import React from "react";
import {
  serviceIcon1,
  serviceIcon2,
  serviceIcon3,
  serviceIcon4,
  serviceIcon5,
} from "..";

function ServicesBanner() {
  return (
    <div className="container indusBannerContainer">
      <div className="row">
        <div className="col-xl-6 col-lg-12 col-md-12 col-12">
          <div className="indusLeftBox">
            <div className="indusBannerTextWrap">
              <h1>
                <span className="tindusTopText">We loves accepting</span>
                <span className="indusMidText">challengs to give</span>
                <span className="indusBtmText">a positive result</span>
              </h1>
            </div>
            <div className="indusBannerParaWrap">
              <p>
                Through our comprehensive IT services, we specialize in crafting
                innovative solutions that effectively tackle business problems,
                align with specific requirements, cater to evolving market
                needs, and drive operational transformation internally.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-12 mob_none">
          <div className="indusRightBox">
            <div className="indusBannerImgWrap">

              <span className="floatImg1">
                <img src={serviceIcon1} alt="" />
              </span>
              <span className="floatImg2">
                <img src={serviceIcon2} alt="" />
              </span>
              <span className="floatImg3">
                <img src={serviceIcon3} alt="" />
              </span>
              <span className="floatImg4">
                <img src={serviceIcon4} alt="" />
              </span>
              <span className="floatImg5">
                <img src={serviceIcon5} alt="" />
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesBanner;
