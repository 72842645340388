import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { qrCodeImg } from "..";

export const AccordionItem = (props) => {
  const contentEl = useRef(null);
  const { handleToggle, active, faq } = props;
  const {
    header,
    id,
    text,
    question1,
    acc_name,
    acc_num,
    bank_name,
    bank_address,
    ifsc,
    swift_code,
    question2,
    question3,
    cin_num,
    pan_num,
    gst_num,
    tan_num,
  } = faq;

  const getContentHeight = () => {
    return active === id ? `${contentEl.current.scrollHeight}px` : "0";
  };

  return (
    <div id={ifsc} className={`commonAccordion ${active === id ? "show" : ""}`}>
      <div
        className={active === id ? "accordionBtn active" : "accordionBtn"}
        onClick={() => handleToggle(id)}
      >
        <h6>{header}</h6>
        <span className="material-symbols-outlined">arrow_forward_ios</span>
      </div>
      <div
        className="collapser accordion_content"
        ref={contentEl}
        style={{
          height: getContentHeight(),
          transition: "height 0.3s ease-in-out",
        }}
      >
        <p>{text}</p>
        <div className="payAccordion">
          <div className="payOptionFaqWrap">
            <h2>{question1}</h2>
            <table className="account bankdetail table-responsive">
              <tbody>
                <tr>
                  <td>
                    <span>Account Name </span>
                  </td>
                  <td>: &nbsp;&nbsp; {acc_name}</td>
                </tr>
                <tr>
                  <td>
                    <span>Account No </span>
                  </td>
                  <td>: &nbsp;&nbsp; {acc_num}</td>
                </tr>
                <tr>
                  <td>
                    <span>Bank Name </span>
                  </td>
                  <td>: &nbsp;&nbsp; {bank_name}</td>
                </tr>
                <tr>
                  <td>
                    <span>Bank Address </span>
                  </td>
                  <td>: &nbsp;&nbsp; {bank_address}</td>
                </tr>
                <tr>
                  <td>
                    <span>IFSC Code </span>
                  </td>
                  <td>: &nbsp;&nbsp; {ifsc}</td>
                </tr>
                <tr>
                  <td>
                    <span>Swift Code </span>
                  </td>
                  <td>: &nbsp;&nbsp; {swift_code}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="payOptionFaqWrap">
            <h2>{question2}</h2>
            <table className="account bankdetail table-responsive">
              <tbody>
                <tr>
                  <td>
                    <span>Swift Code </span>
                  </td>
                  <td>: &nbsp;&nbsp; {swift_code}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="payOptionFaqWrap">
            <h2>{question3}</h2>
            <table className="account bankdetail table-responsive">
              <tbody>
                <tr>
                  <td>
                    <span>CIN Number</span>
                  </td>
                  <td>: &nbsp;&nbsp; {cin_num}</td>
                </tr>
                <tr>
                  <td>
                    <span>Our PAN Number</span>
                  </td>
                  <td>: &nbsp;&nbsp; {pan_num}</td>
                </tr>
                <tr>
                  <td>
                    <span>GST Number</span>
                  </td>
                  <td>: &nbsp;&nbsp; {gst_num}</td>
                </tr>
                <tr>
                  <td>
                    <span>Tan Number</span>
                  </td>
                  <td>: &nbsp;&nbsp; {tan_num}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="qrCodeWrap">
           <h4>UPI Payment</h4>
            <img src={qrCodeImg} alt="Payment QR Code"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Accodion = () => {
  const locationN = useLocation();
  const [active, setActive] = useState(null);
  const faq = useSelector((state) => state.faqs.value);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  return (
    <div className="accordionsWrap">
      {faq &&
        faq
          .filter((data) =>
            data.page.toLowerCase().includes(locationN.pathname.toLowerCase())
          )
          .map((filteredName, index) => {
            return (
              <AccordionItem
                key={index}
                active={active}
                handleToggle={handleToggle}
                faq={filteredName}
              />
            );
          })}
    </div>
  );
};
