export const ExpertiseData = [
    {
        id: 0,
        text: "Comprehensive Web Development Solutions"
    },
    {
        id: 1,
        text: "Data Analysis and Visualization Excellence"
    },
    {
        id: 2,
        text: "Empowering Machine Learning and AI Initiatives"
    },
    {
        id: 3,
        text: "Efficient Automation and Scripting"
    },
    {
        id: 4,
        text: "Tailored Scientific Computing Solutions"
    },
    {
        id: 5,
        text: "Innovative IoT Applications"
    },
    {
        id: 6,
        text: "Commitment to Quality and Innovation"
    },
]