import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [
    {
      id: 0,
      page: "/faqs",
      header: `How can I make payment to Version Next?`,
      question1:"Fedral Bank Account Details:",
      acc_name:"Version Next Technologies Pvt. Ltd.",
      acc_num:"16190200000801",
      bank_name:"Fedral Bank",
      bank_address:"Gala no 8, Sagar Pallazio, Andheri Kurla Link Road, Sakinaka Junction, Andheri (E), Mumbai- 400072",
      ifsc: "FDRL0001619",
      swift_code:"FDRLINBBIBD",
      question2:"Payapl Account Details:",
      question3:"Other Details:",
      cin_num:"U72200MH2008PTC182529",
      pan_num:"AACCV7580M",
      gst_num:"27AACCV7580M1ZN",
      tan_num:"MUMV15923F",
    },
    {
      id: 1,
      page: "/industries",
      header: `What can be included in EHR, EMR, and Patient Portal software development services?`,
      text: `We are ready to provide our customers with end-to-end EHR/EMR software development services. We can also modernize and improve the existing outdated software.`,
    },
    {
      id: 2,
      page: "/industries",
      header: `Can Version Next Technologies build software compliant with HIPAA, PIPEDA, FDA, FHIR, or SNOMED standards?`,
      text: `We treat legislation rules and security very seriously, and our team can help you build EHR, EMR, and Patient Portals software compliant with industry standards. To optimize this process, we’ve established our own framework for compliance with HIPAA and other criteria.`,
    },
    {
      id: 3,
      page: "/industries",
      header: `How could EHR and Patient Portal software help healthcare institutions?`,
      text: `Electronic Health Record (EHR) stores patient data minimizing paperwork for doctors and human errors such as lost or damaged papers with sensitive data. Besides, doctors can update patient data in real-time after talking with patients.`,
    },
    {
      id: 4,
      page: "/industries",
      header: `What features can be included in EMR/EHR and Patient Portal software?`,
      text: `Core features that help medical professionals to deliver the best of care and grow the business value`,
    },
    {
      id: 5,
      page: "/industries",
      header: `What is telehealth software development and what kind of solutions are used in it?`,
      text: `There are many software solutions used in telemedicine. Mobile applications connect patients and doctors (medical consultants) and help them get help and advice remotely. Streaming and video conferencing products allow medical examinations to be completed remotely with necessary confidentiality.`,
    },
    {
      id: 6,
      page: "/services",
      header: `What are the key considerations before choosing offshore software development services?`,
      text: `Decide on what services you need. Are you going to build a mobile or web app, need custom UI design, QA testing, or all together?
        Look for a vendor. Check their portfolio, tech stack, social profiles, and reviews from previous clients.`,
    },
    {
      id: 7,
      page: "/services",
      header: `How do software development services help to grow your business?`,
      text: `If you're building a software product for your customers, it's a great way to attract and engage people. And engaged customers buy 90% more often.
        If we're talking about internal business software, it'll help you manage all the departments in a company, keep track of internal processes, make reports, and many more.`,
    },
    {
      id: 8,
      page: "/services",
      header: `What are the pros of offshore software development services?`,
      text: `It's cheaper. In Ukraine, you'll pay $25-50/h, while in the US the cost goes up to $150-250/h.
      No training costs. You don't waste time and money on training employees – it's the job of your offshore partner.
      Skillset. Most offshore companies are focused on certain industries or technologies, so it'll be easy to find engineers with specific skills.`,
    },
    {
      id: 9,
      page: "/services",
      header: `What are the key focus areas of offshore software development services?`,
      text: `Offshore development services are usually focused on web development, web app development, mobile app development, UI/UX design services, quality assurance services, IT consulting, support.`,
    },
    {
      id: 10,
      page: "/services",
      header: `Why are offshore software development services becoming popular?`,
      text: `Because it's cheaper than hiring an in-house team, but the quality is still high, especially if you're outsourcing to Eastern or Western Europe. Offshore companies offer a bigger pool of talents and technologies, and there are no additional costs you have to bear like taxes, hardware, office rent, and so on.`,
    },
    {
      id: 11,
      page: "/mobile-app-development",
      header: `What includes in the PWA upgrades and support service?`,
      text: `Our PWA upgrades and support service includes maintenance and upgrades to ensure the continued smooth operation of your PWA. We provide timely bug fixes, feature upgrades, security patches, and compatibility updates to make sure your PWA keeps up with the latest technologies and standards.`,
    },
    {
      id: 12,
      page: "/mobile-app-development",
      header: `How long does it take to develop a hybrid mobile app?`,
      text: `The development timeline for a hybrid mobile app varies based on the complexity of the app, the number of features, and the platform requirements. Our expert developers can provide a project timeline after analyzing your specific expectations.`,
    },
    {
      id: 13,
      page: "/mobile-app-development",
      header: `How can you make sure my mobile app is secure?`,
      text: `At Version Next Technologies, security is a top priority. To guarantee the security of your app and user data, we implement industry-standard security methods. To protect your app from any potential security flaws, we use strong encryption, secure authentication, and secure data storage. We also conduct routine security audits to find and address any issues.`,
    },
    {
      id: 16,
      page: "/web-app-development",
      header: `What is enterprise web app development?`,
      text: `Enterprise web app development refers to the creation of web-based applications that are specifically designed to meet the complex requirements of large-scale businesses. These applications are built using the latest web technologies and are scalable, secure, and robust.`,
    },
    {
      id: 17,
      page: "/web-app-development",
      header: `What are the benefits of enterprise web app development?`,
      text: `Enterprise web app development can help businesses to reduce operational costs, enhance operational efficiency, improve customer engagement, streamline workflows, and increase productivity. These applications also provide valuable insights into customer behavior and business performance.`,
    },
    {
      id: 18,
      page: "/web-app-development",
      header: `What kind of enterprise web apps can VNT develop?`,
      text: `VNT can develop a wide range of enterprise web apps including enterprise web portals, eCommerce solutions, B2B and B2C applications, ERP and CRM software apps, reporting, billing & secure financial transaction processing systems, etc. Our web solutions are customized to meet the specific requirements of each business.`,
    },
    {
      id: 21,
      page: "/design-and-graphics",
      header: `What is the importance of good design? `,
      text: `Good design plays a crucial role in various aspects of our lives. It enhances user experiences, communicates messages effectively, creates a positive first impression, and differentiates brands from competitors. It can improve usability, functionality, and overall satisfaction with products, services, and digital interfaces.`,
    },
    {
      id: 22,
      page: "/design-and-graphics",
      header: `What is the difference between UI and UX design? `,
      text: `UI (User Interface) design focuses on the visual and interactive elements of a digital product or interface. It involves creating visually appealing layouts, selecting colors and typography, and designing intuitive navigation. UX (User Experience) design, on the other hand, is concerned with the overall experience and usability of a product. It involves understanding user behaviors, conducting research, creating user personas, and designing intuitive user flows to ensure a positive and satisfying user experience.`,
    },
    {
      id: 23,
      page: "/design-and-graphics",
      header: `How does branding relate to design? `,
      text: `Design is an integral part of branding. It involves creating visual elements such as logos, typography, color schemes, and graphic assets that represent the essence and personality of a brand. Design plays a vital role in conveying brand values, establishing recognition, and creating a cohesive and memorable brand identity across various touchpoints.`,
    },
    {
      id: 24,
      page: "/design-and-graphics",
      header: `What is the design process like? `,
      text: `The design process typically involves several stages, including research, ideation, concept development, prototyping, and implementation. It begins with understanding the project requirements and target audience, followed by research and gathering insights. Ideas are then generated, refined, and transformed into visual concepts through wireframes, mock-ups, and prototypes. Feedback is collected, and iterations are made to enhance the design until the final implementation stage.`,
    },
    {
      id: 25,
      page: "/design-and-graphics",
      header: `How long does the design process take? `,
      text: `The duration of the design process depends on the complexity and scope of the project. It can range from a few days for smaller projects to several weeks or months for more extensive and intricate designs. Effective communication and collaboration between designers and clients, as well as timely feedback, can help expedite the process.`,
    },
    {
      id: 26,
      page: "/devops",
      header: `What are the benefits of using DevOps services for application delivery?`,
      text: `DevOps services enable continuous delivery, increased productivity, early identification of mistakes, and more stable version control. These benefits ultimately result in efficient release strategies and a better user experience.`,
    },
    {
      id: 27,
      page: "/devops",
      header: `What tools and frameworks does VNT use for DevOps consultation?`,
      text: `VNT uses industry-leading software tools and frameworks such as Docker, Puppet, and Selenium for DevOps consultation. These tools help automate the continuous delivery process and create a well-organized and consistent ecosystem.`,
    },
    {
      id: 28,
      page: "/devops",
      header: `Why should I hire VNT for DevOps solutions?`,
      text: `VNT has decades of experience working with leading infrastructure providers and application deployment workflows. Our team members are equipped with DevOps principles and adhere to workflow standards from the early stages of project development. We have a strong track record of aligning ourselves with enterprise infrastructure, DevOps, and other integration cycles for extreme variety and use cases.      `,
    },
    {
      id: 31,
      page: "/internet-of-things",
      header: `What is smart inventory management?`,
      text: `Smart inventory management is the use of IoT technology to monitor and optimize inventory levels, reduce waste, and increase efficiency in supply chain management.`,
    },
    {
      id: 32,
      page: "/internet-of-things",
      header: `How can smart inventory management benefit my business?`,
      text: `Smart inventory management can help businesses save time and money by reducing inventory holding costs, preventing stockouts, and improving overall inventory accuracy.`,
    },
    {
      id: 33,
      page: "/internet-of-things",
      header: `What kind of IoT devices can be used for smart inventory management?`,
      text: `IoT devices such as RFID tags, sensors, and barcode scanners can be used for smart inventory management, along with custom mobile apps and cloud-based analytics platforms.`,
    },
    
    {
      id: 41,
      page: "/ai-and-ml",
      header: `What services does VNT offer in AI and ML?`,
      text: `VNT is a top AI application development company that offers services in AI and ML projects, tools, and technologies such as TensorFlow, Apache SystemML, Caffe, Apache Mahout, OpenNN, Torch, Neuroph, Mycroft AI, etc.`,
    },
    {
      id: 42,
      page: "/ai-and-ml",
      header: `What are the benefits of implementing AI and ML in various industries?`,
      text: `AI and ML can help various industries make better business predictions, improve productivity, reduce risks, and enhance customer experiences. For example, healthcare can use AI and ML for risk prediction, diagnostics, and dosage error reduction.`,
    },
    {
      id: 43,
      page: "/ai-and-ml",
      header: `What is the process of building a chatbot with VNT?`,
      text: `VNT offers chatbot development services based on NLP/Deep Learning, flow-based, functional, chatbot backend development, and chatbot development with Dialogue Flow (API.AI) or IBM Watson framework.`,
    },
    {
      id: 46,
      page: "/quality-assurance",
      header: `What types of software testing services does VNT offer?`,
      text: `VNT offers a range of software testing services, including manual QA testing, web testing automation, mobile app testing, performance testing, API testing automation, and expert QA consultation.`,
    },
    {
      id: 47,
      page: "/quality-assurance",
      header: `How does VNT approach strategic test planning?`,
      text: `VNT begins with a comprehensive analysis of requirements to develop a software testing strategy and execution plan. The QA team then designs test cases, creates test checklists and data, and maintains test reports and documentation.`,
    },
    {
      id: 48,
      page: "/quality-assurance",
      header: `How does VNT ensure high-quality documentation throughout the software development life cycle?`,
      text: `VNT prioritizes high-quality documentation that is accessible to all team members. The QA team uses tools like TestRail and Zephyr to write and plan test cases and maintains software quality metrics to track progress. Any changes to requirements or documentation are immediately visible to the entire team.`,
    },
    {
      id: 56,
      page: "/career",
      header: `How much experience do I need to apply for a specific position?`,
      text: `Please click on the open positions on this page, based upon your area of interest. You will land on a page where you can view the minimum experience and qualification requirements. We hire people with a wide range of professional experience and educational background.`,
    },
    {
      id: 57,
      page: "/career",
      header: `What documents do I need to submit for applying online?`,
      text: `You only have to upload an up-to-date copy of your CV/resume along with filling up the details asked in the online form.`,
    },
    {
      id: 58,
      page: "/career",
      header: `How do I get notified that my job application has been received by you?`,
      text: `You will receive a confirmation message and an email, confirming that your application is received for a review. If your candidate profile and application meet the qualifications for a selected position, a recruiter will be in contact with you at the earliest. If this takes long and you want to inquire, drop an email with your details and same CV again on hr@version-next.com.`,
    },
    {
      id: 59,
      page: "/career",
      header: `What is the size limit of the document to upload while applying online?`,
      text: `A single document with a size not bigger than 5 MB can be uploaded.`,
    },
    {
      id: 60,
      page: "/career",
      header: `What if I get an error while applying online or uploading a document?`,
      text: `We advise you check your internet connection and give it another try. However, if you get the same result after trying again, kindly contact the recruitment team to explain the reason and apply at hr@version-next.com.`,
    },
    {
      id: 61,
      page: "/digital-marketing",
      header: `What is digital marketing?`,
      text: `Digital marketing refers to the use of digital channels and technologies to promote products, services, and brands. It encompasses various online marketing tactics such as search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, content marketing, email marketing, and more.`,
    },
    {
      id: 62,
      page: "/digital-marketing",
      header: `Why is digital marketing important for businesses?`,
      text: `Digital marketing is essential for businesses as it allows them to reach a larger audience, target specific demographics, increase brand visibility, drive website traffic, generate leads, and ultimately, grow their customer base and revenue. It provides opportunities for targeted marketing campaigns, real-time analytics, and the ability to adapt and optimize strategies based on data insights.`,
    },
    {
      id: 63,
      page: "/digital-marketing",
      header: `How do you measure the success of digital marketing campaigns?`,
      text: `The success of digital marketing campaigns can be measured through various key performance indicators (KPIs) depending on the specific goals. Common metrics include website traffic, conversion rates, click-through rates (CTR), engagement on social media, email open and click rates, and return on investment (ROI). Analyzing these metrics helps in evaluating campaign performance and making data-driven decisions for optimization.`,
    },
    {
      id: 64,
      page: "/digital-marketing",
      header: `How long does it take to see results from digital marketing efforts?`,
      text: `The timeframe to see results from digital marketing efforts varies based on factors such as the type of campaign, industry, competition, budget, and the effectiveness of strategies. Some tactics, like PPC advertising, can yield quick results, while others, such as SEO or content marketing, may take longer to show significant impact. It's important to have a long-term perspective and invest in consistent and strategic digital marketing efforts for sustainable growth.`,
    },
    {
      id: 65,
      page: "/digital-marketing",
      header: `Can digital marketing help local businesses?`,
      text: `Absolutely. Digital marketing provides powerful tools and strategies to target local audiences and drive foot traffic to brick-and-mortar stores. Local businesses can leverage local SEO techniques, targeted online advertising, social media marketing, and online directory listings to enhance their online presence, increase visibility within their community, and attract local customers.`,
    },
    {
      id: 66,
      page: "/healthcare",
      header: `What are the benefits of IT in healthcare?`,
      text: `IT services improved the sharing of health data between two entities, which lessens the chances of manual errors and saves time and energy to store records. IT helps patients get healthcare services remotely, enabling the potential to automate the task. Also, IT helps to enhance public health by keeping track of and monitoring diseases and providing health education.`,
    },
    {
      id: 67,
      page: "/healthcare",
      header: `Does VNT offer support and maintenance services?`,
      text: `Yes, VNT offers support and maintenance services for your projects. Our team is here to support and maintain your project, ensuring it runs smoothly and lasts long. We're totally aware of the importance of being available to you, therefore we adjust to different time zones to meet your needs. Whether you need technical help, bug fixes, updates, or any other support-related services, our team is ready to help you. Our aim is to ensure your project remains in optimal condition and continues to meet your evolving needs.`,
    },
    {
      id: 68,
      page: "/healthcare",
      header: `How much does it cost to build healthcare software?`,
      text: `The cost of developing healthcare software varies depending on several factors such as complexity, the technology used, design, user experience, and post-launch services required. The cost ranges between $10k to $100k depending on the requirements given by your company. The choices of technology and design can also affect the cost.`,
    },
    {
      id: 69,
      page: "/fin-tech",
      header: `How much time does it take to complete the project?`,
      text: `The time required to complete a software development project depends on various factors such as project complexity, scope, and specific requirements.`,
    },
    {
      id: 70,
      page: "/fin-tech",
      header: `What is the role of information technology in financial services?`,
      text: `The significance of information technology in the realm of financial services is paramount. It amplifies operational effectiveness, elevates customer encounters, and fosters inventive financial offerings. Through IT systems and software, transactions are safeguarded, tasks such as account administration and payments are automated, data scrutiny aids in evaluating risks and identifying fraudulent activities, all while granting effortless entry to banking services via digital avenues. IT bestows the capability upon financial establishments to respond to evolving market requirements, simplify procedures, and provide improved customer services.`,
    },
    {
      id: 71,
      page: "/fin-tech",
      header: `How VNT IT solutions help finance companies?`,
      text: `VNT IT Solutions presents a multitude of advantages tailored for finance enterprises. We specialize in crafting bespoke software development services that intricately enhance efficiency, fortify security measures, and elevate customer satisfaction. Our solutions are adept at promoting streamlined financial administration, mechanizing tasks spanning accounting and reporting, ensuring secure transactions, and furnishing data analytics to facilitate informed choices. We empower financial firms to seamlessly embrace digital transformation, fine-tune operations, and introduce inventive financial amenities to their clientele, all the while upholding compliance with regulatory benchmarks.`,
    },
    {
      id: 72,
      page: "/fin-tech",
      header: `Does VNT-IT Services provide customized solutions to meet specific financial industry requirements?`,
      text: `Absolutely, VNT-IT Services excels in delivering tailor-made solutions meticulously designed to cater to the distinct demands of the financial domain. Recognizing the exceptional hurdles and regulatory framework inherent to the financial sector, we operate with an acute understanding. Our cadre of proficient specialists engages in close partnership with clients to engineer custom software solutions finely attuned to their individual requisites. This spans a spectrum of necessities, be it risk oversight, adherence to compliance, fortification of security, or the augmentation of operational efficacy. Through harnessing our acumen in the field and adept technical prowess, we furnish unique solutions perfectly aligned with the specific prerequisites of financial enterprises.`,
    },
    {
      id: 73,
      page: "/fin-tech",
      header: `Can VNT-IT Services help financial companies with digital transformation initiatives?`,
      text: `Certainly, VNT-IT Services stands poised to support financial enterprises in their endeavors toward digital transformation. Our reservoir of expertise spans wide, as we adeptly employ technology to propel the digital revolution within the financial domain. Our repertoire of services encompasses the creation and deployment of digital platforms, revitalization of legacy systems, seamless amalgamation of digital channels, meticulous process refinement, and the bolstering of cybersecurity protocols. We engage in a synergistic partnership with our clients, harmonizing their business aspirations with pioneering technological remedies. This synthesis empowers them to retain their competitive edge and effectively address the dynamic requisites of the digital epoch.`,
    },
    {
      id: 74,
      page: "/travel",
      header: `Why choose VNT for Travel App Development needs?`,
      text: `With a focus on user needs, the essence of captivating user attention lies in mobility. VNT specializes in crafting immersive and user-centric travel applications. Our forte lies in seamlessly blending effective business solutions into mobile app development. Our enterprise mobility services cater to travel applications, ensuring compatibility across diverse platforms and devices, such as Android and iOS. As a prominent player in travel mobile app development, we oversee every aspect of launching your app into the digital realm.`,
    },
    {
      id: 75,
      page: "/travel",
      header: `Which mobile platform is more suitable for a travel app development project?`,
      text: `The path you choose depends entirely on your requirements and ultimate goal. Opt for Android when catering to a global customer base, while iOS becomes the preferred choice for a clientele concentrated in specific western countries. Moreover, prioritize iOS over Android if your app involves in-app purchases.`,
    },
    {
      id: 76,
      page: "/travel",
      header: `How much does it cost to make a travel app?`,
      text: `The cost of developing a travel app is shaped by its functionality, scope, and specifications. However, upon understanding your requirements, VNT will provide you the appropriate price quote.`,
    },
    {
      id: 77,
      page: "/travel",
      header: `How do travel apps generate revenue?`,
      text: `Travel applications generate revenue through diverse channels like in-app advertising, commissions, premium versions, in-app sales, and more. Nevertheless, for these revenue models to thrive, your app must resonate with users. At VNT, we believe that commencing with an MVP is the optimal approach to crafting an app that aligns with user preferences.`,
    },
    {
      id: 78,
      page: "/social-network",
      header: `What is Social Media API?`,
      text: `A Social Media API is a tool that lets developers build apps and websites that can interact with and use features from social media platforms. It allows them to access things like user profiles, posts, and photos to create customized experiences.`,
    },
    {
      id: 79,
      page: "/social-network",
      header: `How does Social Network API work?`,
      text: `Social network APIs provide access to vast datasets within social networks. This offers a valuable means to collect significant data for your application. Utilizing these APIs can enrich your own app, provide user insights, or even create new services leveraging this data.`,
    },
    {
      id: 80,
      page: "/social-network",
      header: `What is Social Media Integration Theory?`,
      text: `The process of integrating "social media" is characterized by a reciprocal exchange. This enables brands and audiences to engage on a balanced platform, resulting in enhanced brand interaction and enduring communication.`,
    },
    {
      id: 81,
      page: "/social-network",
      header: `How much does it cost to create an API?`,
      text: `The API's features or capabilities will determine how long it takes to create. The creation of several categories is necessary when building an API. The amount of time required to complete each task can be changed, which can change pricing. It also depends on how much time and resources you have available for each, as some coordination between the internal IT staff and outside contractors is required to construct the API.`,
    },
    {
      id: 82,
      page: "/hire-react-js-developers",
      header: `What makes VNT developers for React better than others? What additional benefits will I receive?`,
      text: `With years of React development experience and exposure to numerous popular React-based apps for a variety of niches, VNT is recognised as a top firm. We are proud to have a talented group of React developers who have built a wide range of websites and mobile applications using the React and React Native libraries. We offer continual post-development assistance and maintenance to our clients that hire React developers from us.`,
    },
    {
      id: 83,
      page: "/hire-react-js-developers",
      header: `Where can I find the top ReactJS developers?`,
      text: `You should concentrate on businesses with years of experience and a significant portfolio of React based app projects if you want to recruit the highly skilled ReactJS developers for your projects. We at VNT are proud to have a sizable development team made up of talented and experienced ReactJS developers who have contributed significantly to the success of numerous well-known apps.`,
    },
    {
      id: 84,
      page: "/hire-react-js-developers",
      header: `How long does it take to create a ReactJS web app?`,
      text: `Due to the core reusability and component-based architecture of React, the development time for a ReactJS application is obviously less than the time necessary with other frameworks and technologies. With our understanding of the many React libraries' components and how they are used, we at VNT further guarantee accelerating the development pace.`,
    },
    {
      id: 85,
      page: "/hire-react-js-developers",
      header: `How do I hire a React app developer or team?`,
      text: `There are only a few simple steps involved in hiring a React development team from VNT. Get in touch with us to discuss your project's needs, select an experienced React developer from our group, participate in a one-on-one interview and selection process, handle the paperwork, and bring developers on board for the undertaking.`,
    },
    {
      id: 86,
      page: "/hire-react-js-developers",
      header: `What is the cost of hiring a ReactJS developer?`,
      text: `The price of hiring ReactJS developers is significantly influenced by a number of variables, such as the project's complexity and size, industry specialty and category, necessary development talent, app's nature and target platform, and choice of value-added services. For app projects in a variety of sectors, we at VNT guarantee you the most cost-effective rate for hiring ReactJS engineers.`,
    },
    {
      id: 87,
      page: "/hire-asp-dotnet-developers",
      header: `Where Can I Get Competent ASP.NET Software Developers to Hire?`,
      text: `Microsoft-centric companies are consistently seeking skilled App.net developers, making it a challenge to find experienced Asp.net programmers. At VNT, we provide a specialized asp.net development approach, along with a team of developers who possess established skills and proficiency in this programming language.`,
    },
    {
      id: 88,
      page: "/hire-asp-dotnet-developers",
      header: `What Steps Does the ASP.NET Developer Take To Achieve Quality Compliance?`,
      text: `VNT is dedicated to maintaining a stringent quality control process that systematically evaluates and monitors the contributions of asp.net developers across diverse client assignments. Our commitment involves daily scrutiny and appraisal of developer performance, actively identifying and rectifying issues and glitches as they arise. By adhering to rigorous quality compliance standards, we ensure the triumph of your software project.`,
    },
    {
      id: 89,
      page: "/hire-asp-dotnet-developers",
      header: `How Much Time Is Needed to Ramp up Resources?`,
      text: `Prior to initiating any Asp.net development, we aid businesses in alleviating resource shortages. Our support includes consultations and the creation of precise estimates, enabling businesses to expedite resource augmentation. Additionally, we maintain thorough records of resource allocation to ensure transparency and efficient management.`,
    },
    {
      id: 90,
      page: "/hire-asp-dotnet-developers",
      header: `How Much Does Hiring an ASP.NET Developer Cost?`,
      text: `The expense of enlisting skilled Asp.net developers varies based on a broad spectrum of factors. These include the project's extent, the intricacy of its features and customization preferences, as well as the accessibility of supplementary expertise. VNT offers exceptionally cost-effective and flexible recruitment approaches, along with competitive rates tailored to the proficiency of Asp.net developers.`,
    },
    {
      id: 91,
      page: "/hire-asp-dotnet-developers",
      header: `What Are the Advantages of Hiring ASP.NET Developers From VNT?`,
      text: `What sets VNT apart in the realm of Asp.net development is its adept developer roster, boasting years of hands-on experience across a spectrum of demanding Asp.net ventures. Our track record includes numerous triumphs in crafting effective Asp.net projects for diverse business sectors.`,
    },

  ],
};

const faqs = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    setFAQ(state, action) {
      state.value = action.payload;
    },
  },
});

export const { setFAQ } = faqs.actions;
export default faqs.reducer;
