import React from "react";
import { abtExpert } from "..";
import ExpertButton from "../CTA/ExpertButton";

function AboutExpert() {
  return (
    <div className="container">
      <div className="row ">
        <div className="col-lg-6 col-md-6 col-12">
          <div className="abtLeftBox">
            <img src={abtExpert} alt="About Expert" />
            <span className="flotBox1"></span>
            <span className="flotBox2"></span>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12">
          <div className="abtRightBox">
            <div className="abtDataWrap">
              <h6 className="indusCoreLeftTitle">About VERSION NEXT TECHNOLOGIES PRIVATE LIMITED</h6>
              <h2>
                We put the customer at the center of every decision we make
              </h2>
              <p>
                At Version Next Technologies, we specialize in creating
                cutting-edge mobile applications that cater to your specific
                needs. Our team of skilled developers ensures seamless
                functionality and stunning user experiences for both iOS and
                Android platforms.
              </p>
            </div> 
            <ExpertButton rText="Talk To Experts" rName="rAnimeBtn expertBtn" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutExpert;
