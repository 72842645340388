import React, { useState } from "react";
import { blackarrow } from "..";
import { Link } from "react-router-dom";

function RegionButtons() {
  const [state, setstate] = useState("state1");
  return (
    <div className="mobShowHideButtonWrap">
      <div className="mobBtnsWrap">
        {state === "state1" ? (
          <div
            className={
              state === "state1" ? "mob_btn_1 code1 active" : "mob_btn_1 code1"
            }
          >
            <span className="coodiv-text-12">
              607/608, Pranik Chamber, <br />
              Saki Vihar Road, Sakinaka Metro,
              <br /> Andheri East,
              <br /> Mumbai 400072, India
            </span>
            <div className="directionBtn">
              <Link to="#">
                Get Direction{" "}
                <span>
                  <img src={blackarrow} alt="arrow" />
                </span>
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
        <span
          className={
            state === "state1"
              ? "mob_btn_circle cir1 active"
              : "mob_btn_circle cir1"
          }
          onClick={() => {
            setstate("state1");
          }}
        >
          {" "}
          <i className="fas fa-map-marker-alt"></i>
        </span>
      </div>
      <div className="mobBtnsWrap">
        {state === "state2" ? (
          <div
            className={
              state === "state2" ? "mob_btn_1 code2 active" : "mob_btn_1 code2"
            }
          >
            <span className="coodiv-text-12">
              607/608, Pranik Chamber, <br />
              Saki Vihar Road, Sakinaka Metro,
              <br /> Andheri East,
              <br /> Mumbai 400072, Asia Singapore
            </span>
            <div className="directionBtn">
              <Link to="#">
                Get Direction{" "}
                <span>
                  <img src={blackarrow} alt="arrow" />
                </span>
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
        <span
          className={
            state === "state2"
              ? "mob_btn_circle cir2 active"
              : "mob_btn_circle cir2"
          }
          onClick={() => {
            setstate("state2");
          }}
        >
          <i className="fas fa-map-marker-alt"></i>
        </span>
      </div>
      <div className="mobBtnsWrap">
        {state === "state3" ? (
          <div
            className={
              state === "state3" ? "mob_btn_1 code3 active" : "mob_btn_1 code3"
            }
          >
            <span className="coodiv-text-12">
              607/608, Pranik Chamber, <br />
              Saki Vihar Road, Sakinaka Metro,
              <br /> Andheri East,
              <br /> Mumbai 400072, European Union Germany
            </span>
            <div className="directionBtn">
              <Link to="#">
                Get Direction{" "}
                <span>
                  <img src={blackarrow} alt="arrow" />
                </span>
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
        <span
          className={
            state === "state3"
              ? "mob_btn_circle cir3 active"
              : "mob_btn_circle cir3"
          }
          onClick={() => {
            setstate("state3");
          }}
        >
          <i className="fas fa-map-marker-alt"></i>
        </span>
      </div>
      <div className="mobBtnsWrap">
        {state === "state4" ? (
          <div
            className={
              state === "state4" ? "mob_btn_1 code4 active" : "mob_btn_1 code4"
            }
          >
            <span className="coodiv-text-12">
              607/608, Pranik Chamber, <br />
              Saki Vihar Road, Sakinaka Metro,
              <br /> Andheri East,
              <br /> Mumbai 400072, UK London
            </span>
            <div className="directionBtn">
              <Link to="#">
                Get Direction{" "}
                <span>
                  <img src={blackarrow} alt="arrow" />
                </span>
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
        <span
          className={
            state === "state4"
              ? "mob_btn_circle cir4 active"
              : "mob_btn_circle cir4"
          }
          onClick={() => {
            setstate("state4");
          }}
        >
          <i className="fas fa-map-marker-alt"></i>
        </span>
      </div>
      <div className="mobBtnsWrap">
        {state === "state5" ? (
          <div
            className={
              state === "state5" ? "mob_btn_1 code5 active" : "mob_btn_1 code5"
            }
          >
            <span className="coodiv-text-12">
              607/608, Pranik Chamber, <br />
              Saki Vihar Road, Sakinaka Metro,
              <br /> Andheri East,
              <br /> Mumbai 400072, West USA
            </span>
            <div className="directionBtn">
              <Link to="#">
                Get Direction{" "}
                <span>
                  <img src={blackarrow} alt="arrow" />
                </span>
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
        <span
          className={
            state === "state5"
              ? "mob_btn_circle cir5 active"
              : "mob_btn_circle cir5"
          }
          onClick={() => {
            setstate("state5");
          }}
        >
          <i className="fas fa-map-marker-alt"></i>
        </span>
      </div>
      <div className="mobBtnsWrap">
        {state === "state6" ? (
          <div
            className={
              state === "state6" ? "mob_btn_1 code6 active" : "mob_btn_1 code6"
            }
          >
            <span className="coodiv-text-12">
              607/608, Pranik Chamber, <br />
              Saki Vihar Road, Sakinaka Metro,
              <br /> Andheri East,
              <br /> Mumbai 400072, East USA
            </span>
            <div className="directionBtn">
              <Link to="#">
                Get Direction{" "}
                <span>
                  <img src={blackarrow} alt="arrow" />
                </span>
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
        <span
          className={
            state === "state6"
              ? "mob_btn_circle cir6 active"
              : "mob_btn_circle cir6"
          }
          onClick={() => {
            setstate("state6");
          }}
        >
          <i className="fas fa-map-marker-alt"></i>
        </span>
      </div>
      <div className="mobBtnsWrap">
        {state === "state7" ? (
          <div
            className={
              state === "state7" ? "mob_btn_1 code7 active" : "mob_btn_1 code7"
            }
          >
            <span className="coodiv-text-12">
              607/608, Pranik Chamber, <br />
              Saki Vihar Road, Sakinaka Metro,
              <br /> Andheri East,
              <br /> Mumbai 400072, Central USA
            </span>
            <div className="directionBtn">
              <Link to="#">
                Get Direction{" "}
                <span>
                  <img src={blackarrow} alt="arrow" />
                </span>
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
        <span
          className={
            state === "state7"
              ? "mob_btn_circle cir7 active"
              : "mob_btn_circle cir7"
          }
          onClick={() => {
            setstate("state7");
          }}
        >
          <i className="fas fa-map-marker-alt"></i>
        </span>
      </div>
      <div className="mobBtnsWrap">
        {state === "state8" ? (
          <div
            className={
              state === "state8" ? "mob_btn_1 code8 active" : "mob_btn_1 code8"
            }
          >
            <span className="coodiv-text-12">
              607/608, Pranik Chamber, <br />
              Saki Vihar Road, Sakinaka Metro,
              <br /> Andheri East,
              <br /> Mumbai 400072, Australia
            </span>
            <div className="directionBtn">
              <Link to="#">
                Get Direction{" "}
                <span>
                  <img src={blackarrow} alt="arrow" />
                </span>
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
        <span
          className={
            state === "state8"
              ? "mob_btn_circle cir8 active"
              : "mob_btn_circle cir8"
          }
          onClick={() => {
            setstate("state8");
          }}
        >
          <i className="fas fa-map-marker-alt"></i>
        </span>
      </div>
      <div className="mobBtnsWrap">
        {state === "state9" ? (
          <div
            className={
              state === "state9" ? "mob_btn_1 code9 active" : "mob_btn_1 code9"
            }
          >
            <span className="coodiv-text-12">
              607/608, Pranik Chamber, <br />
              Saki Vihar Road, Sakinaka Metro,
              <br /> Andheri East,
              <br /> Mumbai 400072, Japan
            </span>
            <div className="directionBtn">
              <Link to="#">
                Get Direction{" "}
                <span>
                  <img src={blackarrow} alt="arrow" />
                </span>
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
        <span
          className={
            state === "state9"
              ? "mob_btn_circle cir9 active"
              : "mob_btn_circle cir9"
          }
          onClick={() => {
            setstate("state9");
          }}
        >
          <i className="fas fa-map-marker-alt"></i>
        </span>
      </div>
    </div>
  );
}

export default RegionButtons;
