const CurrentOpeningsData = [
    {
        id:1,
        positionName: "PHP Developer",
        positionCount: "1 Position",
        jdlink: "https://jobs.version-next.com/job-details/GLEbzkRFlh1W"
    },
    {
        id:2,
        positionName: "Flutter Developer",
        positionCount: "1 Position",
        jdlink: "https://jobs.version-next.com/job-details/tmjwrbH1tdh4"
    },
    {
        id:3,
        positionName: "iOS Developer",
        positionCount: "1 Position",
        jdlink: "https://jobs.version-next.com/job-details/wXv0h8Y5NyRv"
    },
    {
        id:4,
        positionName: "Server Support Executive",
        positionCount: "0 Position",
        jdlink: "#"
    },
    {
        id:5,
        positionName: "UI/UX Designer",
        positionCount: "0 Position",
        jdlink: "#"
    },
    {
        id:6,
        positionName: "Graphic Designer",
        positionCount: "0 Position",
        jdlink: "#"
    },
    {
        id:7,
        positionName: "Front-End Developer",
        positionCount: "1 Position",
        jdlink: "https://jobs.version-next.com/job-details/AC7l3WbtlcQ5"
    },
    {
        id:8,
        positionName: "Asp.Net Developer",
        positionCount: "1 Position",
        jdlink: "https://jobs.version-next.com/job-details/iQPM14RqrASL"
    },
    {
        id:9,
        positionName: "Accountants",
        positionCount: "0 Position",
        jdlink: "#"
    },
    {
        id:10,
        positionName: "Software Development",
        positionCount: "0 Position",
        jdlink: "#"
    },
    {
        id:11,
        positionName: "C++ Developer",
        positionCount: "0 Position",
        jdlink: "#"
    },
    {
        id:12,
        positionName: "Golang Developer",
        positionCount: "0 Position",
        jdlink: "#"
    },
    {
        id:13,
        positionName: "Human Resource",
        positionCount: "0 Position",
        jdlink: "#"
    },
    {
        id:14,
        positionName: "Software Tester",
        positionCount: "0 Position",
        jdlink: "#"
    },
    {
        id:15,
        positionName: "Java Developer",
        positionCount: "0 Position",
        jdlink: "#"
    },
]
export default CurrentOpeningsData;

