import React, { useState, useRef, useEffect } from "react";
import csaeImg1 from "../../../assets/images/Casestudy/great-learning-ui-study.jpg";
import csaeImg2 from "../../../assets/images/Casestudy/mokup-ui.jpg";
import csaeImg3 from "../../../assets/images/Casestudy/nutritional-counter-app-composition2.jpg";
import csaeImg4 from "../../../assets/images/Casestudy/top-view-nutritional-counter-app-composition.jpg";
import ExpertButton from "../../CTA/ExpertButton";
import { useLocation } from "react-router";

function CaseStudyNew(props) {
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const containerRef = useRef(null);
  const isDragging = useRef(false);
  const [bgColor, setbgColor] = useState(csaeImg1);
  const [ArrImage, setArrImage] = useState([]);
  const [slideCount, setSlideCount] = useState(1);
  const [healthCaseStudy, setHealthCaseStudy] = useState(false);
  const [homeCaseStudy, setHomeCaseStudy] = useState(false);
  const [finTechCaseStudy, setfinTechCaseStudy] = useState(false);
  const locationN = useLocation();

  const values = [
    {
      image: csaeImg1,
      page: "/",
    },
    {
      image: csaeImg2,
      page: "/",
    },
    {
      image: csaeImg3,
      page: "/",
    },
    {
      image: csaeImg4,
      page: "/",
    },
    {
      image: csaeImg1,
      page: "/healthcare",
    },
    {
      image: csaeImg4,
      page: "/healthcare",
    },
    {
      image: csaeImg2,
      page: "/fin-tech",
    },
    {
      image: csaeImg3,
      page: "/fin-tech",
    },
    {
      image: csaeImg2,
      page: "/education-software-development-company",
    },
    {
      image: csaeImg3,
      page: "/education-software-development-company",
    },
    {
      image: csaeImg2,
      page: "/logistics-software-solutions",
    },
    {
      image: csaeImg3,
      page: "/logistics-software-solutions",
    },
    {
      image: csaeImg1,
      page: "/sports-gaming-development",
    },
    {
      image: csaeImg3,
      page: "/sports-gaming-development",
    },
    {
      image: csaeImg2,
      page: "/digital-retail",
    },
    {
      image: csaeImg3,
      page: "/digital-retail",
    },
    {
      image: csaeImg2,
      page: "/insurance",
    },
    {
      image: csaeImg3,
      page: "/insurance",
    },
    {
      image: csaeImg1,
      page: "/aws-development",
    },
    {
      image: csaeImg2,
      page: "/aws-development",
    },
    {
      image: csaeImg3,
      page: "/aws-development",
    },
    {
      image: csaeImg4,
      page: "/aws-development",
    },
    {
      image: csaeImg1,
      page: "/firebase-development",
    },
    {
      image: csaeImg2,
      page: "/firebase-development",
    },
    {
      image: csaeImg3,
      page: "/firebase-development",
    },
    {
      image: csaeImg4,
      page: "/firebase-development",
    },
  ];

  useEffect(() => {
    if (locationN.pathname === "/healthcare") {
      setHealthCaseStudy(true);
    } else if (locationN.pathname === "/") {
      setHomeCaseStudy(true);
    } else if (locationN.pathname === "/fin-tech") {
      setfinTechCaseStudy(true);
    } else if (
      locationN.pathname === "/education-software-development-company"
    ) {
      setfinTechCaseStudy(true);
    } else if (locationN.pathname === "/logistics-software-solutions") {
      setfinTechCaseStudy(true);
    } else if (locationN.pathname === "/sports-gaming-development") {
      setfinTechCaseStudy(true);
    } else if (locationN.pathname === "/digital-retail") {
      setfinTechCaseStudy(true);
    } else if (locationN.pathname === "/insurance") {
      setfinTechCaseStudy(true);
    }else if (locationN.pathname === "/aws-development") {
      setHomeCaseStudy(true);
    }else if (locationN.pathname === "/firebase-development") {
      setHomeCaseStudy(true);
    }
  }, []);

  const handleScroll = (e) => {
    const element = e.target;
    const scrollTop = element.scrollTop;
    const scrollHeight = element.scrollHeight - element.clientHeight;
    const percentage = (scrollTop / scrollHeight) * 100;
    setScrollPercentage(percentage);

    if (scrollTop > 0 && scrollTop < 400) {
      setbgColor(ArrImage[0].image);
      setSlideCount(1);
    } else if (scrollTop > 420 && scrollTop < 800) {
      setbgColor(ArrImage[1].image);
      setSlideCount(2);
    } else if (scrollTop > 820 && scrollTop < 1300) {
      setbgColor(ArrImage[2].image);
      setSlideCount(3);
    } else if (scrollTop > 1320) {
      setbgColor(ArrImage[3].image);
      setSlideCount(4);
    }
  };

  const handleThumbMouseMove = (e) => {
    if (isDragging.current) {
      requestAnimationFrame(() => {
        const container = containerRef.current;
        const containerHeight = container.clientHeight;
        const scrollHeight = container.scrollHeight - containerHeight;
        const mouseY = e.clientY - container.getBoundingClientRect().top;
        let percentage = (mouseY / containerHeight) * 100;

        // Ensure percentage is within bounds
        if (percentage < 0) {
          percentage = 0;
        } else if (percentage > 100) {
          percentage = 100;
        }

        setScrollPercentage(percentage);
        container.scrollTop = (percentage / 100) * scrollHeight;
      });
    }
  };

  const handleThumbMouseUp = (e) => {
    isDragging.current = false;
    e.preventDefault();
  };
  const setImages = () => {
    const imagesArr = [];
    values
      .filter((data) => data.page === locationN.pathname)
      .map((res) => {
        return imagesArr.push(res);
      });
    setArrImage(imagesArr);
    setbgColor(imagesArr[0].image);
  };
  useEffect(() => {
    setImages();
    document.addEventListener("mousemove", handleThumbMouseMove);
    document.addEventListener("mouseup", handleThumbMouseUp);

    return () => {
      document.removeEventListener("mousemove", handleThumbMouseMove);
      document.removeEventListener("mouseup", handleThumbMouseUp);
    };
  }, []);
  const radius = 39; // Radius of the progress bar
  const circumference = 2 * Math.PI * radius; // Circumference of the progress bar

  // Calculate the stroke-dashoffset value based on the percentage
  const strokeDashoffset =
    circumference - (scrollPercentage / 100) * circumference;
  return (
    <div className="container">
      <div className="sectionTitle">
        <h2>
          {props.titleChar1}{" "}
          <span className="textGrade">{props.titleChar2}</span>
        </h2>
        <ExpertButton rText="Get Project Quote" rName="rAnimeBtn qouteBtn" />
      </div>
      <div className="csaerow">
        <div
          className="stickyBox"
          style={{ backgroundImage: `url(${bgColor})` }}
        ></div>
        <div
          className="caseStudyNew carousel-container"
          onScroll={handleScroll}
          ref={containerRef}
        >
          <div className="caseRow item">
            <div className="col-md-6 h100">
              <div className="caseLeftBox"></div>
            </div>
            <div className="col-md-6 h100">
              <div className="caseRightBox">
                <div className="caseDataWrap">
                  <h4>{props.projectTitle10}</h4>
                  <p>{props.projectPara11}</p>
                  <p>{props.projectPara12}</p>
                  <p>{props.projectPara13}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="caseRow item">
            <div className="col-md-6 h100">
              <div className="caseLeftBox"></div>
            </div>
            <div className="col-md-6 h100">
              <div className="caseRightBox">
                <div className="caseDataWrap">
                  <h4>{props.projectTitle20}</h4>
                  <p>{props.projectPara21}</p>
                  <p>{props.projectPara22}</p>
                  <p>{props.projectPara23}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="caseRow item">
            <div className="col-md-6 h100">
              <div className="caseLeftBox"></div>
            </div>
            <div className="col-md-6 h100">
              <div className="caseRightBox">
                <div className="caseDataWrap">
                  <h4>{props.projectTitle30}</h4>
                  <p>{props.projectPara31}</p>
                  <p>{props.projectPara32}</p>
                  <p>{props.projectPara33}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="caseRow item">
            <div className="col-md-6 h100">
              <div className="caseLeftBox"></div>
            </div>
            <div className="col-md-6 h100">
              <div className="caseRightBox">
                <div className="caseDataWrap">
                  <h4>{props.projectTitle40}</h4>
                  <p>{props.projectPara41}</p>
                  <p>{props.projectPara42}</p>
                  <p>{props.projectPara43}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-progress">
          <span className="slideCountNum">
            {slideCount} / {props.totlaCount}
          </span>
          <svg className="progress-bar" width="80" height="80">
            <circle
              className="progress-bar-background"
              r={radius}
              cx="40"
              cy="40"
              fill="none"
              stroke="#fff"
              strokeWidth="2"
            />
            <circle
              className="progress-bar-fill"
              r={radius}
              cx="40"
              cy="40"
              fill="none"
              stroke="#3758ba"
              strokeWidth="2"
              strokeLinecap="round"
              strokeDasharray={circumference}
              strokeDashoffset={strokeDashoffset}
            />
          </svg>
        </div>
      </div>

      {homeCaseStudy ? (
        <div className="mobCaseRow homePageCaseRow">
          <div className="mobCaseCard">
            <div className="col-12 mb-5">
              <div className="mobCaseImgWrap">
                <img src={csaeImg1} alt="" />
              </div>
              <div className="mobCaseDataWrap">
                <h4>1. Utsav Jewels</h4>
                <p>
                  Utssav Jwelles had a strong presence in the offline market but
                  wanted to expand their reach to a wider audience by selling
                  their products online. They approached us at Version Next to
                  help them needed a user-friendly e-commerce website that
                  provided a seamless shopping experience to their customers.
                </p>
                <p>
                  To address Utssav Jwelles' requirements, we designed and
                  developed an e-commerce with mobile app for Utssav Jwelles to
                  manage their orders and deliveries. The app allowed them to
                  view all orders and track their status in real-time. The
                  application was built using a modern stack of technologies to
                  ensure fast loading times and smooth checkout processes.
                </p>
                <p>
                  At Version Next, we take pride in delivering high-quality
                  solutions that are tailored to our client's business needs.
                  Our collaboration with Utssav Jwelles is an example of how we
                  leverage our expertise in web and mobile app development to
                  help businesses achieve their goals.
                </p>
              </div>
            </div>
            <div className="col-12 mb-5">
              <div className="mobCaseImgWrap">
                <img src={csaeImg2} alt="" />
              </div>
              <div className="mobCaseDataWrap">
                <h4>2. Building an ERP Tool for Heritage</h4>
                <p>
                  Heritage, a leading company in the industry, sought to enhance
                  their customer experience by developing an ERP tool. This tool
                  aimed to seamlessly connect customers with the ability to
                  order subscription-based products through their app.
                  Additionally, it would establish a connection to the nearest
                  Heritage branch responsible for fulfilling the orders and
                  facilitating timely deliveries. Version Next, an experienced
                  IT services provider, was enlisted to build this innovative
                  solution.
                </p>
                <p>
                  Version Next's development team meticulously crafted the ERP
                  tool, leveraging industry-standard technologies to ensure a
                  robust and reliable solution. The user interface was designed
                  with a customer-centric approach, focusing on simplicity and
                  ease of use while offering a comprehensive ordering
                  experience. The ERP tool transformed Heritage's customer
                  experience by providing a convenient, user-friendly platform
                  for ordering subscription-based products.
                </p>
                <p>
                  The solution provided Heritage with a competitive edge by
                  enhancing customer satisfaction, optimizing internal
                  operations, and supporting future growth.
                </p>
              </div>
            </div>
            <div className="col-12 mb-5">
              <div className="mobCaseImgWrap">
                <img src={csaeImg3} alt="" />
              </div>
              <div className="mobCaseDataWrap">
                <h4>3. Parental Control App for MobiCID</h4>
                <p>
                  MobiCID,has the goal was to create a powerful tool that would
                  enable parents to monitor and manage their children's online
                  activities effectively, approached Version Next with a
                  specific requirement.
                </p>
                <p>
                  Version Next collaborated closely with MobiCID to understand
                  their specific needs and the desired functionalities of the
                  parental control app. We implemented advanced tracking
                  mechanisms to capture and present detailed reports on calling,
                  app usage, websites visited, and social media interactions.
                  The app provided parents with extensive customization options,
                  allowing them to set screen time limits, block specific apps
                  or websites, and receive notifications for concerning
                  activities.
                </p>
                <p>
                  The implementation of stringent privacy and security measures
                  further enhanced the app's reliability and trustworthiness.
                  Overall, the MobiCID parental control app became a valuable
                  tool for proactive parental oversight in today's digital age.
                </p>
              </div>
            </div>
            <div className="col-12">
              <div className="mobCaseImgWrap">
                <img src={csaeImg4} alt="" />
              </div>
              <div className="mobCaseDataWrap">
                <h4>4. Mobile Trading App for Elixir Equities</h4>
                <p>
                  Elixir Equities, a prominent financial services company,
                  approached our team with a specific requirement: to design and
                  develop a mobile trading app that would enable their clients
                  to engage in seamless trading activities using their API.
                </p>
                <p>
                  We collaborated closely with Elixir Equities to understand
                  their specific needs and the desired functionalities of the
                  mobile trading app. We developed a robust backend system that
                  seamlessly integrated with Elixir Equities' API, allowing
                  real-time market data updates and immediate trade execution.
                  The app was equipped with a wide range of trading features,
                  including market orders, limit orders, real-time portfolio
                  tracking, and historical trade data analysis.
                </p>
                <p>
                  By providing an intuitive UI/UX design, seamless integration
                  with the Elixir Equities API, and a wide range of trading
                  features, the app revolutionised the trading experience for
                  their clients. With a strong emphasis on security and
                  compliance, the app offered peace of mind to users, knowing
                  their information and transactions were protected.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {healthCaseStudy ? (
        <div className="mobCaseRow healthPageCaseRow">
          <div className="mobCaseCard">
            <div className="col-12 mb-5">
              <div className="mobCaseImgWrap">
                <img src={csaeImg2} alt="" />
              </div>
              <div className="mobCaseDataWrap">
                <h4>1. Building an ERP Tool for Heritage</h4>
                <p>
                  Heritage, a leading company in the industry, sought to enhance
                  their customer experience by developing an ERP tool. This tool
                  aimed to seamlessly connect customers with the ability to
                  order subscription-based products through their app.
                  Additionally, it would establish a connection to the nearest
                  Heritage branch responsible for fulfilling the orders and
                  facilitating timely deliveries. Version Next, an experienced
                  IT services provider, was enlisted to build this innovative
                  solution.
                </p>
                <p>
                  Version Next's development team meticulously crafted the ERP
                  tool, leveraging industry-standard technologies to ensure a
                  robust and reliable solution. The user interface was designed
                  with a customer-centric approach, focusing on simplicity and
                  ease of use while offering a comprehensive ordering
                  experience. The ERP tool transformed Heritage's customer
                  experience by providing a convenient, user-friendly platform
                  for ordering subscription-based products.
                </p>
                <p>
                  The solution provided Heritage with a competitive edge by
                  enhancing customer satisfaction, optimizing internal
                  operations, and supporting future growth.
                </p>
              </div>
            </div>

            <div className="col-12">
              <div className="mobCaseImgWrap">
                <img src={csaeImg1} alt="" />
              </div>
              <div className="mobCaseDataWrap">
                <h4>2. Utsav Jewels</h4>
                <p>
                  Utssav Jwelles had a strong presence in the offline market but
                  wanted to expand their reach to a wider audience by selling
                  their products online. They approached us at Version Next to
                  help them needed a user-friendly e-commerce website that
                  provided a seamless shopping experience to their customers.
                </p>
                <p>
                  To address Utssav Jwelles' requirements, we designed and
                  developed an e-commerce with mobile app for Utssav Jwelles to
                  manage their orders and deliveries. The app allowed them to
                  view all orders and track their status in real-time. The
                  application was built using a modern stack of technologies to
                  ensure fast loading times and smooth checkout processes.
                </p>
                <p>
                  At Version Next, we take pride in delivering high-quality
                  solutions that are tailored to our client's business needs.
                  Our collaboration with Utssav Jwelles is an example of how we
                  leverage our expertise in web and mobile app development to
                  help businesses achieve their goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {finTechCaseStudy ? (
        <div className="mobCaseRow healthPageCaseRow">
          <div className="mobCaseCard">
            <div className="col-12 mb-5">
              <div className="mobCaseImgWrap">
                <img src={csaeImg2} alt="" />
              </div>
              <div className="mobCaseDataWrap">
                <h4>1. Building an ERP Tool for Heritage</h4>
                <p>
                  Heritage, a leading company in the industry, sought to enhance
                  their customer experience by developing an ERP tool. This tool
                  aimed to seamlessly connect customers with the ability to
                  order subscription-based products through their app.
                  Additionally, it would establish a connection to the nearest
                  Heritage branch responsible for fulfilling the orders and
                  facilitating timely deliveries. Version Next, an experienced
                  IT services provider, was enlisted to build this innovative
                  solution.
                </p>
                <p>
                  Version Next's development team meticulously crafted the ERP
                  tool, leveraging industry-standard technologies to ensure a
                  robust and reliable solution. The user interface was designed
                  with a customer-centric approach, focusing on simplicity and
                  ease of use while offering a comprehensive ordering
                  experience. The ERP tool transformed Heritage's customer
                  experience by providing a convenient, user-friendly platform
                  for ordering subscription-based products.
                </p>
                <p>
                  The solution provided Heritage with a competitive edge by
                  enhancing customer satisfaction, optimizing internal
                  operations, and supporting future growth.
                </p>
              </div>
            </div>

            <div className="col-12">
              <div className="mobCaseImgWrap">
                <img src={csaeImg1} alt="" />
              </div>
              <div className="mobCaseDataWrap">
                <h4>2. Utsav Jewels</h4>
                <p>
                  Utssav Jwelles had a strong presence in the offline market but
                  wanted to expand their reach to a wider audience by selling
                  their products online. They approached us at Version Next to
                  help them needed a user-friendly e-commerce website that
                  provided a seamless shopping experience to their customers.
                </p>
                <p>
                  To address Utssav Jwelles' requirements, we designed and
                  developed an e-commerce with mobile app for Utssav Jwelles to
                  manage their orders and deliveries. The app allowed them to
                  view all orders and track their status in real-time. The
                  application was built using a modern stack of technologies to
                  ensure fast loading times and smooth checkout processes.
                </p>
                <p>
                  At Version Next, we take pride in delivering high-quality
                  solutions that are tailored to our client's business needs.
                  Our collaboration with Utssav Jwelles is an example of how we
                  leverage our expertise in web and mobile app development to
                  help businesses achieve their goals.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="absBtnWrap mob_Abs_btn">
        <ExpertButton rText="Get Project Quote" rName="rAnimeBtn qouteBtn" />
      </div>
    </div>
  );
}

export default CaseStudyNew;
