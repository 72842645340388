import React from "react";
import {
  aiMl,
  appDevImg,
  devops,
  digitalMarket,
  iot,
  qa,
  uiuxImg,
  webDevImg,
} from "..";
import TransparentRoundedBtn from "../CTA/TransparentRoundedBtn";

function ServicesBlocks() {
  return (
    <div className="container">
      <div className="sectionTitle">
        <h2>
          Services We <span className="textGrade">Provide</span>
        </h2>
        <p>
          Our team supports you at every stage of the software development
          life-cycle: from product discovery to deployment and post-release
          support
        </p>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftServiceBox">
            <div className="serviceImg">
              <img src={webDevImg} alt="Web Developement" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightServiceBox">
            <div className="serviceData">
              <h2>Web app development</h2>
              <p>
                Unlock the potential of your business with our top-notch web
                development services. From creating responsive and user-friendly
                websites to building robust web applications, our team leverages
                the latest technologies to deliver high-performance solutions
                that engage and impress your audience.
              </p>
            </div>
            <TransparentRoundedBtn
              rText="Read More"
              rName="rAnimeBtn blackImg"
              rLink="/web-app-development"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftServiceBox">
            <div className="serviceImg">
              <img src={appDevImg} alt="Mobile App Developement" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightServiceBox">
            <div className="serviceData">
              <h2>Mobile app development</h2>
              <p>
                At Version Next Technologies, we specialize in creating
                cutting-edge mobile applications that cater to your specific
                needs. Our team of skilled developers ensures seamless
                functionality and stunning user experiences for both iOS and
                Android platforms.
              </p>
            </div>
            <TransparentRoundedBtn
              rText="Read More"
              rName="rAnimeBtn blackImg"
              rLink="/mobile-app-development"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftServiceBox">
            <div className="serviceImg">
              <img src={uiuxImg} alt="Design and Graphics" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightServiceBox">
            <div className="serviceData">
              <h2>Design and Graphics</h2>
              <p>
                Our talented designers and graphic artists bring your ideas to
                life with captivating visuals and stunning designs. Whether you
                need a logo, branding materials, or eye-catching graphics for
                your website or marketing campaigns, we deliver aesthetically
                pleasing solutions that leave a lasting impact.
              </p>
            </div>
            <TransparentRoundedBtn
              rText="Read More"
              rName="rAnimeBtn blackImg"
              rLink="/design-and-graphics"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftServiceBox">
            <div className="serviceImg">
              <img src={devops} alt="DevOps" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightServiceBox">
            <div className="serviceData">
              <h2>DevOps</h2>
              <p>
                Streamline your software development and deployment processes
                with our DevOps expertise. We help you optimize collaboration,
                automate workflows, and enhance the efficiency of your
                development cycle, ensuring faster and more reliable software
                delivery.
              </p>
            </div>
            <TransparentRoundedBtn
              rText="Read More"
              rName="rAnimeBtn blackImg"
              rLink="/devops"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftServiceBox">
            <div className="serviceImg">
              <img src={iot} alt="IoT" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightServiceBox">
            <div className="serviceData">
              <h2>IoT</h2>
              <p>
                Experience the power of connected devices with our Internet of
                Things (IoT) solutions. We design and develop IoT applications
                that enable seamless communication and control between devices,
                empowering businesses to collect valuable data and drive
                innovation across various industries.
              </p>
            </div>
            <TransparentRoundedBtn
              rText="Read More"
              rName="rAnimeBtn blackImg"
              rLink="/internet-of-things"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftServiceBox">
            <div className="serviceImg">
              <img src={digitalMarket} alt="Digital Marketing" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightServiceBox">
            <div className="serviceData">
              <h2>Digital Marketing</h2>
              <p>
                Our comprehensive digital marketing services help you establish
                a strong online presence and reach your target audience
                effectively. From SEO and social media marketing to content
                creation and paid advertising, we employ result-oriented
                strategies that maximize your brand's visibility and drive
                conversions.
              </p>
            </div>
            <TransparentRoundedBtn
              rText="Read More"
              rName="rAnimeBtn blackImg"
              rLink="/digital-marketing"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftServiceBox">
            <div className="serviceImg">
              <img src={aiMl} alt="AI and ML" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightServiceBox">
            <div className="serviceData">
              <h2>AI and ML</h2>
              <p>
                Leverage the potential of artificial intelligence and machine
                learning to gain valuable insights and automate complex tasks.
                Our skilled team develops intelligent algorithms and models that
                empower businesses to make data-driven decisions, enhance
                efficiency, and deliver personalized experiences.
              </p>
            </div>
            <TransparentRoundedBtn
              rText="Read More"
              rName="rAnimeBtn blackImg"
              rLink="/ai-and-ml"
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftServiceBox">
            <div className="serviceImg">
              <img src={qa} alt="QA" />
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightServiceBox">
            <div className="serviceData">
              <h2>QA</h2>
              <p>
                Ensure the quality and reliability of your software products
                with our comprehensive quality assurance services. Our
                meticulous testing processes and experienced QA engineers
                identify and address any issues or bugs, ensuring that your
                applications meet the highest standards of performance,
                usability, and security.
              </p>
            </div>
            <TransparentRoundedBtn
              rText="Read More"
              rName="rAnimeBtn blackImg"
              rLink="/quality-assurance"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesBlocks;
