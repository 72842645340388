import React, { useState } from "react";
import SubmitTransperentButton from "../CTA/SubmitTransperentButton";
import ReCAPTCHA from "react-google-recaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Plans } from "../DigitalMarketingData/Plans";
import axios from "axios";
import Lottie from "react-lottie";
import success from "../Homepage/HomeSliderAnimation/success.json";
function SeoFrom() {
  const testSiteKey = "6Lc7Ze8lAAAAALRp1WYcyt_QvdI7dnWwZsAf-XB6";

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    robotChecked: false,
    aboutProject: "",
    attachment: null, // Set the initial value to null
  });
  // console.log(formData);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");

  const [textareaHeight, setTextareaHeight] = useState("0");
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    robot: "",
    aboutProject: "",
    attachment: "",
  });
  const [successMessage, setSuccessMessage] = useState("");

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhoneNumberChange = (event) => {
    const inputPhoneNumber = event.target.value;
    const sanitizedPhoneNumber = inputPhoneNumber.replace(/[^0-9]/g, "");
    setFormData((prevData) => ({
      ...prevData,
      phoneNumber: sanitizedPhoneNumber,
    }));
  };

  const handleTextareaChange = (event) => {
    const textareaLineHeight = 2;
    const minRows = 1;

    event.target.style.height = "auto";

    const currentHeight = event.target.scrollHeight;
    const minHeight = textareaLineHeight * minRows;

    event.target.style.height = `${Math.max(currentHeight, minHeight)}px`;

    setFormData((prevData) => ({
      ...prevData,
      aboutProject: event.target.value,
    }));
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);

      const reader = new FileReader();

      setFormData((prevData) => ({
        ...prevData,
        file_name: selectedFile.name,
      }));

      reader.onload = (e) => {
        // When the file is loaded, convert it to base64 and set it in FormData
        const base64File = e.target.result.split(",")[1]; // Get the base64 data after the comma
        setFormData((prevData) => ({
          ...prevData,
          attachment: base64File,
        }));
      };

      reader.readAsDataURL(selectedFile);
      // setFormData((prevData) => ({
      //   ...prevData,
      //   attachment: selectedFile, // Set the attachment field to the selected file name
      // }));
      if (isValidMimeType(selectedFile.type)) {
        setFile(selectedFile);
        setFileName(selectedFile.name);
  
        const reader = new FileReader();
  
        setFormData((prevData) => ({
          ...prevData,
          file_name: selectedFile.name,
        }));
  
        reader.onload = (e) => {
          // When the file is loaded, convert it to base64 and set it in FormData
          const base64File = e.target.result.split(',')[1]; // Get the base64 data after the comma
          setFormData((prevData) => ({
            ...prevData,
            attachment: base64File,
          }));
        };
  
        reader.readAsDataURL(selectedFile);
      }
      else {
        // Invalid MIME type
        setFile(null);
        setFileName("");
        setFormData((prevData) => ({
          ...prevData,
          attachment: null, // Reset the attachment field to null if the file is invalid
        }));
        alert("Invalid file type. Please select a valid file.");
      }
    } else {
      setFile(null);
      setFileName("");
      setFormData((prevData) => ({
        ...prevData,
        attachment: null, // Reset the attachment field to null if no file is selected
      }));
    }
  };
  const isValidMimeType = (fileType) => {
    // Define the allowed MIME types here
    const allowedMimeTypes = ["image/jpeg", "image/png", "application/pdf"];
  
    // Check if the selected file's MIME type is in the allowedMimeTypes array
    return allowedMimeTypes.includes(fileType);
  };
  // const testSiteKey = "6Lc7Ze8lAAAAALRp1WYcyt_QvdI7dnWwZsAf-XB6";
  const [checkedSeo, setCheckedSeo] = useState([]);
  const [checkedPpc, setCheckedPpc] = useState([]);
  const [checkedSmo, setCheckedSmo] = useState([]);
  // const [name, setName] = useState("");
  // const [email, setEmail] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [aboutProject, setAboutProject] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isSuccessMessageVisible, setIsSuccessMessageVisible] = useState(false);
  const validateForm = () => {
    let valid = true;
    const errors = {
      name: "",
      email: "",
      phoneNumber: "",
      robot: "",
      aboutProject: "",
      attachment: "",
    };

    if (!formData.name.trim()) {
      valid = false;
      errors.name = "Please enter your name.";
    }

    if (!formData.email.trim()) {
      valid = false;
      errors.email = "Please enter your email.";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        valid = false;
        errors.email = "Please enter a valid email address.";
      }
    }

    if (!formData.phoneNumber.trim()) {
      valid = false;
      errors.phoneNumber = "Please enter your phone number.";
    } else {
      const phoneNumberRegex = /^\d{10}$/;
      if (!phoneNumberRegex.test(formData.phoneNumber)) {
        valid = false;
        errors.phoneNumber = "Please enter a valid 10-digit phone number.";
      }
    }

    if (!formData.aboutProject.trim()) {
      valid = false;
      errors.aboutProject = "Please provide information about your project.";
    }

    if (!formData.robotChecked) {
      valid = false;
      errors.robot = "Please verify that you are not a robot.";
    }
    setFormErrors(errors);
    return valid;
  };

  const handleSubmit = async (event) => {
    formData.seoModulesRqst = checkedSeo;
    formData.ppcModulesRqst = checkedPpc;
    formData.smoModulesRqst = checkedSmo;

    event.preventDefault();

    if (validateForm()) {
      try {
        const response = await axios.post(
          "https://version-next.com/digital-marketing-form.php",
          formData
        );

        if (response.data.type == "success") {
          // console.log(formData);
          setSuccessMessage("Form Submitted Successfully!");
          setIsFormSubmitted(true);
          setIsSuccessMessageVisible(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setFormErrors(error.response.data.errors);
        } else {
          console.error("Error submitting form:", error);
          console.log(error);
        }
      }
    }
  };

  const handleCloseForm = () => {
    setIsFormSubmitted(false);
    setSuccessMessage("");
    setIsSuccessMessageVisible(false);
    setFileName("");
    setFile(null);
    setTextareaHeight("0");
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      robotChecked: false,
      aboutProject: "",
    });
    setFormErrors({
      name: "",
      email: "",
      phoneNumber: "",
      robot: "",
      aboutProject: "",
      attachment: "",
    });
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: success,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  // if (isFormSubmitted) {
  //   return (
  //     <div>
  //       <div className="overlayBgMsg"></div>
  //       <div className="successMessage">
  //         <div className="animDiv">
  //         <Lottie options={defaultOptions} className="animeGIf" />
  //         </div>
  //         <h3 className="successMsgText">{successMessage}</h3>
  //         <button onClick={handleCloseForm} className="closPopBtn">
  //           <i className="fa fa-close"></i>
  //         </button>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      {isSuccessMessageVisible && (
        <div>
          <div className="overlayBgMsg"></div>
          <div className="successMessage">
            <div className="animDiv">
              <Lottie options={defaultOptions} className="animeGIf" />
            </div>
            <h3 className="successMsgText">{successMessage}</h3>
            <button onClick={handleCloseForm} className="closPopBtn">
              <i className="fa fa-close"></i>
            </button>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit} className="seoForm">
        <div className="seo-package-container__heading">
          <div className="text-center">
            <div className="col-lg-12 seo-package-container__package-plan-details">
              <h3>Build Your Plan</h3>

              <div className="formFields">
                <div className="inputWrap">
                  <div className="form-floating mb-5 col-md-4 cutmWidth-6 noPad">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="TypeHere"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    <label>Name*</label>
                    {formErrors.name && (
                      <span className="errorMessage">{formErrors.name}</span>
                    )}
                  </div>
                  <div className="form-floating mb-5 col-md-4 cutmWidth-6 noPad">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="TypeHere"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    <label>Enter your current login email*</label>
                    {formErrors.email && (
                      <span className="errorMessage">{formErrors.email}</span>
                    )}
                  </div>
                  <div className="form-floating mb-5 col-md-4 cutmWidth-6 noPad">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="TypeHere"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handlePhoneNumberChange}
                    />
                    <label>Phone Number*</label>
                    {formErrors.phoneNumber && (
                      <span className="errorMessage">
                        {formErrors.phoneNumber}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-7 planSelect">
              <div className="seo-package-container__plan">Select modules</div>
            </div>
            <div className="col-lg-5 seo-package-container__package-plan">
              <div className="row no-gutters myRow">
                <div className="col-3 package-plan-content bronze-plan">
                  <div className="seo-package-container__plan">SEO</div>
                </div>

                <div className="col-3 package-plan-content silver-plan">
                  <div className="seo-package-container__plan">PPC</div>
                </div>

                <div className="col-3 package-plan-content gold-plan">
                  <div className="seo-package-container__plan">SMO</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="seo-package-container__content">
          {Plans.map((plan, index) =>
            plan.subname ? (
              <div className="row no-gutters" key={index} id={index}>
                <div className="col-lg-5 seo-package-container__package-plan-details">
                  <div className="seo-package-container__details subtitle">
                    {plan.subname}
                  </div>
                </div>
                <div className="col-lg-7 seo-package-container__package-plan">
                  <div className="row no-gutters myRow">
                    <div className="col-3 package-plan-content bronze-plan">
                      &nbsp;
                    </div>
                    <div className="col-3 package-plan-content silver-plan">
                      &nbsp;
                    </div>
                    <div className="col-3 package-plan-content gold-plan">
                      &nbsp;
                    </div>
                    <div className="col-3 package-plan-content platinum-plan">
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row no-gutters " key={index} id={index}>
                <div className="col-lg-7 seo-package-container__package-plan-details">
                  <div className="seo-package-container__details">
                    {plan.name}
                  </div>
                </div>

                <div className="col-lg-5 seo-package-container__package-plan">
                  <div className="row no-gutters myRow">
                    <div className="col-3 package-plan-content bronze-plan">
                      <div
                        className={`yesIcon animated-checkbox ${
                          checkedSeo.includes(plan.name) ? "checked" : ""
                        }`}
                        onClick={() => {
                          const updatedCheckedSeo = checkedSeo.includes(
                            plan.name
                          )
                            ? checkedSeo.filter((item) => item !== plan.name)
                            : [...checkedSeo, plan.name];
                          setCheckedSeo(updatedCheckedSeo);
                          console.log(updatedCheckedSeo);
                        }}
                      >
                        <div className="checkmark"></div>
                      </div>
                    </div>

                    <div className="col-3 package-plan-content silver-plan">
                      <div
                        className={`yesIcon animated-checkbox ${
                          checkedPpc.includes(plan.name) ? "checked" : ""
                        }`}
                        onClick={() => {
                          const updatedCheckedPpc = checkedPpc.includes(
                            plan.name
                          )
                            ? checkedPpc.filter((item) => item !== plan.name)
                            : [...checkedPpc, plan.name];
                          setCheckedPpc(updatedCheckedPpc);
                          console.log(updatedCheckedPpc);
                        }}
                      >
                        <div className="checkmark"></div>
                      </div>
                    </div>

                    <div className="col-3 package-plan-content gold-plan">
                      <div
                        className={`yesIcon animated-checkbox ${
                          checkedSmo.includes(plan.name) ? "checked" : ""
                        }`}
                        onClick={() => {
                          const updatedCheckedSmo = checkedSmo.includes(
                            plan.name
                          )
                            ? checkedSmo.filter((item) => item !== plan.name)
                            : [...checkedSmo, plan.name];
                          setCheckedSmo(updatedCheckedSmo);
                          console.log(updatedCheckedSmo);
                        }}
                      >
                        <div className="checkmark"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
          <div className="formFields borderTop">
            <div className="form-floating mb-5">
              <textarea
                type="text"
                className="form-control textInput"
                placeholder="TypeHere"
                style={{ height: textareaHeight }}
                name="aboutProject"
                value={formData.aboutProject}
                onChange={handleTextareaChange}
              />
              <label>Any additional project details*</label>
              {formErrors.aboutProject && (
                <span className="errorMessage">{formErrors.aboutProject}</span>
              )}
              <div className="floatAttach" id={fileName}>
                {file ? (
                  <div className="fileSelected">
                    <span className="fileName">
                      {file.name.split(".")[0].length > 15
                        ? `${file.name.split(".")[0].substring(0, 10)}...`
                        : file.name.split(".")[0]}
                    </span>
                    <span className="fileType">
                      .{file.name.split(".").pop()}
                    </span>
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => setFile(null)}
                    />
                  </div>
                ) : (
                  <label className="filelabel">
                    <FontAwesomeIcon icon={faPaperclip} />
                    <input
                      className="FileUpload1"
                      id="FileInput"
                      name="attachment"
                      type="file"
                      onChange={handleFileSelect}
                    />
                  </label>
                )}
              </div>
              {formErrors.attachment && (
                <span className="errorMessage">{formErrors.attachment}</span>
              )}
            </div>
            <div className="checkBoxWrap mb-5">
              <div className="captcta">
                <ReCAPTCHA
                  sitekey={testSiteKey}
                  onChange={(value) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      robotChecked: true,
                    }))
                  }
                />
              </div>
              <SubmitTransperentButton
                rText="Send Message"
                rName="rAnimeBtn blackImg"
                disabled={!formData.robotChecked}
              />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default SeoFrom;
