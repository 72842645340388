export const YourNeed = [
{
 id : 0,
 title: "Customized DevOps Advisory",
 description: "Our team of DevOps developers offers professional consulting services in the realm of DevOps, encompassing containerization, server assistance, server security, orchestration, and virtualization.",
 img: "https://dev.version-next.com/static/media/needImg1.png"
},
{
    id : 1,
    title: "DevOps Solutions for Enterprises",
    description: "Our DevOps developers create applications tailored for large-scale enterprises, ensuring they are both scalable and securely reliable.",
    img: "https://dev.version-next.com/static/media/needImg2.png"
},
{
    id : 2,
    title: "DevOps Developers with Exclusive Focus",
    description: "Recruit specialized DevOps developers proficient in crafting interactive user interfaces for enterprise applications, harnessing the potential of DevOps.",
    img: "https://dev.version-next.com/static/media/needImg3.png"
},
{
    id : 3,
    title: "Automation in DevOps",
    description: "Our online DevOps developers automate the complete delivery cycle, ensuring that deployments and rollbacks are executed with a single click, minimizing risks and maximizing productivity.",
    img: "https://dev.version-next.com/static/media/needImg4.png"
},
{
    id : 4,
    title: "Configuration and Integration in DevOps",
    description: "Recruit dedicated DevOps developers who possess the expertise to proficiently oversee your streamlined operations through our comprehensive DevOps configuration and integration services.",
    img: "https://dev.version-next.com/static/media/needImg5.png"
},
{
    id : 5,
    title: "DevOps Platform-as-a-Service (PaaS)",
    description: "Our team of DevOps developers offers optimal solutions for devising, planning, and implementing business promotion processes through the utilization of Platform-as-a-Service (PaaS) mechanisms.",
    img: "https://dev.version-next.com/static/media/needImg1.png"
}
]