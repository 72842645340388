import React  from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import DevServicesSection from "../components/Homepage/DevelopPageComponents/DevServicesSection";
import Industries from "../components/Homepage/IndustriesSection/Industries";
import VntWork from "../components/ServicesPageComponents/VntWork";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import { Accodion } from "../components/Accordion/AccordionItem";
import FaqVideo from "../components/FaqVideo/FaqVideo";
import TransparentRoundedBtn from "../components/CTA/TransparentRoundedBtn";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WorkSliderItem from "../components/ServicesPageComponents/WorkSliderItem";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import BlogSection from "../components/BlogPosts/BlogSection";
import {
  arvindprofileImg,
  float1,
  float2,
  process1,
  process2,
  process3,
  process4,
  process5,
  process6,
  process7,
} from "../components";
import SeoFrom from "../components/DigitalMarketingData/SeoFrom";
import { Helmet } from "react-helmet";
function DegitalMarketing() {

  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
 <>
 <Helmet>
<title>Digital Marketing Consulting & Services | Drive Growth & Engagement</title>
<meta name="description" content="Boost your online presence with our comprehensive digital marketing services. From SEO to social media, we drive growth and engagement to help your business thrive." />

<link rel="canonical" href="https://version-next.com/digital-marketing" />
    
<meta property="og:title" content="Digital Marketing Consulting & Services | Drive Growth & Engagement" />
<meta property="og:description" content="Boost your online presence with our comprehensive digital marketing services. From SEO to social media, we drive growth and engagement to help your business thrive." />
<meta property="og:url" content="https://version-next.com/digital-marketing" />
<meta property="og:type" content="website" />
<meta property="og:image" content="https://version-next.com/static/media/versionnext-logo.abca9ec7.svg" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Digital Marketing Consulting & Services | Drive Growth & Engagement" />
<meta name="twitter:description" content="Boost your online presence with our comprehensive digital marketing services. From SEO to social media, we drive growth and engagement to help your business thrive." />
<meta name="twitter:image" content="https://version-next.com/static/media/versionnext-logo.abca9ec7.svg" />
</Helmet>
      <div className="devlopPage mainDigitalMarketing" id="devlopPage">
        <div className="devTopSection marketingBg">
          <DevelopBannerSection
            line1="Digital Marketing"
            line2=" Consulting &"
            line3=" Services"
            BannerPara="Digital services encompass a wide range of solutions that leverage technology and the internet to drive business growth and enhance customer experiences. From web development and mobile app design to digital marketing and e-commerce solutions, our digital services empower businesses to expand their online presence."
          />
          <div className="shapeSvg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  className="fil0"
                  d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="devCoreServiceSection">
          <DevServicesSection
            // secTitle="Our core services"
            secMainTitle="Digital Marketing With Finesse"
            secDesc="At VNT (Version Next Technologies), we're digital marketing experts, orchestrating success through deep expertise, innovative strategies, and a track record of exceeding expectations. Our team constantly innovates with the latest industry trends, tools, and data-driven insights to boost brand visibility, traffic, and conversions. With a focus on ROI and customer dedication, we provide holistic digital solutions to help businesses conquer the digital realm."
          />
        </div>
        <section id="industriesSection" className="industriesSection">
          <div className="rightTop"></div>
          <Industries
            char1="Our"
            char2="Services"
            char3="In"
            char4="Digital Marketing"
            secPara="In the realm of digital marketing, we offer a comprehensive range of services to help businesses thrive and achieve their marketing goals. Our services include:"
            icon1="health_and_safety"
            cardTitle1="Search Engine Optimization (SEO)"
            cardDesc1="We optimize your website to improve search engine rankings, increase organic traffic, and enhance online visibility."
            cardLink1="#"
            icon2="school"
            cardTitle2="Pay-Per-Click (PPC) Advertising"
            cardDesc2="We create and manage targeted PPC campaigns on platforms like google and social media to drive qualified traffic and maximize ROI."
            cardLink2="#"
            icon3="account_balance"
            cardTitle3="Social Media Marketing"
            cardDesc3="We develop tailored strategies for social media platforms to engage your target audience, build brand awareness, and foster customer loyalty."
            cardLink3="#"
            icon4="volunteer_activism"
            cardTitle4="Content Marketing"
            cardDesc4="We create compelling and valuable content that resonates with your audience, attracts organic traffic, and positions your brand as an industry thought leader."
            cardLink4="#"
            icon5="inventory"
            cardTitle5="Email Marketing"
            cardDesc5="We design and implement effective email marketing campaigns to nurture leads, drive conversions, and build strong relationships with your customers."
            cardLink5="#"
            icon6="storefront"
            cardTitle6="Influencer Marketing"
            cardDesc6="We design dynamic influencer marketing campaigns to leverage the reach and credibility of influential individuals within your industry or niche to promote your brand, products, or services."
            cardLink6="#"
          />
          <div className="leftBtm"></div>
        </section>
        <section className="wntWorkSection">
          <div className="container">
            <VntWork
              subText="How We"
              mainText="Work?"
              paratext="At VNT (Version Next Technologies), our work process is designed to ensure a seamless and successful collaboration with our clients. Here's an overview of how we work:"
            />
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-12 col-12">
                <div className="workSliderWrapper" id="digiMarketSlider">
                  <Slider {...settings} className="sliderWork slider-with-gap digiSlider">
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Discovery and Consultation"
                        number="1"
                        sliderDesc="We begin by having in-depth discussions to understand your business, goals, target audience, and specific requirements. This helps us gain insights into your needs and enables us to create a tailored digital marketing strategy."
                        slideImg={process1}
                        imgclassName="extraImgClass extraWidth"
                      />
                    </div>

                    <div className="slide-item">
                      <WorkSliderItem
                        title="Strategy Development"
                        number="2"
                        sliderDesc="Based on the information gathered, we develop a comprehensive digital marketing strategy that aligns with your goals and objectives. This includes defining key performance indicators (KPIs), identifying target audience segments, selecting appropriate marketing channels, and outlining the campaign roadmap."
                        slideImg={process2}
                        imgclassName="extraImgClass"
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Implementation and Execution"
                        number="3"
                        sliderDesc="Once the strategy is approved, our skilled team starts implementing and executing the digital marketing campaigns. This involves creating engaging content, optimizing websites for search engines, running paid advertising campaigns, managing social media accounts, and implementing other agreed-upon tactics."
                        slideImg={process3}
                        imgclassName="extraImgClass imgXwidth"
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Monitoring and Optimization"
                        number="4"
                        sliderDesc="We closely monitor the performance of your campaigns, tracking key metrics and making data-driven decisions. By continuously analyzing the results, we identify areas of improvement and optimize the campaigns to maximize their effectiveness and ROI."
                        slideImg={process4}
                        imgclassName="extraImgClass"
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Reporting and Analysis"
                        number="5"
                        sliderDesc="We provide regular reports and analysis to keep you informed about the progress and success of your digital marketing campaigns. These reports include key metrics, insights, and actionable recommendations to help you make informed decisions and further refine your marketing strategy."
                        slideImg={process5}
                        imgclassName="extraImgClass"
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Collaboration and Communication"
                        number="6"
                        sliderDesc="Throughout the process, we maintain open and transparent communication, keeping you updated on the progress, seeking your input, and addressing any questions or concerns promptly. We value collaboration and consider you an active partner in the entire process."
                        slideImg={process6}
                        imgclassName="extraImgClass"
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Ongoing Support and Growth"
                        number="7"
                        sliderDesc="Our partnership doesn't end with the completion of campaigns. We offer ongoing support, monitoring, and optimization to ensure the long-term success of your digital marketing efforts. We stay updated with the latest industry trends and proactively provide suggestions to keep your marketing strategies ahead of the curve."
                        slideImg={process7}
                        imgclassName="extraImgClass absImg"
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="seo-package-container">
          <div className="container">
            <div className="seo-package-container__info">
              Our plans are bespoke and offer maximum value for the budget.
              <br />
              Check out our affordable SEO packages here:
            </div>

            <div className="seo-package-container__content-container">
              <div className="seo-package-container__title" id="disNone">
                <h2>Explore our SEO Packages, Plans &amp; Pricing</h2>
              </div>
              
              <SeoFrom/>
              
            </div>
          </div>
        </section>
        <section className="whatMakesSection lessPad" id="whatMakesSection">
          <DevDiff
            float1={float1}
            float2={float2}
            Char1="What Makes"
            Char2="us"
            Char3="Different?"
            diffDescription="At our core, what makes us different is our unwavering commitment to excellence and our customer-centric approach. We believe in going above and beyond to deliver exceptional results that exceed client expectations. With a team of highly skilled professionals, we bring a unique blend of expertise, innovation, and creativity to every project. We take the time to understand your business, goals, and challenges, allowing us to provide customized and personalized solutions that address your specific needs. Our focus is not just on delivering a service but building long-lasting partnerships, guiding you towards success in the ever-evolving digital landscape."
          />
        </section>

        <section className="IndusExpertSection">
          <IndusExpert
            cName="Version Next Technologies"
            cSecTitle="Why Hire Version Next Technologies as Digital Marketing?"
            profileName="Mr. Arvind Baraskar"
            designation="Buisness Head"
            cardTitle1="Expertise and Experience"
            cardData1="Version Next Technologies brings a wealth of expertise and experience in digital marketing. Our team of professionals stays up to date with the latest trends, tools, and strategies to deliver effective results."
            cardTitle2="Customized Strategies"
            cardData2="We understand that every business is unique. We take a tailored approach, developing customized digital marketing strategies that align with your goals, target audience, and industry."
            cardTitle3="Comprehensive Services"
            cardData3="We offer a wide range of digital marketing services, which includes SEO, PPC, Social Media Marketing, Content Marketing, and many more."
            cardTitle4="Measurable Results"
            cardData4="We are focused on delivering measurable results. Through careful analysis, tracking, and reporting, we provide transparent insights into the performance of your digital marketing campaigns."
            profileImg={arvindprofileImg}
            facebookLink="https://www.facebook.com/arvindv.baraskar"
            facebookIcon="fab fa-facebook-square"
            twiiterLink="https://twitter.com/ArvindBaraskar"
            twitterIcon="fab fa-twitter-square"
            linkdinLink="https://www.linkedin.com/in/arvindbaraskar/"
            linkdinIcon="fab fa-linkedin"
          />
        </section>
        <section className="commonFaq">
          <div className="container">
            <div className="sectionTitle">
              <h2>
                Frequently Asked <span className="textGrade">Questions</span>
              </h2>

              <p>
                Singling out common points and possible difficulties which can
                be encountered in EHR, EMR, and Patient Portal software
                development
              </p>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6 col-12">
                <Accodion />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <FaqVideo />
              </div>
            </div>
            <div className="readMoreBtnWrap">
              <TransparentRoundedBtn
                rText="Read More"
                rLink="/faqs"
                rName="rAnimeBtn readMoretBtn"
              />
            </div>
          </div>
        </section>
        <BlogSection />
      </div>
 </>
  );
}

export default DegitalMarketing;
