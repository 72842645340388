import React from "react";
import { hiredTeam } from "../../../components";

function WhyHire(props) {
  return (
    <div className="container">
      <div className="row whyHireRow">
        <div className="col-lg-6">
          <div className="whyLeftBox">
            <img src={hiredTeam} alt="Why Hire VNT Developers" />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="whyRightBox">
            <h3>Why Hire {props.techName} Developers from VNT?</h3>
            <p>
             {props.techPara}
            </p> 
            <ul>
                <li>Experienced {props.techName} development team</li>
                <li>Transparent solutions</li>
                <li>IP Rights Protection</li>
                <li>Maintain oversight & control</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyHire;
