import React from "react";
import { qaservice1, qaservice2, qaservice3, qaservice4, qaservice5, qaservice6, qaservice7, qaservice8, qaservice9 } from "..";


function QaServices() {
  return (
    <div className="container">
      <div className="sectionTitle">
        <h2>
          Quality Assurance <span className="textGrade">Services We Offer</span>
        </h2>
        <p>
          Our QA specialists are committed to delivering successful solutions
          while minimizing risks. Our comprehensive software testing practices
          encompass a wide array of parameters, including functionality,
          behavior, performance, scalability, compatibility, and more.
        </p>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Planning for Strategic Testing</h3>
            <p>
              The quantity and sequence of QA activities can vary depending on
              the software development project's size and nature, contingent
              upon its scope and objectives. However, the initial step remains
              consistent. Our team of QA experts will conduct a thorough
              analysis of the project's requirements to formulate a Software
              Testing Strategy and execution plan.
            </p>
            <p>
              This process involves scrutinizing the system architecture and
              technologies for inconsistencies, allowing us to detect costly
              errors in the early stages. Occasionally, the preparation of QA
              documentation goes hand in hand with the testing strategy
              formulation.
            </p>
            <ul className="serviceList">
              <li>Review of Requirements</li>
              <li>Designing the Test Strategy</li>
              <li>Planning for Test Execution</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img src={qaservice1} alt="Planning for Strategic Testing" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Quality Assurance Documentation</h3>
            <p>
              VNT places a significant emphasis on the significance of
              documentation in software development. We take great care to
              ensure the quality of our documentation, ensuring that every team
              member can easily track any modifications made to requirements or
              documents.
            </p>
            <p>
              Following the establishment of requirements, our QA team utilizes
              tools such as TestRail and Zephyr to author and organize test
              cases. These test cases provide precise instructions to our QA
              engineers, guiding them on the actions required to validate the
              software's functionality.
            </p>
            <ul className="serviceList">
              <li>Test Case Creation</li>
              <li>Test Case Preparation</li>
              <li>Creating Test Checklists and Data</li>
              <li>Maintenance of Test Reports</li>
              <li>Creating Software Quality Metrics</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img src={qaservice2} alt="Quality Assurance Documentation" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Manual Quality Assurance Testing</h3>
            <p>
              We provide comprehensive manual quality assurance and software
              testing services at all project stages. We identify errors and
              meticulously monitor them to ensure they are rectified prior to
              the final product delivery. Our experienced software quality
              assurance team is proficient in testing across all facets.
            </p>
            <ul className="serviceList">
              <li>Database Testing</li>
              <li>Smoke Testing</li>
              <li>GUI Testing</li>
              <li>Sanity Testing</li>
              <li>Functional & Regression Testing</li>
              <li>Browser Compatibility Testing</li>
              <li>End to End Testing</li>
              <li>Product Verification Testing</li>
              <li>Exploratory Testing</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img src={qaservice3} alt="Manual Quality Assurance Testing" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Automated Web Testing</h3>
            <p>
              Employ our Quality Assurance (QA) specialists who conduct
              automated testing of web applications, ensuring exceptional depth,
              accuracy, and efficiency. Our QA team will develop test cases and
              execute cross-browser UI tests by mimicking real user
              interactions.
            </p>
            <ul className="serviceList">
              <li>UI and mockup testing</li>
              <li>Cross-browser testing</li>
              <li>Smoke testing</li>
              <li>Functional testing</li>
              <li>Regression testing</li>
              <li>Field validation testing</li>
              <li>Acceptance testing</li>
              <li>Integration testing</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img src={qaservice4} alt="Automated Web Testing" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Testing of Mobile Applications</h3>
            <p>
              Mobility plays a crucial role in every organization's strategy.
              Functional defects and subpar user experiences frequently hinder
              its adoption. The QA team at VNT is well-prepared to address
              the intricacies of mobile devices and effectively address user
              concerns through their mobile testing proficiency. We have
              consistently assisted enterprises in deploying their products
              across a range of mobile devices, ensuring a seamless and
              exceptional user experience.
            </p>
            <ul className="serviceList">
              <li>Functional Testing</li>
              <li>User Experience Testing</li>
              <li>Compatibility Testing</li>
              <li>Performance Testing</li>
              <li>Localization Testing</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img src={qaservice5} alt="Testing of Mobile Applications" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Automation of Mobile Testing</h3>
            <p>
              Our framework has the capability to automatically conduct mobile
              app testing on all major platforms and devices. We can design and
              execute a variety of test types, including functional,
              integration, system, and UI tests, adhering to our established
              testing standards.
            </p>
            <ul className="serviceList">
              <li>Cross-platform app testing and support</li>
              <li>Native apps testing</li>
              <li>Web apps testing</li>
              <li>Use devices, emulators & cloud services for testing</li>
              <li>Run tests in parallel for speed</li>
              <li>Generate reports for 100% results visibility</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img src={qaservice6} alt="Automation of Mobile Testing" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Testing for Performance</h3>
            <p>
              The most concerning issue, often leading to site abandonment, is
              the crash of websites or applications. VNT's performance
              testing services ensure that your product can withstand
              substantial loads. Our dedicated QA team is proficient in testing
              complex, high-volume applications. Through our software testing
              methodology, we ensure that your applications maintain optimal
              functionality even during peak usage, ensuring they perform as
              anticipated. Our Performance Testing Services are designed to
              assist businesses in achieving their goals, as outlined below.
            </p>
            <ul className="serviceList">
              <li>Load Testing</li>
              <li>Stress Testing</li>
              <li>Performance Assessment</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img src={qaservice7} alt="Testing for Performance" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Automation of API Testing</h3>
            <p>
              Ensure the functionality of any APIs, whether they are third-party
              or your own, under all circumstances. Automation simplifies API
              testing, making it a faster and more straightforward process.
            </p>
            <ul className="serviceList">
              <li>
                Automation of API testing, encompassing SOAP web services and
                RESTful APIs.
              </li>
              <li>
                Compatibility with all industry-standard data serialization
                formats, including JSON, XML, plain text, and more.
              </li>
              <li>A personalized HTTPClient designed for optimal outcomes.</li>
              <li>Functional Testing Beyond the User Interface</li>
              <li>Testing Component Integration</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img src={qaservice8} alt="Automation of API Testing" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Consultation from QA Experts</h3>
            <p>
              Every organization must prioritize software testing to ensure the
              delivery of high-quality services. However, this can pose
              challenges and lead to delays if issues are identified at a later
              stage. That's why VNT provides Test Advisory Services to
              assist companies in overseeing their software testing strategies
              and offering guidance throughout the entire Software Development
              Life Cycle (SDLC). We can enhance various aspects of quality
              assurance, including personnel, procedures, and deliverables,
              thereby aiding organizations in maturing their testing approach.
            </p>
            <ul className="serviceList">
              <li>Test Plan/Strategy designing</li>
              <li>Test Tools recommendation</li>
              <li>Assessment of Test assets</li>
              <li>Test Process planning</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img src={qaservice9} alt="Consultation from QA Experts" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default QaServices;
