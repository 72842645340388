import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel, Navigation } from "swiper";
import "swiper/swiper.min.css";
import { useLocation } from "react-router";

SwiperCore.use([Mousewheel, Navigation]);
function ScrollingSlide(props) {
  const locationN = useLocation();
  const sliderOption = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    loop: false,
    breakpoints: {
      0: {
        slidesPerView: 1.5,
      },
      576: {
        slidesPerView: 1.5,
      },
      991: {
        slidesPerView: 1.5,
      },
      1024: {
        slidesPerView: 1.5,
      },
      1200: {
        // slidesPerView: locationN.pathname === "/insurance"?2:(locationN.pathname === "/fin-tech"?3:2.5),
         slidesPerView: locationN.pathname === "/insurance"?2:2.5,
      },
    },
  };
  const renderSlides = () => {
    const slideImages = [
      props.scrollSliderImg1,
      props.scrollSliderImg2,
      props.scrollSliderImg3,
      props.scrollSliderImg4,
      props.scrollSliderImg5,
      props.scrollSliderImg6,
      props.scrollSliderImg7,
      props.scrollSliderImg8,
      props.scrollSliderImg9,
      props.scrollSliderImg10,
      props.scrollSliderImg11,
      props.scrollSliderImg12,
      props.scrollSliderImg13,
    ];

    return slideImages
      .filter((img) => img) // Filter out undefined or null images
      .map((img, index) => (
        <SwiperSlide key={index} className="swiper-slide">
          <div className="mouseScrollImg">
            <img src={img} alt={`slide ${index + 1}`} />
          </div>
        </SwiperSlide>
      ));
  };
  return (
    <div className="swiper-container">
      <Swiper {...sliderOption} mousewheel={true} className="swiper-wrapper">
        {renderSlides()}
      </Swiper>
    </div>
  );
}

export default ScrollingSlide;
