import { portfolio20, portfolio21, portfolio22, portfolio23, portfolio24, portfolio25, portfolio26, portfolio27, portfolio28 } from "..";

const packagingdata = [
    {
        id: 0,
        logoname: 'version next portfolio',
        logosrc: portfolio20
    },
    {
        id: 1,
        logoname: 'version next portfolio',
        logosrc: portfolio21
    },
    {
        id: 2,
        logoname: 'version next portfolio',
        logosrc: portfolio22
    },
    {
        id: 3,
        logoname: 'version next portfolio',
        logosrc: portfolio23
    },
    {
        id: 4,
        logoname: 'version next portfolio',
        logosrc: portfolio24
    },
    {
        id: 5,
        logoname: 'version next portfolio',
        logosrc: portfolio25
    },
    {
        id: 6,
        logoname: 'version next portfolio',
        logosrc: portfolio26
    },
    {
        id: 7,
        logoname: 'version next portfolio',
        logosrc: portfolio27
    },
    {
        id: 8,
        logoname: 'version next portfolio',
        logosrc: portfolio28
    },
    
]

export default packagingdata;