export const ExpertiseData = [
    {
        id: 0,
        text: "Our developers are not just proficient in Java – they live and breathe it."
    },
    {
        id: 1,
        text: "With a deep understanding of the language's nuances, frameworks, and libraries."
    },
    {
        id: 2,
        text: "We harness the power of Java to create high-performance applications."
    },
    {
        id: 3,
        text: "Our Java developers work closely with you to understand your business objectives."
    },
    {
        id: 4,
        text: "The end product aligns seamlessly with your goals."
    },
    {
        id: 5,
        text: "Working within an iterative and adaptable framework."
    },
    {
        id: 6,
        text: "With a commitment to staying up-to-date with the latest industry trends."
    },
    {
        id: 7,
        text: "We bring fresh perspectives to your projects, resulting in truly remarkable solutions."
    },
    {
        id: 8,
        text: "Our expertise doesn't just lie in code – it extends to collaboration."
    },
    {
        id: 9,
        text: "We believe that the best solutions are born through open communication and teamwork."
    },
]