import React, { useEffect } from "react";

function BackToTop() {
  useEffect(() => {
    let offset = 100;
    let circleContainer = document.querySelector(".circle-container");
    let circlePath = document.querySelector(".circle-container path");
    let pathLength = circlePath.getTotalLength();
    circlePath.style.transition = circlePath.style.WebkitTransition = "none";
    circlePath.style.strokeDasharray = pathLength;
    circlePath.style.strokeDashoffset = pathLength;
    circlePath.getBoundingClientRect();
    circlePath.style.transition = circlePath.style.WebkitTransition =
      "stroke-dashoffset 10ms linear";

    let updateLoader = () => {
      let scrollTop = window.scrollY;
      let windowHeight = window.innerHeight;
      let docHeight = document.documentElement.scrollHeight;
      let progress = (pathLength - (scrollTop * pathLength) / (docHeight - windowHeight));
      circlePath.style.strokeDashoffset = progress;

      if (scrollTop > offset) {
        circleContainer.classList.add("active");
      } else {
        circleContainer.classList.remove("active");
      }
    };

    circleContainer.onclick = function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };

    window.onscroll = () => {
      updateLoader();
    };

    updateLoader();
  }, []);

  return (
    <div className="circle-container">
      <svg
        className="circle-progress svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>
  );
}

export default BackToTop;
