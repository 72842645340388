import React from "react";
import { useEffect } from "react";

function VerticalTimeLineSection() {
  useEffect(() => {
    const items = document.querySelectorAll(".timeline li");

    const isElementInViewport = (el) => {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    };

    const callbackFunc = () => {
      for (var i = 0; i < items.length; i++) {
        if (isElementInViewport(items[i])) {
          items[i].classList.add("in-view");
        }
      }
    };

    window.addEventListener("load", callbackFunc);
    window.addEventListener("resize", callbackFunc);
    window.addEventListener("scroll", callbackFunc);

    return () => {
      window.removeEventListener("load", callbackFunc);
      window.removeEventListener("resize", callbackFunc);
      window.removeEventListener("scroll", callbackFunc);
    };
  }, []);
  return (
    <section className="timeline">
      <ul>
        <li>
          <div>
            <time>2004</time> <span>Commenced Remote Web Design Services</span>
          </div>
        </li>

        <li>
          <div>
            <time>2005</time> <span>Initiated Bulk SMS Services</span>
          </div>
        </li>
        <li>
          <div>
            <time>2006</time> <span>Commenced Offshore Development Services</span>
          </div>
        </li>
        <li>
          <div>
            <time>2007</time> <span>Launched Hosting Services</span>
          </div>
        </li>
        <li>
          <div>
            <time>2008</time> <span>Registered the Company as a Private Limited Entity</span>
          </div>
        </li>
        <li>
          <div>
            <time>2009</time> <span>Developed the First Marathi Facebook Platform</span>
          </div>
        </li>
        <li>
          <div>
            <time>2010</time> <span>Started Mobile App Development Services</span>
          </div>
        </li>
        <li>
          <div>
            <time>2011</time> <span>Expanded the Team and Infrastructure</span>
          </div>
        </li>
        <li>
          <div>
            <time>2012</time> <span>Acquired FCB India Company</span>
          </div>
        </li>
        <li>
          <div>
            <time>2013</time> <span>Developed a Parental Control App</span>
          </div>
        </li>
        <li>
          <div>
            <time>2014</time> <span>Initiated UI/UX and Graphics Work</span>
          </div>
        </li>
        <li>
          <div>
            <time>2015</time> <span>Achieved ISO 9001 Certification</span>
          </div>
        </li>
        <li>
          <div>
            <time>2016</time> <span>Achieved ISO 27000 Certification</span>
          </div>
        </li>
        <li>
          <div>
            <time>2017</time> <span>Expanded Development Services in Various Technology Stacks</span>
          </div>
        </li>
        <li>
          <div>
            <time>2018</time> <span>Expanded to International Markets</span>
          </div>
        </li>
        <li>
          <div>
            <time>2019</time> <span>Increased Team Presence in Various Regions</span>
          </div>
        </li>
        <li>
          <div>
            <time>2020</time> <span>Acquired FCB India Company</span>
          </div>
        </li>
        <li>
          <div>
            <time>2022</time> <span>Expanded Office Infrastructure and Introduced AI and Machine Learning Technologies</span>
          </div>
        </li>
        <li>
          <div>
            <time>2023</time> <span>Celebrated 15 Years of Excellence and Anniversary</span>
          </div>
        </li>
      </ul>
    </section>
  );
}

export default VerticalTimeLineSection;
