import React from "react";
// import CommonRoundButton from "../../CTA/CommonRoundButton";
import VerticleTestimonial from "./VerticleTestimonial";
import ContactForm from "../../ContactForm/ContactForm";

function ContactUs() {
 
  return (
    <div className="container">
      <div className="row contactWrapper">
        <div className="col-md-7 noPad">
          <ContactForm/>
        </div>
        <div className="col-md-5 noPad">
          <div className="leftTetimonialWrapper">
            <VerticleTestimonial/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
