export const YourNeed = [
{
 id : 0,
 title: "Custom Python Web Application Development",
 description: "Get the services of skilled Python developers with extensive expertise in creating Python web applications that are known for their strong security, reliability, exceptional value, and impressive performance.",
 img: "https://dev.version-next.com/static/media/needImg1.png"
},
{
    id : 1,
    title: "Dedicated Python Developers",
    description: "Recruit proficient Python web developers with expertise in creating secure, scalable, and resilient web applications to cater to the needs of international enterprises.",
    img: "https://dev.version-next.com/static/media/needImg2.png"
},
{
    id : 2,
    title: "Enterprise Python Applications",
    description: "Our team of Python developers constructs applications for major corporations that are not only suitable for extensive operations but also possess scalability, dependability, and security concurrently.",
    img: "https://dev.version-next.com/static/media/needImg3.png"
},
{
    id : 3,
    title: "Python Migration & Integration Services",
    description: "Effortlessly transfer your current applications to Python using our dependable team of Python programmers, enhancing their resilience and security in the process.",
    img: "https://dev.version-next.com/static/media/needImg4.png"
},
{
    id : 4,
    title: "Services for the Development of API's",
    description: "Energize your iOS, Android, and web applications by enabling them to offer secure, stable, and thoroughly documented web services and APIs for accessing your platform's data.",
    img: "https://dev.version-next.com/static/media/needImg5.png"
},
{
    id : 5,
    title: "Applications Utilizing AI and Machine Learning",
    description: "Our proficient full-stack Python developers leverage a wide array of Python libraries to craft resilient solutions for projects centered around Machine Learning and AI.",
    img: "https://dev.version-next.com/static/media/needImg1.png"
}
]