export const YourNeed = [
{
 id : 0,
 title: "Dedicated React Developers",
 description: "Recruit proficient React programmers with expertise in crafting interactive user interfaces for enterprise applications by harnessing the capabilities of the React library.",
 img: "https://dev.version-next.com/static/media/needImg1.png"
},
{
    id : 1,
    title: "Services for React Migration & Integration",
    description: "Our team of remote React developers offers expert guidance for seamlessly integrating your current web applications and systems with React.",
    img: "https://dev.version-next.com/static/media/needImg2.png"
},
{
    id : 2,
    title: "Custom Web App Development with React",
    description: "Enlist React programmers capable of swiftly deploying React development projects and offering dependable support for ongoing endeavors.",
    img: "https://dev.version-next.com/static/media/needImg3.png"
},
{
    id : 3,
    title: "Enterprise-Level React Web Applications",
    description: "Our team of React developers constructs web applications tailored for expansive enterprises, ensuring scalability, reliability, and security in tandem.",
    img: "https://dev.version-next.com/static/media/needImg4.png"
},
{
    id : 4,
    title: "Enhanced UI/UX Development with React",
    description: "We strive to provide a great user experience across all devices and platforms by making the best use of UI/UX libraries.",
    img: "https://dev.version-next.com/static/media/needImg5.png"
},
{
    id : 5,
    title: "Interactive UI Development",
    description: "Our focus is on delivering exceptional user experiences across diverse devices and platforms, achieved through the optimal utilization of UI/UX libraries.",
    img: "https://dev.version-next.com/static/media/needImg1.png"
}
]