import React from "react";
import {
  modelClock,
  needCardImg1,
  needCardImg2,
  needCardImg3,
  needCardImg4,
  needCardImg5,
} from "..";

function NativeServicesBlocks() {
  return (
    <div className="container">
      <div className="sectionTitle">
        <h2>React Native App Development Services</h2>
      </div>
      <div className="row needDevRow">
        <div className="col-lg-4 col-md-6 col-12 needDevCardWidth">
          <div className="needDevCard">
            <div className="needImgeOuter">
              <div className="cardImgWrap">
                <img
                  src={needCardImg1}
                  alt="Tailored React Native Application Development"
                />
              </div>
            </div>
            <div className="needDevContent">
              <h3>Tailored React Native Application Development</h3>
              <p>
                Starting from Proof of Concepts (PoCs) and extending to
                comprehensive mobile app solutions, we provide React Native
                development services for iOS, Android, and web applications.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 needDevCardWidth">
          <div className="needDevCard">
            <div className="needImgeOuter">
              <div className="cardImgWrap">
                <img
                  src={needCardImg5}
                  alt="Prototyping and Visual Design Concepts"
                />
              </div>
            </div>
            <div className="needDevContent">
              <h3>Prototyping and Visual Design Concepts</h3>
              <p>
                We create wireframes and offer you design choices. Once you pick
                a wireframe from the selected options, the coding phase
                commences.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 needDevCardWidth">
          <div className="needDevCard">
            <div className="needImgeOuter">
              <div className="cardImgWrap">
                <img
                  src={needCardImg3}
                  alt="Seamless Integration of AI and ML Features"
                />
              </div>
            </div>
            <div className="needDevContent">
              <h3>Seamless Integration of AI and ML Features</h3>
              <p>
                We harness the potential of AI and ML in React Native app
                development to enhance intelligence and streamline interactions
                between humans and machines.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 needDevCardWidth">
          <div className="needDevCard">
            <div className="needImgeOuter">
              <div className="cardImgWrap">
                <img src={needCardImg4} alt="Integrating Server-Side APIs" />
              </div>
            </div>
            <div className="needDevContent">
              <h3>Integrating Server-Side APIs</h3>
              <p>
                We provide secure and flexible server-side APIs, seamlessly
                integrating them with applications to enhance their robustness
                and dynamism.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 needDevCardWidth">
          <div className="needDevCard">
            <div className="needImgeOuter">
              <div className="cardImgWrap">
                <img
                  src={modelClock}
                  alt="Quality Assurance and Application Testing"
                />
              </div>
            </div>
            <div className="needDevContent">
              <h3>Quality Assurance and Application Testing</h3>
              <p>
                We conduct comprehensive checks and record results to ensure
                efficient, bug-free coding that aligns with client expectations.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 needDevCardWidth">
          <div className="needDevCard">
            <div className="needImgeOuter">
              <div className="cardImgWrap">
                <img
                  src={needCardImg2}
                  alt="Application Maintenance and Support Services"
                />
              </div>
            </div>
            <div className="needDevContent">
              <h3>Application Maintenance and Support Services</h3>
              <p>
                We offer a contractual system for maintenance and support
                services to ensure your app remains compatible with the latest
                operating system versions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NativeServicesBlocks;
