import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Mousewheel, Navigation } from "swiper";
import "swiper/swiper.min.css";
import { xLogo } from "..";
SwiperCore.use([Mousewheel, Navigation]);
function Milestone() {
  const sliderOption = {
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    loop: false,
    spaceBetween: 0,
    slidesPerView: "3",
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      991: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <div className="container">
      <div className="swiper-container">
        <Swiper
          {...sliderOption}
          mousewheel={true}
          className="timeline swiper-wrapper"
        >
          <img src={xLogo} className="timeline__cursor" alt="logo" />
          <SwiperSlide className="swiper-slide"></SwiperSlide>

          <SwiperSlide className="swiper-slide timeline__steps">
            <div className="li ddd">
              <span className="year font-lg bold">2004</span>
              <p>Commenced Remote Web Design Services</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps">
            <div className="li ddd">
              <span className="year font-lg bold">2005</span>
              <p>Initiated Bulk SMS Services</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps">
            <div className="li ddd">
              <span className="year font-lg bold">2006</span>
              <p>Commenced Offshore Development Services</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps">
            <div className="li ddd">
              <span className="year font-lg bold">2007</span>
              <p>Launched Hosting Services</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps">
            <div className="li ddd">
              <span className="year font-lg bold">2008</span>
              <p>Registered the Company as a Private Limited Entity</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps">
            <div className="li ddd">
              <span className="year font-lg bold">2009</span>
              <p>Developed the First Marathi Facebook Platform</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps">
            <div className="li ddd">
              <span className="year font-lg bold">2010</span>
              <p>Started Mobile App Development Services</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps">
            <div className="li ddd">
              <span className="year font-lg bold">2011</span>
              <p>Expanded the Team and Infrastructure</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps">
            <div className="li ddd">
              <span className="year font-lg bold">2012</span>
              <p>Acquired FCB India Company</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps">
            <div className="li ddd">
              <span className="year font-lg bold">2013</span>
              <p>Developed a Parental Control App</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps">
            <div className="li ddd">
              <span className="year font-lg bold">2014</span>
              <p>Initiated UI/UX and Graphics Work</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps ">
            <div className="li ddd">
              <span className="year font-lg bold">2015</span>
              <p>Achieved ISO 9001 Certification</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps ">
            <div className="li ddd">
              <span className="year font-lg bold">2016</span>
              <p>Achieved ISO 27000 Certification</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps ">
            <div className="li ddd">
              <span className="year font-lg bold">2017</span>
              <p>Expanded Development Services in Various Technology Stacks</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps ">
            <div className="li ddd">
              <span className="year font-lg bold">2018</span>
              <p>Expanded to International Markets</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps ">
            <div className="li ddd">
              <span className="year font-lg bold">2019</span>
              <p>Increased Team Presence in Various Regions</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps ">
            <div className="li ddd">
              <span className="year font-lg bold">2022</span>
              <p>
                Expanded Office Infrastructure and Introduced AI and Machine
                Learning Technologies
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide timeline__steps lineNone ">
            <div className="li ddd">
              <span className="year font-lg bold">2023</span>
              <p>Celebrated 15 Years of Excellence and Anniversary</p>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide "></SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default Milestone;
