import React from "react";
import "animate.css";
// import CommonRoundButton from "../../CTA/CommonRoundButton";
import { float1, float2} from "../..";
import TransparentRoundedBtn from "../../CTA/TransparentRoundedBtn";
import RoundedCommonBtn from "../../CTA/RoundedCommonBtn";
import ExpertButton from "../../CTA/ExpertButton";

function MeetingFixed() {
  return (
    <div className="tp-news-area black-bg fix">
      <div className="container">
        <div className="row align-items-end flex-wrap-reverse flex-md-wrap">
          <div className="col-xl-6 col-lg-6 col-md-6">
            <div className="tp-news-letter-section-box-two pt-135 pb-180 wow tpfadeUp">
              
              <h2 className="tp-title tp-white-text pb-10">
                Hire a <span className="dashArrow"><span className="textGrade">Skilled</span></span> <br/>Team for Success
              </h2>
              <p>
                We assist both global startups and enterprises in assembling
                dedicated developer teams tailored to their specific software
                development needs. Build your team of experts, each with over 3+
                years of experience in diverse technology domains. Benefit from
                efficient communication and regular updates, ensuring timely
                delivery of your projects.
              </p>
              <div className="meetingBtnWrap">
                <ExpertButton
                  rText="Build Your Agile Squad"
                  rLink=""
                  rName="rAnimeBtn blackImg" 
                />
                <RoundedCommonBtn
                  rText="Our Team"
                  rLink="/about-version-next"
                  rName="rAnimeBtn meetingFillBtn"
                />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 mobNone">
            <div className="tp-newsletter-left-img pt-60 p-relative wow tpfadeLeft">
              <div className="flotImg box1">
                <img src={float1} alt="" />
              </div>
              <span className="dottetCircle"></span>
              <span className="fillCircle"></span>
              <div className="flotImg box2">
                <img src={float2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeetingFixed;
