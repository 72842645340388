import React from "react";
import DevelopBannerSection from "../components/Homepage/DevelopPageComponents/DevelopBannerSection";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HealthSliderCard from "../components/HealthcarePageComponent/HealthSliderCard";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import { Accodion } from "../components/Accordion/AccordionItem";
import FaqVideo from "../components/FaqVideo/FaqVideo";
import TransparentRoundedBtn from "../components/CTA/TransparentRoundedBtn";
import { float1, float2, vinayakprofileImg } from "../components";
import DevDiff from "../components/Homepage/DevelopPageComponents/DevDiff";
import CaseStudyNew from "../components/Homepage/CaseStudy/CaseStudyNew";
import HealthCareTabSection from "../components/HealthcarePageComponent/HealthCareTabSection";
import HealthcareTabs from "../components/Tabs/HealthcareTabs";
import { Helmet } from "react-helmet";
function HealthCare() {
  var settings = {
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
    <Helmet>
<title>Healthcare Apps & Software Development Services</title>
<meta name="description" content="Transform healthcare with cutting-edge software solutions. We create innovative tools for patient care, data management, and healthcare delivery—tailored to meet the industry's highest standards." />

<link rel="canonical" href="https://version-next.com/healthcare" />
    
<meta property="og:title" content="Healthcare Apps & Software Development Services" />
<meta property="og:description" content="Transform healthcare with cutting-edge software solutions. We create innovative tools for patient care, data management, and healthcare delivery—tailored to meet the industry's highest standards." />
<meta property="og:url" content="https://version-next.com/healthcare" />
<meta property="og:type" content="website" />
<meta property="og:image" content="https://version-next.com/static/media/versionnext-logo.abca9ec7.svg" />

<meta name="twitter:card" content="summary_large_image" />
<meta name="twitter:title" content="Healthcare Apps & Software Development Services" />
<meta name="twitter:description" content="Transform healthcare with cutting-edge software solutions. We create innovative tools for patient care, data management, and healthcare delivery—tailored to meet the industry's highest standards." />
<meta name="twitter:image" content="https://version-next.com/static/media/versionnext-logo.abca9ec7.svg" />
</Helmet>

    <section id="healthCare" className="healthCare">
      <div className="devTopSection">
        <DevelopBannerSection
          line1="Healthcare Apps &"
          line2=" Software Development"
          line3=" Services"
          BannerPara="Creating healthcare apps to provide patients with better services. Making custom software to optimize workflows at medical facilities. Digitizing healthcare to improve its interoperability."
        />
        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className="healthServicesSliderWrap">
        <div className="container">
          <div className="row sectionFlexHeading">
            <div className="col-md-6 col-12">
              <div className="sectionTitle">
                <h2>Healthcare Software Services We Offer</h2>
              </div>
            </div>
            <div className="col-md-6 col-12">
              <p className="sectionPara">
                Healthcare software development services for all industry
                institutions: medical, telemedicine and remote care, maintaining
                physical and mental well-being
              </p>
            </div>
          </div>
          <div className="healthServicesSlider">
            <Slider {...settings} className="slider-with-gap">
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Custom Software Development"
                  cardPara="Studying the customer’s business process to develop bespoke solutions, including SaaS. Compliance with industry regulations (GDPR, HIPAA, PIPEDA, and more)."
                  cardLink=""
                  btnName="Get consultation"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Software Modernization"
                  cardPara="Reengineering the existing solutions to meet the latest healthcare and telehealth standards in terms of security, usability, and interoperability."
                  cardLink=""
                  btnName="Leave a request"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Third Party Integrations"
                  cardPara="Aligning custom-built software with internal systems, EHR, EMR, Patient Portals, e-RX services for prescription solutions, etc. Integrations with interactive streaming platforms for Telehealth solutions."
                  cardLink=""
                  btnName="Order an estimate"
                />
              </div>
              <div className="slide-item">
                <HealthSliderCard
                  cardTitle="Digitalization and Optimization"
                  cardPara="Increasing the performance of healthcare and telemedicine software by automating manual processes."
                  cardLink=""
                  btnName="Share your idea"
                />
              </div>
            </Slider>
          </div>
        </div>
      </div>
      <section id="healthCareTabSection">
        <div className="container">
          <HealthCareTabSection
            tabSectionTitle1="Healthcare"
            tabSectionTitle2="Software Solutions"
            tabSectionTitle3="We Provide"
            tabSectionPara="Custom healthcare software development standalone solutions and major
      modules for larger systems. VNT's solutions optimize services
      and workflows, enhancing interaction between providers, their
      partners/clients, and patients."
          />
          <HealthcareTabs
            tabTitle1="Treatment/Patient Support"
            tabTitle2="Internal operability"
            tabTitle3="Management software"
            tabTitle4="Provided to Patients"
            tabContentTitle11="Treatment/Patient Support"
            contentList11="EHR, EMR/EPR & Patient Portals"
            contentList12="Ambulatory medical record system"
            contentList13="Practice Management Software"
            contentList14="E-prescribing software"
            contentList15="Telemedicine software"
            contentList16="Appointments scheduling"
            contentList17="Remote Patient Monitoring (RPM) with Wearable devices & IoT"
            contentList18="Healthcare CRMs"

            tabContentTitle22="Internal operability"
            contentList21="Medical research software"
            contentList22="Medical database software"
            contentList23="Clinical trial management software"
            contentList24="Medical Diagnosis Software
            (To exchange anonymized patient health records)"
            contentList25="Medical equipment management software"
            contentList26="Medical staffing software"
            contentList27="QMS for medical devices manufacturers"

            tabContentTitle33="Management software"
            contentList31="Hospital management software (HMS)"
            contentList32="Medical billing"
            contentList33="Medical imaging and visualization software"
            contentList34="Health Insurance Software"
            contentList35=""
            contentList36=""
            contentList37=""
            contentList38=""

            tabContentTitle44="Provided to Patients"
            contentList41="Medical diagnosis software
            (for patients to check symptoms and self-diagnosis)"
            contentList42="Health tracking app"
            contentList43="Personal Health Record"
          />
        </div>
      </section>
      <section id="case_study" className="case_study">
        <CaseStudyNew
          titleChar1="We Provide"
          titleChar2="Software Solutions"
          projectTitle10="1. Utsav Jewels"
          projectPara11="Utssav Jwelles had a strong presence in the offline market but wanted to expand their reach to a wider audience by selling their products online. They approached us at Version Next to help them needed a user-friendly e-commerce website that provided a seamless shopping experience to their customers."
          projectPara12="To address Utssav Jwelles' requirements, we designed and developed an e-commerce with mobile app for Utssav Jwelles to manage their orders and deliveries. The app allowed them to view all orders and track their status in real-time. The application was built using a modern stack of technologies to ensure fast loading times and smooth checkout processes."
          projectPara13="At Version Next, we take pride in delivering high-quality solutions that are tailored to our client's business needs. Our collaboration with Utssav Jwelles is an example of how we leverage our expertise in web and mobile app development to help businesses achieve their goals."
          projectTitle20="2. Building an ERP Tool for Heritage"
          projectPara21="Heritage, a leading company in the industry, sought to enhance their customer experience by developing an ERP tool. This tool aimed to seamlessly connect customers with the ability to order subscription-based products through their app. Additionally, it would establish a connection to the nearest Heritage branch responsible for fulfilling the orders and facilitating timely deliveries. Version Next, an experienced IT services provider, was enlisted to build this innovative solution."
          projectPara22="Version Next's development team meticulously crafted the ERP tool, leveraging industry-standard technologies to ensure a robust and reliable solution. The user interface was designed with a customer-centric approach, focusing on simplicity and ease of use while offering a comprehensive ordering experience. The ERP tool transformed Heritage's customer experience by providing a convenient, user-friendly platform for ordering subscription-based products."
          projectPara23="The solution provided Heritage with a competitive edge by enhancing customer satisfaction, optimizing internal operations, and supporting future growth."
          totlaCount="2"
        />
      </section>
      <section className="IndusExpertSection">
        <IndusExpert
          cName="Version Next Technologies"
          cSecTitle="Why Hire VNT as Healthcare Software Development Partner?"
          profileName="Mr. Vinayak Parab"
          designation="Buisness Head"
          cardTitle1="Innovation"
          cardData1="We brings innovative solutions that leverage cutting-edge technologies to drive business growth and stay ahead of the competition."
          cardTitle2="Integrity"
          cardData2="We uphold the highest standards of integrity, ensuring transparency, honesty, and ethical practices in all our interactions with clients."
          cardTitle3="Customer-Obsession"
          cardData3="Our unwavering focus on delivering exceptional customer experiences sets us apart, as we go above and beyond to meet and exceed client expectations."
          cardTitle4="Quality"
          cardData4="We are committed to delivering top-notch quality in all our services, adhering to industry best practices and rigorous quality control processes to ensure excellence."
          profileImg={vinayakprofileImg}
          linkdinLink="http://linkedin.com/in/vinayak-parab-2a7a8615"
          linkdinIcon="fab fa-linkedin"
        />
      </section>
      <section className="whatMakesSection" id="whatMakesSection">
        <DevDiff
          float1={float1}
          float2={float2}
          Char1="What Makes"
          Char2="us"
          Char3="Different?"
          diffDescription="What sets us apart is our expertise, customization, collaboration, creativity, results-driven approach, and customer-centric focus. We have a highly skilled team, personalized solutions, open communication, innovative ideas, a focus on measurable results, and a dedication to customer satisfaction. These factors combine to deliver outstanding outcomes and exceptional experiences for our clients."
        />
      </section>
      <section className="commonFaq">
        <div className="container">
          <div className="sectionTitle">
            <h2>
              Frequently Asked <span className="textGrade">Questions</span>
            </h2>

            <p>
              Singling out common points and possible difficulties which can be
              encountered in EHR, EMR, and Patient Portal software development
            </p>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6 col-12">
              <Accodion />
            </div>
            <div className="col-md-12 col-lg-6 col-12">
              <FaqVideo />
            </div>
          </div>
          <div className="readMoreBtnWrap">
            <TransparentRoundedBtn
              rText="Read More"
              rLink="/faqs"
              rName="rAnimeBtn readMoretBtn"
            />
          </div>
        </div>
      </section>
    </section>
    </>
  );
}

export default HealthCare;
