import React from "react";

function IndusBanner() {
  return (
    <div className="container indusBannerContainer">
      <div className="row">
        <div className="col-lg-12 col-xl-6 col-md-12 col-12">
          <div className="indusLeftBox">
            <div className="indusBannerTextWrap">
              <h1>
                <span className="tindusTopText">Next-Gen Digital</span>
                <span className="indusMidText">Transforming</span>
                <span className="indusBtmText">Solutions</span>
              </h1>
            </div>
            <div className="indusBannerParaWrap">
              <p>
                A decade of experience leveraging next-gen technologies to
                develop industry-leading scalable digital solutions for
                transformational customer experiences and complex systems in
                line with the latest industry trends.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IndusBanner;
