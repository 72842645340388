import React from "react";
import { devOps1, devOps2, devOps3, devOps4 } from "..";

function ContinuousSection() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Continuous Planning</h3>
            <p>
              We use Continuous Planning to help our clients achieve seamless
              DevOps automation. Our expert DevOps consultants check existing
              processes and systems to create a complete roadmap with
              expectations and milestones. We also use industry-leading software
              tools and frameworks like Docker, Puppet, and Selenium to help
              achieve goals.
            </p>
            <ul className="continueList">
              <li>Setting goals and milestones</li>
              <li>Comprehensive evaluation process</li>
              <li>Strategizing roadmap</li>
              <li>Illustration of roadmap</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img src={devOps1} alt="Continuous Planning" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Continuous Integration</h3>
            <p>
              Continuous integration is a process that ensures developers always
              have access to the most up-to-date and validated code. This helps
              prevent costly delays in development by allowing many developers
              to work on the same source code at the same time with confidence.
              This is much better than waiting to integrate separate sections of
              code on release day, which can cause delays and problems.
            </p>
            <ul className="continueList">
              <li>Code validation</li>
              <li>Technical architecture</li>
              <li>Close collaboration</li>
              <li>Delay prevention</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img src={devOps2} alt="Continuous Integration" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Continuous Delivery</h3>
            <p>
              Continuous Delivery (CD) is a software development practice where
              code changes from various team members are automatically prepared
              for release to the production environment. We use AWS CodePipeline
              to practice continuous delivery. This allows us to build a
              workflow that builds code in AWS CodeBuild, runs automated tests,
              and deploys code. This process ensures that our code is always
              ready for release to the production environment.
            </p>
            <ul className="continueList">
              <li>Identifying risk factors</li>
              <li>Automation testing</li>
              <li>Change management</li>
              <li>Release management</li>
              <li>Performance optimization</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img src={devOps3} alt="Continuous Delivery" />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-6 col-12">
          <div className="leftWrap">
            <h3>Continuous Monitoring</h3>
            <p>
              DevOps monitoring is important for identifying performance
              deviations, availability issues, and functional correctness
              problems before they affect end users. We keep an eye on both the
              pre-production and production environment using Application
              Monitoring Tools (APM). This covers Web, API, and Infrastructure
              monitoring.
            </p>
            <ul className="continueList">
              <li>Continuous feedback</li>
              <li>Aligning client expectations</li>
              <li>Overall continuous monitoring</li>
              <li>Achieving business goals</li>
              <li>Functionality monitoring</li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-12">
          <div className="rightWrap">
            <img src={devOps4} alt="Continuous Monitoring" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContinuousSection;
