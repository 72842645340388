export const ExpertiseData = [
    {
        id: 0,
        text: "Tailored DevOps Solutions"
    },
    {
        id: 1,
        text: "End-to-End Automation"
    },
    {
        id: 2,
        text: "Strategic Configuration and Integration"
    },
    {
        id: 3,
        text: "Scalable Enterprise Solutions"
    },
    {
        id: 4,
        text: "Harnessing PaaS for Business Growth"
    },
    {
        id: 5,
        text: "We ensure that each solution is meticulously crafted to maximize efficiency and productivity."
    },
    {
        id: 6,
        text: "With a deep understanding of automation principles."
    },
    {
        id: 7,
        text: "Ensuring smooth communication and collaboration across your entire technology stack."
    },
    {
        id: 8,
        text: "Recognizing the needs of large-scale enterprises."
    },
    {
        id: 9,
        text: "We create interactive user interfaces that enhance user experience and engagement."
    },
]