import React, { useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState } from "react";
import {
  circleSvg,
  designIcon,
  devopsIcon,
  iosIcon,
  iosMock,
  iotIcon,
  marketingIcon,
  semiCircle1,
  semiCircle2,
  webAppIcon,
} from "../..";
function SyncSlider() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState(null);
  const [slidesToShow, setSlidesToShow] = useState(5);

  useEffect(() => {
    // Update the number of slides to show based on the screen size
    const updateSlidesToShow = () => {
      if (window.innerWidth < 768) {
        setSlidesToShow(2);
      } else if (window.innerWidth < 992) {
        setSlidesToShow(3);
      } else if (window.innerWidth < 1200) {
        setSlidesToShow(4);
      } else {
        setSlidesToShow(5);
      }
    };

    // Call the updateSlidesToShow function initially and on window resize
    updateSlidesToShow();
    window.addEventListener("resize", updateSlidesToShow);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateSlidesToShow);
    };
  }, []);

  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 3500,
    arrows: false,
    loop: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="syncSlidesBox">
      <div className="syncBtnsWrap">
        <Slider
          asNavFor={nav1}
          ref={(slider2) => setNav2(slider2)}
          slidesToShow={slidesToShow}
          swipeToSlide={true}
          focusOnSelect={true}
          arrows={false}
          loop={true}
        >
          <div className="item">
            <span>
              <img src={iosIcon} alt=" Native Ios" />
            </span>
            Native iOS Apps
          </div>
          <div className="item">
            <span>
              <img src={webAppIcon} alt="Native Android Apps" />
            </span>{" "}
            Native Android Apps
          </div>

          <div className="item">
            <span>
              <img src={designIcon} alt="Design and Graphics" />
            </span>{" "}
           Cross Platform
          </div>
          <div className="item">
            <span>
              <img src={devopsIcon} alt="DevOps" />
            </span>
            Hybrid Apps
          </div>
          <div className="item">
            <span>
              <img src={iotIcon} alt="IoT" />
            </span>
            PWA
          </div>
          <div className="item">
            <span>
              <img src={marketingIcon} alt="Digital Marketing" />
            </span>
            Mobile UI/UX
          </div>
          
        </Slider>
      </div>
      <div className="syncSlidesContentBox">
        <Slider
          {...settings}
          asNavFor={nav2}
          ref={(slider1) => setNav1(slider1)}
          loop={true}
        >
          <div className="itemContent">
            <div className="row flex-column flex-lg-row">
              <div className="col-md-12 col-lg-5 px-0 d-md-block d-none">
                <div className="itemImgWrap">
                  <img src={iosMock} alt="" />
                  <span className="span1">
                    <img src={circleSvg} alt="circle SVG" />
                  </span>
                  <span className="span2">
                    <img src={semiCircle1} alt="Semi Circle" />
                  </span>
                  <span className="span3">
                    <img src={semiCircle2} alt="Semi Circle" />
                  </span>
                </div>
              </div>
              <div className="col-md-12 col-lg-7 px-0">
                <div className="itemContentWrap">
                  <p>
                    Version Next Technologies is a leading iOS/iPhone app development company
                    with a team of skilled iOS developers. We create custom apps
                    that work great on all iOS devices, including iPhones,
                    iPads, Apple Watches, and Apple TVs.
                  </p>
                  <ul>
                    <li>iPhone App Development</li>
                    <li>Apple Watch Development</li>
                    <li>iOS App with SiriKit</li>
                    <li>iPad App Development</li>
                  </ul>
                  <p>
                    Our developers have years of expertise in the latest iOS app
                    development technologies. We ensure that the mobile apps we
                    build have a delightful user experience. If you need an iOS
                    app, Hire iOS app developers from VNT.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="itemContent">
            <div className="row flex-column flex-lg-row">
              <div className="col-md-12 col-lg-5 px-0 d-md-block d-none">
                <div className="itemImgWrap">
                  <img src={iosMock} alt="" />
                  <span className="span1">
                    <img src={circleSvg} alt="circle SVG" />
                  </span>
                  <span className="span2">
                    <img src={semiCircle1} alt="Semi Circle" />
                  </span>
                  <span className="span3">
                    <img src={semiCircle2} alt="Semi Circle" />
                  </span>
                </div>
              </div>
              <div className="col-md-12 col-lg-7 px-0">
                <div className="itemContentWrap">
                  <p>
                  Version Next Technologies is a leading iOS/iPhone app development company
                    with a team of skilled iOS developers. We create custom apps
                    that work great on all iOS devices, including iPhones,
                    iPads, Apple Watches, and Apple TVs.
                  </p>
                  <ul>
                    <li>iPhone App Development</li>
                    <li>Apple Watch Development</li>
                    <li>iOS App with SiriKit</li>
                    <li>iPad App Development</li>
                  </ul>
                  <p>
                    Our developers have years of expertise in the latest iOS app
                    development technologies. We ensure that the mobile apps we
                    build have a delightful user experience. If you need an iOS
                    app, Hire iOS app developers from VNT.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="itemContent">
            <div className="row flex-column flex-lg-row">
              <div className="col-md-12 col-lg-5 px-0 d-md-block d-none">
                <div className="itemImgWrap">
                  <img src={iosMock} alt="" />
                  <span className="span1">
                    <img src={circleSvg} alt="circle SVG" />
                  </span>
                  <span className="span2">
                    <img src={semiCircle1} alt="Semi Circle" />
                  </span>
                  <span className="span3">
                    <img src={semiCircle2} alt="Semi Circle" />
                  </span>
                </div>
              </div>
              <div className="col-md-12 col-lg-7 px-0">
                <div className="itemContentWrap">
                  <p>
                  Version Next Technologies VNT is a leading iOS/iPhone app development company
                    with a team of skilled iOS developers. We create custom apps
                    that work great on all iOS devices, including iPhones,
                    iPads, Apple Watches, and Apple TVs.
                  </p>
                  <ul>
                    <li>iPhone App Development</li>
                    <li>Apple Watch Development</li>
                    <li>iOS App with SiriKit</li>
                    <li>iPad App Development</li>
                  </ul>
                  <p>
                    Our developers have years of expertise in the latest iOS app
                    development technologies. We ensure that the mobile apps we
                    build have a delightful user experience. If you need an iOS
                    app, Hire iOS app developers from VNT.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="itemContent">
            <div className="row flex-column flex-lg-row">
              <div className="col-md-12 col-lg-5 px-0 d-md-block d-none">
                <div className="itemImgWrap">
                  <img src={iosMock} alt="" />
                  <span className="span1">
                    <img src={circleSvg} alt="circle SVG" />
                  </span>
                  <span className="span2">
                    <img src={semiCircle1} alt="Semi Circle" />
                  </span>
                  <span className="span3">
                    <img src={semiCircle2} alt="Semi Circle" />
                  </span>
                </div>
              </div>
              <div className="col-md-12 col-lg-7 px-0">
                <div className="itemContentWrap">
                  <p>
                  Version Next Technologies is a leading iOS/iPhone app development company
                    with a team of skilled iOS developers. We create custom apps
                    that work great on all iOS devices, including iPhones,
                    iPads, Apple Watches, and Apple TVs.
                  </p>
                  <ul>
                    <li>iPhone App Development</li>
                    <li>Apple Watch Development</li>
                    <li>iOS App with SiriKit</li>
                    <li>iPad App Development</li>
                  </ul>
                  <p>
                    Our developers have years of expertise in the latest iOS app
                    development technologies. We ensure that the mobile apps we
                    build have a delightful user experience. If you need an iOS
                    app, Hire iOS app developers from VNT.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="itemContent">
            <div className="row flex-column flex-lg-row">
              <div className="col-md-12 col-lg-5 px-0 d-md-block d-none">
                <div className="itemImgWrap">
                  <img src={iosMock} alt="" />
                  <span className="span1">
                    <img src={circleSvg} alt="circle SVG" />
                  </span>
                  <span className="span2">
                    <img src={semiCircle1} alt="Semi Circle" />
                  </span>
                  <span className="span3">
                    <img src={semiCircle2} alt="Semi Circle" />
                  </span>
                </div>
              </div>
              <div className="col-md-12 col-lg-7 px-0">
                <div className="itemContentWrap">
                  <p>
                  Version Next Technologies is a leading iOS/iPhone app development company
                    with a team of skilled iOS developers. We create custom apps
                    that work great on all iOS devices, including iPhones,
                    iPads, Apple Watches, and Apple TVs.
                  </p>
                  <ul>
                    <li>iPhone App Development</li>
                    <li>Apple Watch Development</li>
                    <li>iOS App with SiriKit</li>
                    <li>iPad App Development</li>
                  </ul>
                  <p>
                    Our developers have years of expertise in the latest iOS app
                    development technologies. We ensure that the mobile apps we
                    build have a delightful user experience. If you need an iOS
                    app, Hire iOS app developers from VNT.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="itemContent">
            <div className="row flex-column flex-lg-row">
              <div className="col-md-12 col-lg-5 px-0 d-md-block d-none">
                <div className="itemImgWrap">
                  <img src={iosMock} alt="" />
                  <span className="span1">
                    <img src={circleSvg} alt="circle SVG" />
                  </span>
                  <span className="span2">
                    <img src={semiCircle1} alt="Semi Circle" />
                  </span>
                  <span className="span3">
                    <img src={semiCircle2} alt="Semi Circle" />
                  </span>
                </div>
              </div>
              <div className="col-md-12 col-lg-7 px-0">
                <div className="itemContentWrap">
                  <p>
                  Version Next Technologies is a leading iOS/iPhone app development company
                    with a team of skilled iOS developers. We create custom apps
                    that work great on all iOS devices, including iPhones,
                    iPads, Apple Watches, and Apple TVs.
                  </p>
                  <ul>
                    <li>iPhone App Development</li>
                    <li>Apple Watch Development</li>
                    <li>iOS App with SiriKit</li>
                    <li>iPad App Development</li>
                  </ul>
                  <p>
                    Our developers have years of expertise in the latest iOS app
                    development technologies. We ensure that the mobile apps we
                    build have a delightful user experience. If you need an iOS
                    app, Hire iOS app developers from VNT.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default SyncSlider;
