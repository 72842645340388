export const ExpertiseData = [
    {
        id: 0,
        text: "Personalized ASP.NET Development"
    },
    {
        id: 1,
        text: "Migration from Classic ASP to ASP.NET"
    },
    {
        id: 2,
        text: "SQL Server Reporting Services (SSRS)"
    },
    {
        id: 3,
        text: "Services offered by Azure Cloud"
    },
    {
        id: 4,
        text: "Services for Optimizing Databases"
    },
    {
        id: 5,
        text: "Incorporating External Systems and Tailoring Solutions"
    },
    {
        id: 6,
        text: "AI and Large-Scale Data Analysis"
    },
    {
        id: 7,
        text: "Implementation of Machine Learning"
    },
    {
        id: 8,
        text: "Maintenance and support for ASP.Net"
    },
    {
        id: 9,
        text: "Service for optimizing MS-SQL databases"
    },
    {
        id: 10,
        text: "Integrations with third-party platforms such as SAP, Salesforce, Quickbooks, Sage, and more"
    },
]