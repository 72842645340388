import React from "react";
import "../src/assets/Sass/main.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import NavBar from "./components/Homepage/Navbar/NavBar";
import Footer from "./components/Footer/Footer";
import BackToTop from "./components/BackToTop/BackToTop";
import IndustriesPage from "./pages/IndustriesPage";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ServicesPage from "./pages/ServicesPage";
import About from "./pages/About";
import ContactPage from "./pages/ContactPage";
import BlogPage from "./pages/BlogPage";
import MobileAppDev from "./pages/MobileAppDev";
import WebAppDev from "./pages/WebAppDev";
import DesignGraphicsDev from "./pages/DesignGraphicsDev";
import IoTDev from "./pages/IoTDev";
import DegitalMarketing from "./pages/DegitalMarketing";
import DevopsDev from "./pages/DevopsDev";
import AiMlDev from "./pages/AiMlDev";
import QaDev from "./pages/QaDev";
import SoftDev from "./pages/SoftDev";
import AwsDev from "./pages/AwsDev";
import FirebaseDev from "./pages/FirebaseDev";
import NodejsDev from "./pages/NodejsDev";
import VuejsDev from "./pages/VuejsDev";
import WordpressDev from "./pages/WordpressDev";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Testimonial from "./pages/Testimonial";
import Error404 from "./pages/Error404";
import FaqAll from "./pages/FaqAll";
import ReactNativeDev from "./pages/ReactNativeDev";
import HealthCare from "./pages/HealthCare";
import FinTech from "./pages/FinTech";
import Career from "./pages/Career";
import ItConsultancy from "./pages/ItConsultancy";
import DigitalTransformation from "./pages/DigitalTransformation";
import Analytics from "./pages/Analytics";
import Education from "./pages/Education";
import Logistics from "./pages/Logistics";
import Sports from "./pages/Sports";
import DigitalRetail from "./pages/DigitalRetail";
import Insurance from "./pages/Insurance";
import ReactjsDeveloperHire from "./pages/HireDevelopers/ReactjsDeveloperHire";
import Travel from "./pages/Travel";
import SocialNetwork from "./pages/SocialNetwork";
import AspDotNetDeveloperHire from "./pages/HireDevelopers/AspDotNetDeveloperHire";
import JavaDeveloperHire from "./pages/HireDevelopers/JavaDeveloperHire";
import DevOpsDeveloperHire from "./pages/HireDevelopers/DevOpsDeveloperHire";
import PythonDeveloperHire from "./pages/HireDevelopers/PythonDeveloperHire";
import Marketplaces from "./pages/Marketplaces";
import InformationSecurityAuditService from "./pages/SecurityServices/InformationSecurityAuditService";
import VulnerabilityAssessmentService from "./pages/SecurityServices/VulnerabilityAssessmentService";
import WebApplicationPenetrationTesting from "./pages/SecurityServices/WebApplicationPenetrationTesting";
import PenetrationTestingService from "./pages/SecurityServices/PenetrationTestingService";
import SpearPhishing from "./pages/SecurityServices/SpearPhishing";
import WirelessAuditing from "./pages/SecurityServices/WirelessAuditing";
import MobileApplicationSecurityTesting from "./pages/SecurityServices/MobileApplicationSecurityTesting";
import WebApplicationSecurity from "./pages/SecurityServices/WebApplicationSecurity";
import WebsiteSecurityManagement from "./pages/AnnualSecurityManagement/WebsiteSecurityManagement";
import EthicalHackingForBeginners from "./pages/TrainingSkilAndlDevelopment/EthicalHackingForBeginners";
import PenetrationTestingTraining from "./pages/TrainingSkilAndlDevelopment/PenetrationTestingTraining";
import AndriodAppDevelopmentTraining from "./pages/TrainingSkilAndlDevelopment/AndriodAppDevelopmentTraining";
import SecuredASPDotNET from "./pages/TrainingSkilAndlDevelopment/SecuredASPDotNET";
import BlockChain from "./pages/BlockChain";
import Portfolio from "./pages/Portfolio";
import PaymentOption from "./pages/PaymentOption";
import Logos from "./pages/Logos";
function App() {
  return (
    <BrowserRouter forceRefresh={true}>
      <ScrollToTop />
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/industries" element={<IndustriesPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/mobile-app-development" element={<MobileAppDev />} />
        <Route path="/web-app-development" element={<WebAppDev />} />
        <Route path="/design-and-graphics" element={<DesignGraphicsDev />} />
        <Route path="/internet-of-things" element={<IoTDev />} />
        <Route path="/devops" element={<DevopsDev />} />
        <Route path="/digital-marketing" element={<DegitalMarketing />} />
        <Route path="/ai-and-ml" element={<AiMlDev />} />
        <Route path="/quality-assurance" element={<QaDev />} />
        <Route path="/software-development" element={<SoftDev />} />
        <Route path="/aws-development" element={<AwsDev />} />
        <Route path="/firebase-development" element={<FirebaseDev />} />
        <Route path="/nodejs-development" element={<NodejsDev />} />
        <Route path="/vuejs-development" element={<VuejsDev />} />
        <Route path="/wordpress-development" element={<WordpressDev />} />
        <Route path="/react-native-development" element={<ReactNativeDev />} />
        <Route path="/about-version-next" element={<About />} />
        <Route path="/it-consultancy-solutions" element={<ItConsultancy />} />
        <Route path="/digital-transformation-experts" element={<DigitalTransformation />} />
        <Route path="/bi-and-analytics" element={<Analytics />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/healthcare" element={<HealthCare />} />
        <Route path="/fin-tech" element={<FinTech />} />
        <Route path="/education-software-development-company" element={<Education />} />
        <Route path="/logistics-software-solutions" element={<Logistics />} />
        <Route path="/digital-retail" element={<DigitalRetail/>} />
        <Route path="/insurance" element={<Insurance/>} />
        <Route path="/sports-gaming-development" element={<Sports />} />
        <Route path="/travel" element={<Travel />} />
        <Route path="/social-network" element={<SocialNetwork />} />
        <Route path="/digital-retail" element={<DigitalRetail />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/career" element={<Career />} />
        <Route path="/faqs" element={<FaqAll />} />
        <Route path="/hire-react-js-developers" element={<ReactjsDeveloperHire />} />
        <Route path="/hire-asp-dotnet-developers" element={<AspDotNetDeveloperHire />} />
        <Route path="/hire-java-developers" element={<JavaDeveloperHire />} />
        <Route path="/hire-devops-developers" element={<DevOpsDeveloperHire />} />
        <Route path="/hire-pyhton-developers" element={<PythonDeveloperHire />} />
        <Route path="/marketplace" element={<Marketplaces />} />
        <Route path="/error404" element={<Error404 />} />
        <Route path="/information-security-audit-service" element={<InformationSecurityAuditService />} />
        <Route path="/vulnerability-assessment-service" element={<VulnerabilityAssessmentService />} />
        <Route path="/web-application-penetration-testing" element={<WebApplicationPenetrationTesting />} />
        <Route path="/penetration-testing-service" element={<PenetrationTestingService />} />
        <Route path="/spear-phishing" element={<SpearPhishing />} />
        <Route path="/wireless-auditing" element={<WirelessAuditing />} />
        <Route path="/mobile-application-security-testing" element={<MobileApplicationSecurityTesting />} />
        <Route path="/web-application-security" element={<WebApplicationSecurity />} />
        <Route path="/website-security-management" element={<WebsiteSecurityManagement />} />
        <Route path="/ethical-hacking-for-beginners" element={<EthicalHackingForBeginners />} />
        <Route path="/penetration-testing-training" element={<PenetrationTestingTraining />} />
        <Route path="/andriod-app-development-training" element={<AndriodAppDevelopmentTraining />} />
        <Route path="/aspdotnet-development-training" element={<SecuredASPDotNET />} />
        <Route path="/blockchain" element={<BlockChain />} />
        <Route path="/company-portfolio" element={<Portfolio />} />
        <Route path="/logo" element={<Logos />} />
        <Route path="/payment-details" element={<PaymentOption />} />
        <Route path="*" element={<Navigate to="/Error404" />} />
      </Routes>
      <Footer />
      <BackToTop />
    </BrowserRouter>
  );
}

export default App;
