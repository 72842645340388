import React from "react";
import ServicesBanner from "../components/ServicesPageComponents/ServicesBanner";
import BlogSection from "../components/BlogPosts/BlogSection";
import { Accodion } from "../components/Accordion/AccordionItem";
import FaqVideo from "../components/FaqVideo/FaqVideo";
import TransparentRoundedBtn from "../components/CTA/TransparentRoundedBtn";
import Clients from "../components/Homepage/Clients/Clients";
import ServicesBlocks from "../components/ServicesPageComponents/ServicesBlocks";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import VntWork from "../components/ServicesPageComponents/VntWork";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import WorkSliderItem from "../components/ServicesPageComponents/WorkSliderItem";
import { slidee101, slidee102, slidee103, slidee104, slidee105, slidee106, summetprofileImg } from "../components";
function ServicesPage(props) {
  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    autoplay: true,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="servicesPage" id="servicesPage">
        <section className="ServiceTopSection">
          <ServicesBanner />
          <div className="shapeSvg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  className="fil0"
                  d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
                />
              </g>
            </svg>
          </div>
        </section>
        <section className="servicesBlocks">
          <ServicesBlocks />
        </section>
        <section className="IndusExpertSection">
          <IndusExpert
            cName="Version Next Technologies"
            cSecTitle="Why Hire Version Next Technologies as IT Services"
            profileName="Mr. Sumeet Rathod"
            designation="Buisness Head"
            cardTitle1="Innovation"
            cardData1="We brings innovative solutions that leverage cutting-edge technologies to drive business growth and stay ahead of the competition."
            cardTitle2="Integrity"
            cardData2="We uphold the highest standards of integrity, ensuring transparency, honesty, and ethical practices in all our interactions with clients.            "
            cardTitle3="Customer-Obsession"
            cardData3="Our unwavering focus on delivering exceptional customer experiences sets us apart, as we go above and beyond to meet and exceed client expectations.            "
            cardTitle4="Quality"
            cardData4="We are committed to delivering top-notch quality in all our services, adhering to industry best practices and rigorous quality control processes to ensure excellence."
            profileImg={summetprofileImg}
            facebookLink="https://www.linkedin.com/in/sumeetrathod"
            facebookIcon="fab fa-linkedin"
            twiiterLink="https://twitter.com/srtrend"
            twitterIcon="fab fa-twitter-square"
          />
        </section>
        <section id="clients" className="clients">
          <Clients />
        </section>
        <section className="wntWorkSection">
          <div className="container">
            <VntWork
              subText="Industry We"
              mainText="Work"
              paratext="Partnering with a Range of Sectors to Deliver Tailored IT Solutions for Enhanced Efficiency, Productivity, and Growth, while Staying Ahead in a Rapidly Evolving Technological Landscape."
            />
            <div className="row justify-content-center">
              <div className="col-lg-10 col-md-12 col-12">
                <div className="workSliderWrapper">
                  <Slider {...settings} className="sliderWork slider-with-gap">
                    <div className="slide-item">
                      <WorkSliderItem 
                        title="Health and Life Sciences"
                        number="1"
                        sliderDesc="EHR & EMR, Patient Portals software development services for institutions maintaining physical and mental well-being: medical, telemedicine."
                        slideImg={slidee101}
                      />
                    </div>

                    <div className="slide-item">
                      <WorkSliderItem
                        title="Education"
                        number="2"
                        sliderDesc="Education app development services provided by Version Next empower learning experience for students, teachers, and administrations.                        "
                        slideImg={slidee102}
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="FinTech"
                        number="3"
                        sliderDesc="Financial software development services that help automate processes, improve transaction security, and put the latest technologies for your business.                        "
                        slideImg={slidee103}
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Insurance"
                        number="4"
                        sliderDesc="Insurance software development services that automate processes, improve data security, and help your business benefit from the latest technologies.                        "
                        slideImg={slidee104}
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Supply Chain & Logistics"
                        number="5"
                        sliderDesc="We ensure consistent development, scaling, and high performance products for supply chain & logistics management to meet your corporate goals.                        "
                        slideImg={slidee105}
                      />
                    </div>
                    <div className="slide-item">
                      <WorkSliderItem
                        title="Digital Retail"
                        number="6"
                        sliderDesc="Solutions counting customer’s business processes, Increasing the performance of customer’s retail software by automating manual processes."
                        slideImg={slidee106}
                      />
                    </div>
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="commonFaq">
          <div className="container">
            <div className="sectionTitle">
              <h2>
                Frequently Asked <span className="textGrade">Questions</span>
              </h2>

              <p>
                Singling out common points and possible difficulties which can
                be encountered in EHR, EMR, and Patient Portal software
                development
              </p>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6 col-12">
                <Accodion />
              </div>
              <div className="col-md-12 col-lg-6 col-12">
                <FaqVideo />
              </div>
            </div>
            <div className="readMoreBtnWrap">
              <TransparentRoundedBtn
                rText="Read More"
                rLink="/faqs"
                rName="rAnimeBtn readMoretBtn"
              />
            </div>
          </div>
        </section>
        <BlogSection />
      </div>
    </>
  );
}

export default ServicesPage;
