import React from "react";
import PageBanner from "../components/TermsPageComponents/PageBanner";

function PaymentOption() {
  return (
    <>
      <section id="allFaqPage" className="allFaqPage">
        <div className="pagesBanner">
          <PageBanner PageTitle="Payment Details" />

          <div className="shapeSvg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
              <g id="Layer_x0020_1">
                <metadata id="CorelCorpID_0Corel-Layer" />
                <path
                  className="fil0"
                  d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
                />
              </g>
            </svg>
          </div>
        </div>
      </section>
      <section id="paymentDetailsWrap">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-lg-10 col-xl-8 col-12 mx-auto">
              <div className="payAccordion">
                <div className="payOptionFaqWrap">
                  <h2>Fedral Bank Account Details:</h2>
                  <table className="account bankdetail table-responsive">
                    <tbody>
                      <tr>
                        <td>
                          <span>Account Name </span>
                        </td>
                        <td>
                          : &nbsp;&nbsp; Version Next Technologies Pvt. Ltd.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>Account No </span>
                        </td>
                        <td>: &nbsp;&nbsp; 16190200000801</td>
                      </tr>
                      <tr>
                        <td>
                          <span>Bank Name </span>
                        </td>
                        <td>: &nbsp;&nbsp; Fedral Bank</td>
                      </tr>
                      <tr>
                        <td>
                          <span>Bank Address </span>
                        </td>
                        <td>
                          : &nbsp;&nbsp; Gala no 8, Sagar Pallazio, Andheri
                          Kurla Link Road, Sakinaka Junction, Andheri (E),
                          Mumbai- 400072
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span>IFSC Code </span>
                        </td>
                        <td>: &nbsp;&nbsp; FDRL0001619</td>
                      </tr>
                      <tr>
                        <td>
                          <span>Swift Code </span>
                        </td>
                        <td>: &nbsp;&nbsp; FDRLINBBIBD</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="payOptionFaqWrap">
                  <h2>Payapl Account Details:</h2>
                  <table className="account bankdetail table-responsive">
                    <tbody>
                      <tr>
                        <td>
                          <span>Swift Code </span>
                        </td>
                        <td>: &nbsp;&nbsp; FDRLINBBIBD</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="payOptionFaqWrap">
                  <h2>Other Details:</h2>
                  <table className="account bankdetail table-responsive">
                    <tbody>
                      <tr>
                        <td>
                          <span>CIN Number</span>
                        </td>
                        <td>: &nbsp;&nbsp; U72200MH2008PTC182529</td>
                      </tr>
                      <tr>
                        <td>
                          <span>Our PAN Number</span>
                        </td>
                        <td>: &nbsp;&nbsp; AACCV7580M</td>
                      </tr>
                      <tr>
                        <td>
                          <span>GST Number</span>
                        </td>
                        <td>: &nbsp;&nbsp; 27AACCV7580M1ZN</td>
                      </tr>
                      <tr>
                        <td>
                          <span>Tan Number</span>
                        </td>
                        <td>: &nbsp;&nbsp; MUMV15923F</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="qrCodeWrap">
                  <h4>UPI Payment</h4>
                  <img
                    src="/static/media/qr-code.726ea939.png"
                    alt="Payment QR Code"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PaymentOption;
