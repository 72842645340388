import React from "react";
import ExpertButton from "../../../components/CTA/ExpertButton";

function BannerLeftBox(props) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-7 col-lg-12 col-md-12 col-12">
          <div className="leftBoxWrap">
            <div className="leftBox">
              <h1>
                <span className="topLine">{props.line1}</span><br/>
                <span className="bottomLine">
                  <span className="gradeText">{props.line2}</span> {props.line3}
                </span>
              </h1>
            </div>
            <div className="hireListWrap">
                <h5>Engage a Developer or a Team Based on Your Project Requirements</h5>
                <ul className="hireList">
                  <li>100% Dedicated In-House Team, No Freelancers</li>
                  <li>Experienced Developers with an Average of 5+ Years of Expertise</li>
                  <li>Eliminate Hiring Fees, Effortless Onboarding, No Interview Worries</li>
                  <li>Engage with Flexible Hiring Models: Fixed Cost, Hourly, or Monthly Basis</li>
                </ul>
            </div>
            <ExpertButton rName="rAnimeBtn expertBtn" rText="Hire Developers"/>
          </div>
        </div> 
      </div>
    </div>
  );
}

export default BannerLeftBox;
