export const YourNeed = [
    {
     id : 0,
     title: "E-commerce Website",
     description: "Develop a feature-rich online store using ASP.Net with functionalities like product catalog, shopping cart, secure payment gateways, order processing, and user account management.",
     img: "https://dev.version-next.com/static/media/needImg1.png"
    },
    {
        id : 1,
        title: "Content Management System (CMS)",
        description: "Build a customizable CMS platform using ASP.Net where users can easily create, edit, and manage digital content, including articles, images, videos, and more.",
        img: "https://dev.version-next.com/static/media/needImg2.png"
    },
    {
        id : 2,
        title: "Enterprise Web Application",
        description: "Create a comprehensive business application that facilitates processes such as CRM, inventory management, employee collaboration, reporting, and analytics.",
        img: "https://dev.version-next.com/static/media/needImg3.png"
    },
    {
        id : 3,
        title: "Online Learning Platform",
        description: "Develop an e-learning platform with ASP.Net that offers features for course creation, video lectures, quizzes, progress tracking, and a user-friendly interface.",
        img: "https://dev.version-next.com/static/media/needImg4.png"
    },
    {
        id : 4,
        title: "Social Networking Platform",
        description: "Create a social media platform using ASP.Net that includes user profiles, friend connections, status updates, media sharing, comments, and messaging.",
        img: "https://dev.version-next.com/static/media/needImg5.png"
    },
    {
        id : 5,
        title: "Online Food Delivery Service",
        description: "Develop a platform for restaurants to list their menus, customers to place orders, and delivery personnel to manage deliveries, all integrated with payment gateways.",
        img: "https://dev.version-next.com/static/media/needImg1.png"
    }
    ]