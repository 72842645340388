import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  hosting1,
  hosting2,
  hosting3,
  hosting4,
  hosting5,
  hosting6,
  hosting7,
  hosting8,
  svglogo,
} from "../../index";
import ExpertButton from "../../CTA/ExpertButton";

function NavBar() {
  const location = useLocation();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [prevScrollpos, setPrevScrollpos] = useState(0);
  const [scroll, setScroll] = useState(false);
  const scrollRef = useRef(null);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "instant" });
  };
  useEffect(() => {
    setIsNavOpen(false);
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 0);
    });
    const handleScroll = () => {
      const currentScrollpos = window.pageYOffset;

      if (prevScrollpos > currentScrollpos) {
        document.getElementById("header").style.top = "0";
      } else if (prevScrollpos > 500) {
        document.getElementById("header").style.top = "-100px";
      }
      setPrevScrollpos(currentScrollpos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollpos, location]);
  useEffect(() => {
    // Function to toggle the class on the body tag
    const toggleBodyClass = () => {
      const body = document.querySelector("body");
      if (isNavOpen) {
        body.classList.add("bodyOverflowClass");
      } else {
        body.classList.remove("bodyOverflowClass");
      }
    };

    toggleBodyClass(); // Call the function once to apply the class on initial render

    return () => {
      // Cleanup function to remove the class when the component unmounts
      const body = document.querySelector("body");
      body.classList.remove("bodyOverflowClass");
    };
  }, [isNavOpen]);
  return (
    <header id="header" className={scroll ? "header" : "header topClass"}>
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <Link
            className="navbar-brand"
            to="/"
            ref={scrollRef}
            onClick={scrollToTop}
          >
            <img src={svglogo} alt="Logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            id="menuToggle"
            onClick={() => setIsNavOpen(!isNavOpen)}
          >
            <input type="checkbox" checked={isNavOpen} readOnly />
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0" id="desktMenuBar">
              <li className="nav-item dropdown popoFace">
                <Link
                  className="dropdown-toggle nav-link "
                  to="https://madpopo.com/"
                  target="_blank"
                  id="servicesDrop0"
                >
                  Hosting
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </Link>
                <ul
                  className="dropdown-menu deskdropMenue zerothMenu"
                  aria-labelledby="servicesDrop0"
                >
                  <h5>Web hosting that scales from easy to expert.</h5>
                  <p>
                    A ton of website hosting options, 99.9% uptime guarantee,
                    free SSL certificate, easy WordPress installs, and a free
                    domain for a year.
                  </p>
                  <div className="innerMenusWrap">
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link
                              to="https://madpopo.com/wordpress-hosting"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img src={hosting1} alt="WordPress Hosting" />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>WordPress Hosting</h6>
                                  <p>
                                    Streamline your site with WordPress hosting.
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://madpopo.com/reseller-hosting"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img src={hosting5} alt="Reseller Hosting" />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Reseller Hosting</h6>
                                  <p>
                                    Manage multiple sites with reseller hosting.
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link
                              to="https://madpopo.com/woocommerce-hosting"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img
                                    src={hosting2}
                                    alt="WooCommerce Hosting"
                                  />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>WooCommerce Hosting</h6>
                                  <p>
                                    Power up your online store with WooCommerce
                                    hosting.
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://madpopo.com/virtual-server"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img src={hosting6} alt="Virtual Server" />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Virtual Server</h6>
                                  <p>
                                    Streamline IT Infrastructure with Cloud
                                    Servers
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link
                              to="https://madpopo.com/windows-hosting"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img
                                    src={hosting3}
                                    alt="Windows Web Hosting"
                                  />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Windows Web Hosting</h6>
                                  <p>Enhance your site with Windows hosting.</p>
                                  <br />
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://madpopo.com/dedicated-server"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img src={hosting7} alt="Dedicated Server" />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Dedicated Server</h6>
                                  <p>
                                    Maximize performance with dedicated server.
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link
                              to="https://madpopo.com/linux-web-hosting"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img src={hosting4} alt="Linux Web Hosting" />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Linux Web Hosting</h6>
                                  <p>Optimize your site with Linux hosting.</p>
                                  <br />
                                </div>
                              </div>
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="https://madpopo.com/business-email-hosting"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img
                                    src={hosting8}
                                    alt="Business Email Hosting"
                                  />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Business Email Hosting</h6>
                                  <p>
                                    Streamline communication with business email
                                    hosting.
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  id="servicesDrop"
                  to={"services"}
                >
                  Services
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </Link>
                <ul
                  className="dropdown-menu deskdropMenue firstMenu"
                  aria-labelledby="servicesDrop"
                >
                  <div className="innerMenusWrap">
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/mobile-app-development">
                              <div className="menuItem">
                                <span>
                                  <i className="material-icons">phone_iphone</i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Mobile app development</h6>
                                  <p>
                                    Unlock Unprecedented Business Growth with
                                    App Development
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/web-app-development">
                              <div className="menuItem">
                                <span>
                                  <i className="material-icons">
                                    desktop_windows
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Web app development</h6>
                                  <p>Web Application Development Services</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/design-and-graphics">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    design_services
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Design and Graphics</h6>
                                  <p>
                                    Design Thinking and Strategy Integrated
                                    Shaping Our Approach
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/devops">
                              <div className="menuItem">
                                <span>
                                  <i className="material-icons">
                                    all_inclusive
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>DevOps</h6>
                                  <p>
                                    Unlock Unprecedented Business Growth with
                                    our DevOps
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/internet-of-things">
                              <div className="menuItem">
                                <span>
                                  <i className="material-icons">language</i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>IoT</h6>
                                  <p>
                                    Unlock Unprecedented Business Growth with
                                    our IoT
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/digital-marketing">
                              <div className="menuItem">
                                <span>
                                  <i className="material-icons">web</i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Digital Marketing</h6>
                                  <p>
                                    Unlocking Unprecedented Business Growth
                                    Through Digital Services
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/ai-and-ml">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    psychology
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>AI & ML</h6>
                                  <p>
                                    Unlock Unprecedented Business Growth with
                                    our AI and ML
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/quality-assurance">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    task
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>QA</h6>
                                  <p>
                                    Unlock Unprecedented Business Growth with
                                    our QA Team
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="innerMenusWrap2">
                    <div className="col-md-4">
                      <div className="menusBox2">
                        <Link to="/it-consultancy-solutions">
                          <h5>IT Consultancy Solutions</h5>
                          <p>
                            Empowering Businesses with Strategic IT Consultancy
                            Solutions
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="menusBox2">
                        <Link to="/digital-transformation-experts">
                          <h5>Digital Transformation Experts</h5>
                          <p>
                            Driving Digital Transformation for Enhanced Business
                            Success
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="menusBox2">
                        <Link to="/bi-and-analytics">
                          <h5>BI & Analytics</h5>
                          <p>
                            Unlock the Power of Data with Version Next
                            Technologies Business Intelligence & Analytics
                            Solutions
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  id="servicesDrop1"
                  to={"industries"}
                >
                  Industries
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </Link>
                <ul
                  className="dropdown-menu deskdropMenue secondMenu"
                  aria-labelledby="servicesDrop1"
                >
                  <div className="innerMenusWrap">
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/healthcare">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    health_and_safety
                                  </i>
                                </span>

                                <div className="menueTextWrap">
                                  <h6>Health and Life Sciences</h6>
                                  <p>
                                    Solutions for doctor processing patient data
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/education-software-development-company">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    school
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Education</h6>
                                  <p>Digital platform for LMS and SMS</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/fin-tech">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    account_balance
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>FinTech</h6>
                                  <p>
                                    Investment, Trading, Wallets and Banking
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/insurance">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    volunteer_activism
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Insurance</h6>
                                  <p>
                                    Management system, Fruad analysis and CRM
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/logistics-software-solutions">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    inventory
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Supply Chain & Logistics</h6>
                                  <p>
                                    Software for delivery and curiour management
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/digital-retail">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    storefront
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Digital Retail</h6>
                                  <p>RMS, POS and CRM System</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="innerMenusWrap2">
                    <div className="col-md-4">
                      <div className="menusBox2">
                        <Link to="/travel">
                          <h5>Travel</h5>
                        </Link>
                        <Link to="/marketplace">
                          <h5>Marketplaces</h5>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="menusBox2">
                        <Link to="#">
                          <h5>Media Content</h5>
                        </Link>
                        <Link to="/social-network">
                          <h5>Social Networks</h5>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="menusBox2">
                        <Link to="/sports-gaming-development">
                          <h5>Sports</h5>
                        </Link>
                        <Link to="/blockchain">
                          <h5>BlockChain</h5>
                        </Link>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" id="servicesDrop">
                  Security
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </Link>
                <ul
                  className="dropdown-menu deskdropMenue SecureMenu"
                  aria-labelledby="servicesDrop"
                >
                  <h5 className="menuSectionTitle">Services</h5>
                  <hr />
                  <div className="innerMenusWrap">
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                        <li>
                            <Link to="/vulnerability-assessment-service">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Vulnerability Assessment </h6>
                                  <p>Systematic evaluation to uncover and address potential security risks.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/information-security-audit-service">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>IT Security Audit OnDemand </h6>
                                  <p>Ensuring digital defenses are always vigilant.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <br/>
                          <li>
                            <Link to="/web-application-penetration-testing">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Web Application Penetration Testing </h6>
                                  <p>Proactively securing your online assets through thorough security assessment.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/mobile-application-security-testing">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Mobile App Security Testing</h6>
                                  <p>Safeguarding your mobile application against vulnerabilities and threats.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/web-application-security">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Web Application Security</h6>
                                  <p>Safeguarding online systems &nbsp;and data from digital threats and breaches.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/website-security-management">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Website Security Management </h6>
                                  <p>Protecting and maintaining the integrity of your online presence.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/penetration-testing-service">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Penetration Testing Service </h6>
                                  <p>Proactively identifying and strengthening security weaknesses in your digital infrastructure.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/spear-phishing">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Spear Phishing </h6>
                                  <p>Targeted deception tactics aiming at specific individuals or organizations for cyberattacks.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/wireless-auditing">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Wireless Auditing </h6>
                                  <p>Evaluating and enhancing the security of wireless networks.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    
                  </div>
                  <h5 className="menuSectionTitle mt-5">
                    Training & Skill Development
                  </h5>
                  <hr />
                  <div className="innerMenusWrap">
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/ethical-hacking-for-beginners">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Ethical Hacking For Beginners </h6>
                                  <p>A beginner's guide to learning cybersecurity through responsible hacking practices.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/andriod-app-development-training">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Secured Mobile App Development</h6>
                                  <p>Building mobile applications with robust security measures.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                        <li>
                            <Link to="/penetration-testing-training">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Penetration Testing</h6>
                                  <p>Simulating cyberattacks to assess and strengthen system security.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/aspdotnet-development-training">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Secured ASP.NET</h6>
                                  <p>Implementing robust security measures in ASP.NET web applications.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" id="servicesDrop23">
                  Company
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </Link>
                <ul
                  className="dropdown-menu deskdropMenue thirdMenu"
                  aria-labelledby="servicesDrop23"
                >
                  <div className="innerMenusWrap">
                    <div className="col-12 px-0">
                      <div className="menusBox">
                        <ul className="menusList thirdList">
                          <li>
                            <Link to="/about-version-next">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>About Version Next</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/blog">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Our Blog</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/testimonial">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Testimonials</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Media Coverage</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/career">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Career</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/faqs">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>FAQ</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact-us">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Contact</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0" id="mobileMenuBar">
              <li className="nav-item dropdown popoFace">
                <Link
                  className="dropdown-toggle nav-link "
                  to="#"
                  id="servicesDrop0"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Hosting
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </Link>
                <ul
                  className="dropdown-menu zerothMenu"
                  aria-labelledby="servicesDrop0"
                >
                  <h5>Web hosting that scales from easy to expert.</h5>
                  <p>
                    A ton of website hosting options, 99.9% uptime guarantee,
                    free SSL certificate, easy WordPress installs, and a free
                    domain for a year.
                  </p>
                  <div className="innerMenusWrap">
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link
                              to="https://madpopo.com/wordpress-hosting"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img src={hosting1} alt="WordPress Hosting" />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>WordPress Hosting</h6>
                                  <p>
                                    Streamline your site with WordPress hosting.
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://madpopo.com/reseller-hosting"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img src={hosting5} alt="Reseller Hosting" />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Reseller Hosting</h6>
                                  <p>
                                    Manage multiple sites with reseller hosting.
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link
                              to="https://madpopo.com/woocommerce-hosting"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img
                                    src={hosting2}
                                    alt="WooCommerce Hosting"
                                  />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>WooCommerce Hosting</h6>
                                  <p>
                                    Power up your online store with WooCommerce
                                    hosting.
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://madpopo.com/virtual-server"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img src={hosting6} alt="Virtual Server" />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Virtual Server</h6>
                                  <p>
                                    Streamline IT Infrastructure with Cloud
                                    Servers
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link
                              to="https://madpopo.com/windows-hosting"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img
                                    src={hosting3}
                                    alt="Windows Web Hosting"
                                  />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Windows Web Hosting</h6>
                                  <p>Enhance your site with Windows hosting.</p>
                                  <br />
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="https://madpopo.com/dedicated-server"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img src={hosting7} alt="Dedicated Server" />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Dedicated Server</h6>
                                  <p>
                                    Maximize performance with dedicated server.
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link
                              to="https://madpopo.com/linux-web-hosting"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img src={hosting4} alt="Linux Web Hosting" />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Linux Web Hosting</h6>
                                  <p>Optimize your site with Linux hosting.</p>
                                  <br />
                                </div>
                              </div>
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="https://madpopo.com/business-email-hosting"
                              target="_blank"
                            >
                              <div className="menuItem">
                                <span>
                                  <img
                                    src={hosting8}
                                    alt="Business Email Hosting"
                                  />
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Business Email Hosting</h6>
                                  <p>
                                    Streamline communication with business email
                                    hosting.
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  id="servicesDrop"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Services
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </Link>
                <ul
                  className="dropdown-menu firstMenu"
                  aria-labelledby="servicesDrop"
                >
                  <div className="innerMenusWrap">
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/mobile-app-development">
                              <div className="menuItem">
                                <span>
                                  <i className="material-icons">phone_iphone</i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Mobile app development</h6>
                                  <p>
                                    Unlock Unprecedented Business Growth with
                                    App Development
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/web-app-development">
                              <div className="menuItem">
                                <span>
                                  <i className="material-icons">
                                    desktop_windows
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Web app development</h6>
                                  <p>Web Application Development Services</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/design-and-graphics">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    design_services
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Design and Graphics</h6>
                                  <p>
                                    Design Thinking and Strategy Integrated
                                    Shaping Our Approach
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/devops">
                              <div className="menuItem">
                                <span>
                                  <i className="material-icons">
                                    all_inclusive
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>DevOps</h6>
                                  <p>
                                    Unlock Unprecedented Business Growth with
                                    our DevOps
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/internet-of-things">
                              <div className="menuItem">
                                <span>
                                  <i className="material-icons">language</i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>IoT</h6>
                                  <p>
                                    Unlock Unprecedented Business Growth with
                                    our IoT
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/digital-marketing">
                              <div className="menuItem">
                                <span>
                                  <i className="material-icons">web</i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Digital Marketing</h6>
                                  <p>
                                    Unlocking Unprecedented Business Growth
                                    Through Digital Services
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-3 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/ai-and-ml">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    psychology
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>AI & ML</h6>
                                  <p>
                                    Unlock Unprecedented Business Growth with
                                    our AI and ML
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/quality-assurance">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    task
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>QA</h6>
                                  <p>
                                    Unlock Unprecedented Business Growth with
                                    our QA Team
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="innerMenusWrap2">
                    <div className="col-md-4">
                      <div className="menusBox2">
                        <Link to="/it-consultancy-solutions">
                          <h5>IT Consultancy Solutions </h5>
                          <p>
                            Empowering Businesses with Strategic IT Consultancy
                            Solutions{" "}
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="menusBox2">
                        <Link to="/digital-transformation-experts">
                          <h5>Digital Transformation Experts</h5>
                          <p>
                            Driving Digital Transformation for Enhanced Business
                            Success
                          </p>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="menusBox2">
                        <Link to="/bi-and-analytics">
                          <h5>BI & Analytics</h5>
                          <p>
                            Unlock the Power of Data with Version Next
                            Technologies Business Intelligence & Analytics
                            Solutions
                          </p>
                        </Link>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  id="servicesDrop1"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Industries
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </Link>
                <ul
                  className="dropdown-menu secondMenu"
                  aria-labelledby="servicesDrop1"
                >
                  <div className="innerMenusWrap">
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/healthcare">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    health_and_safety
                                  </i>
                                </span>

                                <div className="menueTextWrap">
                                  <h6>Health and Life Sciences</h6>
                                  <p>
                                    Solutions for doctor processing patient data
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/education-software-development-company">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    school
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Education</h6>
                                  <p>Digital platform for LMS and SMS</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/fin-tech">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    account_balance
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>FinTech</h6>
                                  <p>
                                    Investment, Trading, Wallets and Banking
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/insurance">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    volunteer_activism
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Insurance</h6>
                                  <p>
                                    Management system, Fruad analysis and CRM
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/logistics-software-solutions">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    inventory
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Supply Chain & Logistics</h6>
                                  <p>
                                    Software for delivery and curiour management
                                  </p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/digital-retail">
                              <div className="menuItem">
                                <span>
                                  <i className="material-symbols-outlined">
                                    storefront
                                  </i>
                                </span>
                                <div className="menueTextWrap">
                                  <h6>Digital Retail</h6>
                                  <p>RMS, POS and CRM System</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="innerMenusWrap2 indusInnerMenuList">
                    <div className="col-md-4">
                      <div className="menusBox2">
                        <Link to="/travel">
                          <h5>Travel</h5>
                        </Link>
                        <Link to="/marketplace">
                          <h5>Marketplaces</h5>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="menusBox2 induxSub">
                        <Link to="#">
                          <h5>Media Content</h5>
                        </Link>
                        <Link to="/social-network">
                          <h5>Social Networks</h5>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="menusBox2">
                        <Link to="/sports-gaming-development">
                          <h5>Sports</h5>
                        </Link>
                        <Link to="/blockchain">
                          <h5>BlockChain</h5>
                        </Link>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  id="servicesDrop1"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Security
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </Link>
                <ul
                  className="dropdown-menu secondMenu"
                  aria-labelledby="servicesDrop1"
                >
                  <h5 className="menuSectionTitle">Services</h5>
                  <hr />
                  <div className="innerMenusWrap">
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/information-security-audit-service">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>IT Security Audit OnDemand </h6>
                                  <p>Ensuring digital defenses are always vigilant.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/vulnerability-assessment-service">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Vulnerability Assessment </h6>
                                  <p>Systematic evaluation to uncover and address potential security risks.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/web-application-penetration-testing">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Web Application Penetration Testing </h6>
                                  <p>Proactively securing your online assets through thorough security assessment.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/mobile-application-security-testing">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Mobile App Security Testing</h6>
                                  <p>Safeguarding your mobile application against vulnerabilities and threats.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/web-application-security">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Web Application Security</h6>
                                  <p>Safeguarding online systems &nbsp;and data from digital threats and breaches.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/website-security-management">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Website Security Management </h6>
                                  <p>Protecting and maintaining the integrity of your online presence.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/penetration-testing-service">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Penetration Testing Service </h6>
                                  <p>Proactively identifying and strengthening security weaknesses in your digital infrastructure.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/spear-phishing">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Spear Phishing </h6>
                                  <p>Targeted deception tactics aiming at specific individuals or organizations for cyberattacks.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/wireless-auditing">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Wireless Auditing </h6>
                                  <p>Evaluating and enhancing the security of wireless networks.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    
                  </div>
                  <h5 className="menuSectionTitle mt-5">
                    Training & Skill Development
                  </h5>
                  <hr />
                  <div className="innerMenusWrap">
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                          <li>
                            <Link to="/ethical-hacking-for-beginners">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Ethical Hacking For Beginners</h6>
                                  <p>A beginner's guide to learning cybersecurity through responsible hacking practices.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/andriod-app-development-training">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Secured Mobile App Development </h6>
                                  <p>Building mobile applications with robust security measures.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 px-0">
                      <div className="menusBox">
                        <ul className="menusList">
                        <li>
                            <Link to="/penetration-testing-training">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Penetration Testing </h6>
                                  <p>Simulating cyberattacks to assess and strengthen system security.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/aspdotnet-development-training">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Secured ASP.NET </h6>
                                  <p>Implementing robust security measures in ASP.NET web applications.</p>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <Link
                  className="nav-link dropdown-toggle"
                  id="servicesDrop23"
                  to="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Company
                  <span className="material-symbols-outlined">
                    chevron_right
                  </span>
                </Link>
                <ul
                  className="dropdown-menu thirdMenu"
                  aria-labelledby="servicesDrop23"
                >
                  <div className="innerMenusWrap">
                    <div className="col-12 px-0">
                      <div className="menusBox">
                        <ul className="menusList thirdList">
                          <li>
                            <Link to="/about-version-next">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>About Version Next</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/blog">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Our Blog</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/testimonial">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Testimonials</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="#">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Media Coverage</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/career">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Career</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/faqs">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>FAQ</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/contact-us">
                              <div className="menuItem">
                                <div className="menueTextWrap">
                                  <h6>Contact</h6>
                                </div>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
              <ExpertButton
                rText="Call Back"
                rLink=""
                rName="rAnimeBtn navBtn mobCallBckBtn"
              />
            </ul>
          </div>

          <ExpertButton
            rText="Call Back"
            rLink=""
            rName="rAnimeBtn navBtn deskCallBckBtn"
          />
        </div>
      </nav>
    </header>
  );
}

export default NavBar;
