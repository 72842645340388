import React from "react";
import IndusBanner from "../components/IndustriesPageComponents/IndusBanner";
import IndusSecSection from "../components/IndustriesPageComponents/IndusSecSection";
import Industries from "../components/Homepage/IndustriesSection/Industries";
import IndusExpert from "../components/IndustriesPageComponents/IndusExpert";
import { Accodion } from "../components/Accordion/AccordionItem";
import FaqVideo from "../components/FaqVideo/FaqVideo";
import TransparentRoundedBtn from "../components/CTA/TransparentRoundedBtn";
import BlogSection from "../components/BlogPosts/BlogSection";
import { sanyogprofileImg } from "../components";
function IndustriesPage() {
  return (
    <>
      <section id="industriesPage" className="industriesPage">
        <IndusBanner />
        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </section>
      <section className="indusSecondSection">
        <IndusSecSection />
      </section>
      <section id="industriesSection" className="industriesSection">
        <div className="leftTop"></div>
        <div className="rightTop"></div>
        <Industries
          char1="Our"
          char2="Services"
          char3="In"
          char4="Industries"
          secPara="We have honed our skills in developing advanced web platforms,
    mobile applications, and intricate systems that align with the
    latest industry trends."
          icon1="health_and_safety"
          cardTitle1="Health and Life Sciences"
          cardDesc1="EHR & EMR, Patient Portals software development services
          for institutions maintaining physical and mental
          well-being: medical, telemedicine."
          cardLink1="/healthcare"
          icon2="school"
          cardTitle2="Education"
          cardDesc2="Education app development services provided by Version
          Next empower learning experience for students, teachers,
          and administrations."
          cardLink2="/education-software-development-company"
          icon3="account_balance"
          cardTitle3="FinTech"
          cardDesc3="Financial software development services that help automate
          processes, improve transaction security, and put the
          latest technologies for your business."
          cardLink3="/fin-tech"
          icon4="volunteer_activism"
          cardTitle4="Insurance"
          cardDesc4="Insurance software development services that automate
          processes, improve data security, and help your business
          benefit from the latest technologies."
          cardLink4="/insurance"
          icon5="inventory"
          cardTitle5="Supply Chain & Logistics"
          cardDesc5="We ensure consistent development, scaling, and high
          performance products for supply chain & logistics
          management to meet your corporate goals."
          cardLink5="/logistics-software-solutions"
          icon6="storefront"
          cardTitle6="Digital Retail"
          cardDesc6="Solutions counting customer’s business processes,
          Increasing the performance of customer’s retail software
          by automating manual processes."
          cardLink6="/digital-retail"
        />
        <div className="rightBtm"></div>
        <div className="leftBtm"></div>
      </section>
      <section className="IndusExpertSection">
        <IndusExpert
          cName="Version Next Technologies"
          cSecTitle="Why Hire Version Next Technologies as IT Services"
          profileName="Mr. Sanyogg Shelar"
          designation="Buisness Head"
          cardTitle1="Innovation"
          cardData1="We brings innovative solutions that leverage cutting-edge technologies to drive business growth and stay ahead of the competition."
          cardTitle2="Integrity"
          cardData2="We uphold the highest standards of integrity, ensuring transparency, honesty, and ethical practices in all our interactions with clients."
          cardTitle3="Customer-Obsession"
          cardData3="Our unwavering focus on delivering exceptional customer experiences sets us apart, as we go above and beyond to meet and exceed client expectations."
          cardTitle4="Quality"
          cardData4="We are committed to delivering top-notch quality in all our services, adhering to industry best practices and rigorous quality control processes to ensure excellence."
          profileImg={sanyogprofileImg}
          facebookLink="https://profiles.wordpress.org/codexdemon/"
          facebookIcon="fab fa-wordpress"
          twiiterLink="https://twitter.com/sanyog"
          twitterIcon="fab fa-twitter-square"
          linkdinLink="https://www.linkedin.com/in/sanyogs"
          linkdinIcon="fab fa-linkedin"
          />
      </section>
      <section className="commonFaq">
        <div className="container">
          <div className="sectionTitle">
            <h2>
              Frequently Asked <span className="textGrade">Questions</span>
            </h2>

            <p>
              Singling out common points and possible difficulties which can be
              encountered in EHR, EMR, and Patient Portal software development
            </p>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6 col-12">
              <Accodion />
            </div>
            <div className="col-md-12 col-lg-6 col-12">
              <FaqVideo />
            </div>
          </div>
          <div className="readMoreBtnWrap">
            <TransparentRoundedBtn
              rText="Read More"
              rLink="/faqs"
              rName="rAnimeBtn readMoretBtn"
            />
          </div>
        </div>
      </section>
      <BlogSection />
    </>
  );
}

export default IndustriesPage;
