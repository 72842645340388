export const Plans = [
  { name: "Initial Analysis Report (SEO Audit)" },
  { name: "Pre SEO Ranking Report" },
  { subname: "On Page Optimization" },
  { name: "Competitor analysis" },
  { name: "Dedicated Account Manager" },
  { name: "Experience Level of Resource" },
  { name: "Number of Keywords to be optimized (up to)" },
  { name: "Google Analytics Setup & Config" },
  { name: "Keyword Research" },
  { name: "Title Tag / Meta Tag Creation" },
  { name: "Heading (Hx) Tags Optimization" },
  { name: "Content Optimization" },
  { name: "Robots.txt Creation" },
  { name: "Renaming URL (will need developers help in some cases)" },
  { name: "404 Broken Link Analysis" },
  { name: "Cross Linking (Internal Link Placement)" },
  { name: "Canonical Tags" },
  { name: "Inclusion of Header tags" },
  { name: "Development Work" },
  { name: "Image Optimization" },
  { name: "Google Webmaster Account Setup" },
  { name: "Google Analytics Code" },
  { name: "Google Places Listing" },
  { name: "XML sitemap" },
  { name: "Outreach + Content Writing" },
  { name: "Monthly 2 blogs" },
  { subname: "OFFPAGE OPTIMIZATION" },
  { name: "Search Engines Submission" },
  { name: "Local Directory Submission" },
  { name: "Social Book marking" },
  { name: "Photo / Image Sharing" },
  { name: "Article Writing" },
  { name: "Article Submission" },
  { name: "Classified Listings" },
  { name: "Blog Writing" },
  { name: "Blog Creation and Postings" },
  { name: "Local Business Centers" },
  { name: "Press Release Creation" },
  { name: "Press Release Submission" },
  { name: "Press Release Bookmarking" },
  { name: "RSS Feeds" },
  { name: "PPT Creation" },
  { name: "PPT Submission" },
  { name: "Video Submission" },
  { subname: "REPORT" },
  { name: "Performance Report" },
  { name: "Weekly Report" },
  { name: "Monthly Report" },
  { subname: "CUSTOMER SUPPPORT" },
  { name: "Email" },
  { name: "Chat" },
  { name: "Phone" },
  { subname: "COMPLIMENTARY" },
  { name: "Social Media Posting (If Required)" },
  { name: "Landing Page Creation" },
  { name: "PPC Campaign check and optimization" },
];
