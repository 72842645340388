import React from "react";
import { flow1, flow2 } from "..";

function WorkFlowDevops() {
  return (
    <div className="container">
      <div className="content-information-block">
        <h2>AWS CI Workflow</h2>
        <p>
          We follow a specific process called the AWS CI Workflow to build and
          deliver products more rapidly and reliably. This process combines
          DevOps practices with AWS services to create a seamless workflow for
          building and testing code. This allows us to deliver high-quality
          products to our clients in a timely and efficient manner.
        </p>
        <img
          src={flow2}
          width="837"
          height="684"
          alt="AWS CI Workflow"
        />
      </div>
      <div className="content-information-block">
        <h2>Azure CI Workflow</h2>
        <p>
          We collaborate on software development by following the Azure CI
          Workflow. This involves using source control, work tracking, and
          continuous integration and delivery to streamline the development
          process. We implement Azure DevOps Services both in the Cloud and
          On-premise to ensure that we have access to all of the tools we need
          to develop high-quality software. By using this workflow, we can work
          more efficiently and deliver better products to our clients.
        </p>
        <p></p>
        <img
          src={flow1}
          width="960"
          height="984"
          alt="Azure CI Workflow"
        />
      </div>
    </div>
  );
}

export default WorkFlowDevops;
