import React from "react";
import PageBanner from "../components/TermsPageComponents/PageBanner";

function TermsConditions() {
  return (
    <section id="termsPage" className="termsPage">
      <div className="pagesBanner">
        <PageBanner PageTitle="Terms & Conditions" />

        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className=" termsTopSection">
        <div className="container">
          <div className="termsWrapper">
            <div className="col-md-12">
              <h6>
                <b>Effective Date:</b> 01/07/2023
              </h6>
              <p className="termsMainPara">
                Please read these Terms and Conditions ("Terms") carefully
                before using the services provided by Version Next Technologies
                Private Limited ("Version Next Technologies," "we," "us," or
                "our"). These Terms govern your use of our website and services.
                By accessing or using our website or services, you agree to be
                bound by these Terms. If you do not agree with any part of these
                Terms, you may not access or use our website or services.
              </p>
              <div className="termsSection">
                <h5>1. Acceptance of Terms</h5>
                <ul>
                  <li>
                    <strong>Agreement to Terms:</strong> By using our website or
                    services, you acknowledge that you have read, understood,
                    and agree to be bound by these Terms, as well as our Privacy
                    Policy.
                  </li>
                  <li>
                    <strong>Modifications:</strong> We reserve the right to
                    modify these Terms at any time without prior notice. The
                    updated Terms will be effective as of the date they are
                    posted on our website. It is your responsibility to review
                    the Terms periodically for any changes. Your continued use
                    of our website or services after the posting of updated
                    Terms constitutes your acceptance of the modifications.
                  </li>
                </ul>
              </div>
              <div className="termsSection">
                <h5>2. Services</h5>
                <ul>
                  <li>
                    <strong>Description: </strong> Version Next Technologies
                    provides IT services, including but not limited to software
                    development, web development, mobile application
                    development, and related consulting services ("Services").
                  </li>
                  <li>
                    <strong>Eligibility: </strong> You must be at least 18 years
                    old to access and use our Services. By using our Services,
                    you represent and warrant that you are of legal age to form
                    a binding contract and that all information you provide to
                    us is accurate and complete.
                  </li>
                  <li>
                    <strong>Intellectual Property: </strong> All intellectual
                    property rights in our website, services, and associated
                    materials, including but not limited to trademarks, logos,
                    software, and content, are the property of Version Next
                    Technologies or our licensors. You agree not to use,
                    reproduce, modify, or distribute any intellectual property
                    without our prior written consent.
                  </li>
                </ul>
              </div>
              <div className="termsSection">
                <h5>3. User Obligations</h5>
                <ul>
                  <li>
                    <strong>Account Registration: </strong> In order to access
                    certain features or use our Services, you may be required to
                    create an account. You agree to provide accurate and
                    complete information during the registration process and to
                    keep your account credentials secure. You are solely
                    responsible for all activities that occur under your
                    account.
                  </li>
                  <li>
                    <strong>Prohibited Conduct: </strong> You agree not to
                    engage in any of the following activities while using our
                    website or services:
                    <ul>
                      <li>
                        a. Violating any applicable laws, regulations, or
                        third-party rights;
                      </li>
                      <li>
                        b. Attempting to gain unauthorized access to our systems
                        or interfering with the proper functioning of our
                        website or services;{" "}
                      </li>
                      <li>
                        c. Uploading, posting, or transmitting any harmful,
                        infringing, or offensive content;
                      </li>
                      <li>
                        d. Engaging in any fraudulent, deceptive, or misleading
                        activities;
                      </li>
                      <li>
                        e. Misrepresenting your affiliation with Version Next
                        Technologies or any third party.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="termsSection">
                <h5>4. Limitation of Liability</h5>
                <ul>
                  <li>
                    <strong>Disclaimer: </strong> Version Next Technologies
                    provides its Services on an "as is" and "as available"
                    basis. We make no warranties or representations, express or
                    implied, regarding the reliability, accuracy, availability,
                    or suitability of our website or services for any particular
                    purpose. You acknowledge and agree that your use of our
                    website or services is at your own risk.
                  </li>
                  <li>
                    <strong>Limitation of Liability: </strong> To the maximum
                    extent permitted by applicable law, Version Next
                    Technologies and its directors, officers, employees, agents,
                    and affiliates shall not be liable for any direct, indirect,
                    incidental, consequential, or punitive damages arising out
                    of or relating to your use or inability to use our website
                    or services, even if we have been advised of the possibility
                    of such damages.
                  </li>
                </ul>
              </div>
              <div className="termsSection">
                <h5>5. Governing Law and Dispute Resolution</h5>
                <ul>
                  <li>
                    <strong>Governing Law: </strong> These Terms shall be
                    governed by and construed in accordance with the laws of
                    India. Any dispute arising out of or relating to these Terms
                    or your use of our website or services shall be subject to
                    the exclusive jurisdiction of the courts located in [Insert
                    City], India.
                  </li>
                </ul>
              </div>
              <div className="termsSection">
                <h5>6. Termination</h5>

                <p>
                  We may, in our sole discretion, suspend, restrict, or
                  terminate your access to our website or services at any time
                  and for any reason, without prior notice or liability.
                </p>
              </div>
              <div className="termsSection">
                <h5>7. Contact Us</h5>
                <p>
                  If you have any questions or concerns about these Terms or our
                  Services, please contact us at:
                </p>
                <address>
                  <strong>Version Next Technologies Private Limited</strong>{" "}
                  <br />
                  Suite No 607-608, A Wing Pranik Chambers, <br />
                  SakiVihar Road, Sakinaka Metro, <br />
                  Andheri East, Mumbai - 400072
                  <br />
                  Maharashtra, India.
                  <br />
                  <strong>Phone:</strong> +91-7039003001
                  <br />
                  <strong>Email:</strong>{" "}
                  
                    sales@version-next.com.
                </address>
                <p className="mb-0">
                  By using our website or services, you acknowledge that you
                  have read, understood, and agreed to these Terms and
                  Conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default TermsConditions;
