import React from "react";
import ExpertButton from "../../../components/CTA/ExpertButton";


function HireExpertise({expertiseData}) {
  return (
    <div className="container">
      <div className="sectionTitle">
        <h3>Our Expertise</h3>
      </div>
      <div className="row expertiseRow">
        {expertiseData.map((data, index) => {
          return (
            <div className="col-lg-3 col-md-4 col-sm-6 col-12 counterExpertise" key={index}>
              <div className="serExpertise">
                <div className="servCount">
                  <span>{data.id + 1}</span>
                </div>
                <p className="serTitle">
                  {data.text}{" "}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="hireOurExpertsBtnWrap">
        <ExpertButton rName="rAnimeBtn expertBtn" rText="Talk To Expert" />
      </div>
    </div>
  );
}

export default HireExpertise;
