export const YourNeed = [
{
 id : 0,
 title: "Customized Solutions",
 description: "Our Java developers work closely with you to understand your specific goals and challenges. This enables us to tailor our services to your exact needs, ensuring that you get the most out of our collaboration.",
 img: "https://dev.version-next.com/static/media/needImg1.png"
},
{
    id : 1,
    title: "Agile Development",
    description: "We embrace agile development methodologies to guarantee flexibility and adaptability throughout every phase of the project.",
    img: "https://dev.version-next.com/static/media/needImg2.png"
},
{
    id : 2,
    title: "Full-Cycle Development",
    description: "From conceptualization to deployment and ongoing maintenance, our Java developers are equipped to handle the entire development cycle.",
    img: "https://dev.version-next.com/static/media/needImg3.png"
},
{
    id : 3,
    title: "Web Application Development",
    description: "Our Java developers excel in creating dynamic and responsive web applications that cater to your users' needs.    ",
    img: "https://dev.version-next.com/static/media/needImg4.png"
},
{
    id : 4,
    title: "Enterprise Solutions",
    description: "Our developers integrate Java technology with your existing systems to create cohesive and effective software solutions.",
    img: "https://dev.version-next.com/static/media/needImg5.png"
},
{
    id : 5,
    title: "Maintenance and Support",
    description: "Our commitment doesn't end with deployment. We provide ongoing maintenance and support services to ensure that your Java-based applications continue to perform optimally.",
    img: "https://dev.version-next.com/static/media/needImg1.png"
}
]