import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faTimes } from "@fortawesome/free-solid-svg-icons";
import SubmitTransperentButton from "../CTA/SubmitTransperentButton";
import { Link } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import Lottie from "react-lottie";
import success from "../Homepage/HomeSliderAnimation/success.json";

function ContactForm() {
  const testSiteKey = "6Lc7Ze8lAAAAALRp1WYcyt_QvdI7dnWwZsAf-XB6";
  
  const [formData, setFormData] = useState({
    name: "",
  email: "",
  phoneNumber: "",
  ndaChecked: false,
  aboutProject: "",
  attachment: null, // Set the initial value to null
  });
// console.log(formData);
  const [fileName, setFileName] = useState("");
  const [file, setFile] = useState(null);
  const [textareaHeight, setTextareaHeight] = useState("0");
  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    nda: "",
    aboutProject: "",
    attachment: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

 

  const handlePhoneNumberChange = (event) => {
    const inputPhoneNumber = event.target.value;
    const sanitizedPhoneNumber = inputPhoneNumber.replace(/[^0-9]/g, "");
    setFormData((prevData) => ({
      ...prevData,
      phoneNumber: sanitizedPhoneNumber,
    }));
  };

  const handleTextareaChange = (event) => {
    const textareaLineHeight = 2;
    const minRows = 1;

    event.target.style.height = "auto";

    const currentHeight = event.target.scrollHeight;
    const minHeight = textareaLineHeight * minRows;

    event.target.style.height = `${Math.max(currentHeight, minHeight)}px`;

    setFormData((prevData) => ({
      ...prevData,
      aboutProject: event.target.value,
    }));
  };

  const handleFileSelect = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);

      const reader = new FileReader();

      setFormData((prevData) => ({
        ...prevData,
        file_name: selectedFile.name,
      }));

      reader.onload = (e) => {
        // When the file is loaded, convert it to base64 and set it in FormData
        const base64File = e.target.result.split(',')[1]; // Get the base64 data after the comma
        setFormData((prevData) => ({
          ...prevData,
          attachment: base64File,
        }));
      };

      reader.readAsDataURL(selectedFile);
      // setFormData((prevData) => ({
      //   ...prevData,
      //   attachment: selectedFile, // Set the attachment field to the selected file name
      // }));

      if (isValidMimeType(selectedFile.type)) {
        setFile(selectedFile);
        setFileName(selectedFile.name);
  
        const reader = new FileReader();
  
        setFormData((prevData) => ({
          ...prevData,
          file_name: selectedFile.name,
        }));
  
        reader.onload = (e) => {
          // When the file is loaded, convert it to base64 and set it in FormData
          const base64File = e.target.result.split(',')[1]; // Get the base64 data after the comma
          setFormData((prevData) => ({
            ...prevData,
            attachment: base64File,
          }));
        };
  
        reader.readAsDataURL(selectedFile);
      }
      else {
        // Invalid MIME type
        setFile(null);
        setFileName("");
        setFormData((prevData) => ({
          ...prevData,
          attachment: null, // Reset the attachment field to null if the file is invalid
        }));
        alert("Invalid file type. Please select a valid file.");
      }
    } else {
      setFile(null);
      setFileName("");
      setFormData((prevData) => ({
        ...prevData,
        attachment: null, // Reset the attachment field to null if no file is selected
      }));
    }
  };
  const isValidMimeType = (fileType) => {
    // Define the allowed MIME types here
    const allowedMimeTypes = ["image/jpeg", "image/png", "application/pdf"];
  
    // Check if the selected file's MIME type is in the allowedMimeTypes array
    return allowedMimeTypes.includes(fileType);
  };
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: success,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const validateForm = () => {
    let valid = true;
    const errors = {
      name: "",
      email: "",
      phoneNumber: "",
      nda: "",
      aboutProject: "",
      attachment: "",
    };

    if (!formData.name.trim()) {
      valid = false;
      errors.name = "Please enter your name.";
    }

    if (!formData.email.trim()) {
      valid = false;
      errors.email = "Please enter your email.";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        valid = false;
        errors.email = "Please enter a valid email address.";
      }
    }

    if (!formData.phoneNumber.trim()) {
      valid = false;
      errors.phoneNumber = "Please enter your phone number.";
    } else {
      const phoneNumberRegex = /^\d{10}$/;
      if (!phoneNumberRegex.test(formData.phoneNumber)) {
        valid = false;
        errors.phoneNumber = "Please enter a valid 10-digit phone number.";
      }
    }

    if (!formData.aboutProject.trim()) {
      valid = false;
      errors.aboutProject = "Please provide information about your project.";
    }

    if (!formData.ndaChecked) {
      valid = false;
      errors.nda = "Please agree to the NDA terms.";
    }

    setFormErrors(errors);
    return valid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      try {
        const response = await axios.post("https://version-next.com/send-inquiry.php", formData);

        if (response.data.type == 'success') {
          console.log(formData);
          setSuccessMessage("Form submitted successfully!");
          var close_button = document.getElementsByClassName('close-button');
          for (var i = 0; i < close_button.length; i++) {
            close_button[i].classList.add('d-none');
          }
          setIsFormSubmitted(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          setFormErrors(error.response.data.errors);
        } else {
          console.error("Error submitting form:", error);
          console.log(error);
        }
      }
    }
  };

  const handleCloseForm = () => {
    setIsFormSubmitted(false);
    setSuccessMessage("");
    var close_button = document.getElementsByClassName('close-button');
    for (var i = 0; i < close_button.length; i++) {
      close_button[i].classList.add('d-block');
      close_button[i].classList.remove('d-none');
    }
    setFileName("");
    setFile(null);
    setTextareaHeight("0");
    setFormData({
      name: "",
      email: "",
      phoneNumber: "",
      ndaChecked: false,
      aboutProject: "",
    });
    setFormErrors({
      name: "",
      email: "",
      phoneNumber: "",
      nda: "",
      aboutProject: "",
      attachment: "",
    });
  };

  if (isFormSubmitted) {
    return (
      <div>
          <div className="overlayBgMsg"></div>
          <div className="successMessage">
            <div className="animDiv">
              <Lottie options={defaultOptions} className="animeGIf" />
            </div>
            <h3 className="successMsgText">{successMessage}</h3>
            <button onClick={handleCloseForm} className="closPopBtn">
              <i className="fa fa-close"></i>
            </button>
          </div>
        </div>
    );
  }

  return (
    <div className="contactFormWrap">
      <div className="formTitle">
        <h3 className="lightText">
          Start <span className="textGrade">growing</span>
        </h3>
        <h3 className="boldText">your business with us</h3>
      </div>
      <div className="formWrapper">
        <form onSubmit={handleSubmit}>
          <div className="inputWrap">
            <div className="form-floating mb-5 col-md-4 cutmWidth-6 noPad">
              <input
                type="text"
                className="form-control"
                placeholder="TypeHere"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
              <label>Name*</label>
              {formErrors.name && (
                <span className="errorMessage">{formErrors.name}</span>
              )}
            </div>
            <div className="form-floating mb-5 col-md-4 cutmWidth-6 noPad">
              <input
                type="text"
                className="form-control"
                placeholder="TypeHere"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <label>Email*</label>
              {formErrors.email && (
                <span className="errorMessage">{formErrors.email}</span>
              )}
            </div>
            <div className="form-floating mb-5 col-md-4 cutmWidth-6 noPad">
              <input
                type="text"
                className="form-control"
                placeholder="TypeHere"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handlePhoneNumberChange}
              />
              <label>Phone Number*</label>
              {formErrors.phoneNumber && (
                <span className="errorMessage">{formErrors.phoneNumber}</span>
              )}
            </div>
          </div>
          <div className="form-floating mb-5">
            <textarea
              type="text"
              className="form-control textInpu"
              placeholder="TypeHere"
              style={{ height: textareaHeight }}
              name="aboutProject"
              value={formData.aboutProject}
              onChange={handleTextareaChange}
            />
            <label>About project*</label>
            {formErrors.aboutProject && (
              <span className="errorMessage">{formErrors.aboutProject}</span>
            )}
            <div className="floatAttach" id={fileName}>
              {file ? (
                <div className="fileSelected">
                  <span className="fileName">
                    {file.name.split(".")[0].length > 15
                      ? `${file.name.split(".")[0].substring(0, 15)}...`
                      : file.name.split(".")[0]}
                  </span>
                  <span className="fileType">
                    .{file.name.split(".").pop()}
                  </span>
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => setFile(null)}
                  />
                </div>
              ) : (
                <label className="filelabel">
                  <FontAwesomeIcon icon={faPaperclip} />
                  <input
                    className="FileUpload1"
                    id="FileInput"
                    name="attachment"
                    type="file"
                    onChange={handleFileSelect}
                  />
                </label>
              )}
            </div>
            {formErrors.attachment && (
              <span className="errorMessage">{formErrors.attachment}</span>
            )}
          </div>
          <div className="checkBoxWrap mb-5">
            <div className="captcta">
              <ReCAPTCHA
                sitekey={testSiteKey}
                onChange={(value) =>
                  setFormData((prevData) => ({
                    ...prevData,
                    ndaChecked: true,
                  }))
                }
              />
            </div>
            <SubmitTransperentButton
              rText="Send Message"
              rName="rAnimeBtn blackImg"
              disabled={!formData.ndaChecked}
            />
          </div>
          <div className="formFooter">
            <div className="termsLink">
              <p>
                By sending this NDA form I confirm that I have read and accept
                the{" "}
                <Link to="/privacy-policy" target="_blank">
                  Privacy Policy
                </Link>
              </p>
            </div>
            
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
