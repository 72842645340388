export const ExpertiseData = [
    {
        id: 0,
        text: "ReactJS Web Application for Enterprises"
    },
    {
        id: 1,
        text: "Supporting, Maintaining, and Testing ReactJS Applications"
    },
    {
        id: 2,
        text: "Services for Developing ReactJS Web Applications"
    },
    {
        id: 3,
        text: "Bespoke ReactJS Development"
    },
    {
        id: 4,
        text: "ReactJS Migration Assistance"
    },
    {
        id: 5,
        text: "Integrating UI into an Existing App"
    },
    {
        id: 6,
        text: "Building a Progressive Web App (PWA) using ReactJS"
    },
    {
        id: 7,
        text: "Incorporating ReactJS with Diverse Amazon Microservices"
    },
    {
        id: 8,
        text: "Development of React Native Apps with Redux"
    },
    {
        id: 9,
        text: "Binding Node.js API in ReactJS"
    },
    {
        id: 10,
        text: "Implementing an Enterprise Hybrid Model using TypeScript and ReactJS"
    },
    {
        id: 11,
        text: "Development of Plugins for ReactJS"
    },
]