import React from "react";

function VntWork(props) {

  return (
    
      <div className="sectionTitle">
        <h2>
          {props.subText} <span className="textGrade">{props.mainText}</span>
        </h2>
        <p>{props.paratext}</p>
      </div>
      
  );
}

export default VntWork;
