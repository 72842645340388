import React from "react";

function HealthCareTabSection(props) {
  return (
    
      <div className="sectionTitle">
        <h2>
          {props.tabSectionTitle1}{" "}
          <span className="textGrade">{props.tabSectionTitle2}</span>{" "}
          {props.tabSectionTitle3}
        </h2>
        <p>{props.tabSectionPara}</p>
      </div>
      
    
  );
}

export default HealthCareTabSection;
