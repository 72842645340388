import React from "react";
import { imgFloat1, imgFloatw2 } from "..";
function OurStructure() {
  return (
    <div className="container">
      <div className="sectionTitle">
        <h2>
          Our <span className="textGrade">Values </span>
        </h2>
        <p>
          Being on the market since 2003, we have managed to gain the trust and
          respect from our clients. Long-term relationships and happy customers
          have always been our main goals.
        </p>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-10 mx-md-auto col-12">
          <div className="structureLeftBox">
            <div className="structBox">
              <div className="iconBox">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 160.01 160.03"
                  >
                    <path
                      className="cls-1"
                      d="M-300.81,442.06a67.87,67.87,0,0,0,30.62-30.61c2.57,2.47,5.19,4.72,5.84,8.44a10.09,10.09,0,0,1-2,8.28c-.74,1-.66,1.39.15,2.2q15.53,15.42,31,30.92c2.83,2.83,2.83,4.56,0,7.41s-5.84,5.86-8.77,8.77c-2.31,2.3-4.28,2.29-6.6,0q-15.64-15.65-31.26-31.32c-.76-.76-1.17-1-2.19-.2a10.3,10.3,0,0,1-13.08-.25C-298.4,444.59-299.53,443.32-300.81,442.06Z"
                      transform="translate(393.08 -319.15)"
                    />
                    <path
                      className="cls-1"
                      d="M-330,319.15a63,63,0,0,0-63,63,63.08,63.08,0,0,0,62.95,63.07,63.13,63.13,0,0,0,63.06-63A63.09,63.09,0,0,0-330,319.15Zm-.1,113.3a50.33,50.33,0,0,1-50.22-50.3,50.36,50.36,0,0,1,50.25-50.28,50.37,50.37,0,0,1,50.32,50.38A50.38,50.38,0,0,1-330.14,432.45Z"
                      transform="translate(393.08 -319.15)"
                    />
                    <path
                      className="cls-1"
                      d="M-288.49,381.15a66.27,66.27,0,0,0-11-12c-8.85-7.39-18.85-11.85-30.56-11.88A38.87,38.87,0,0,0-341.94,359c-12.77,3.74-22.2,11.93-29.92,22.42-.53.72-.26,1.15.14,1.7a63.63,63.63,0,0,0,15.27,15.27c12.42,8.51,25.84,11.19,40.33,6.33,11.83-4,20.62-11.95,27.87-21.84C-287.7,382.09-288.11,381.68-288.49,381.15Zm-41.64,21.94A20.91,20.91,0,0,1-351,382.14a20.86,20.86,0,0,1,20.89-20.9,20.88,20.88,0,0,1,21,20.85A20.92,20.92,0,0,1-330.13,403.09Z"
                      transform="translate(393.08 -319.15)"
                    />
                    <path
                      className="cls-1"
                      d="M-330.09,370a12.07,12.07,0,0,0-12.13,12.24,12,12,0,0,0,12.31,12,12,12,0,0,0,12-12.22A12.09,12.09,0,0,0-330.09,370Zm-2.82,12.17a3.46,3.46,0,0,1-3.37-3.3,3.24,3.24,0,0,1,3.25-3.33,3.16,3.16,0,0,1,3.33,3.22A3.34,3.34,0,0,1-332.91,382.16Z"
                      transform="translate(393.08 -319.15)"
                    />
                  </svg>
                </span>
              </div>
              <div className="structDataWrap">
                <h5>Transparency</h5>
                <p>
                  At Version Next, we believe that honesty with clients,
                  partners, and employees is a fundamental step to creating
                  great products.
                </p>
              </div>
            </div>
            <div className="structBox">
              <div className="iconBox">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Layer_1"
                    data-name="Layer 1"
                    viewBox="0 0 383.14 383.07"
                  >
                    <path
                      d="M-444.08,418.64c1.81-7.11,3.88-14.07,8.32-20.13a43.51,43.51,0,0,1,53-14.17,43.49,43.49,0,0,1,24.6,49.43,43.39,43.39,0,0,1-43.72,33.65,43.33,43.33,0,0,1-41.56-36.24,13.8,13.8,0,0,0-.68-2.06Z"
                      transform="translate(444.08 -245.9)"
                    />
                    <path
                      d="M-307.1,629c-.07-13.37-2.23-27,3.84-39.72,2-4.22,4.78-8.07,7.31-12.28,22.75,26.55,64.69,25.93,86.61.46,6.33,6.94,10.49,15.05,11.25,24.3.74,9,.23,18.16.27,27.24Z"
                      transform="translate(444.08 -245.9)"
                    />
                    <path
                      d="M-230.14,441.42c-2.25,1.11-3.43,2.06-4.71,2.26-6.09.92-7.17,4.76-6.43,10.06a55.46,55.46,0,0,1,.05,7.32h-22.46c0-4.22.15-8.42-.1-12.61a4.56,4.56,0,0,0-2.2-3c-2.49-1.35-5.21-2.29-8.39-3.64l-9.75,10.61-16-16.19,10.36-9.77c-1.11-2.44-2.3-4.16-2.66-6-.84-4.37-3.42-5.33-7.42-4.88a86,86,0,0,1-9.26.06V393.13c3.65,0,7.36-.18,11,.06,2.76.18,4.38-.46,5.1-3.4.59-2.37,1.85-4.57,3.08-7.48l-10.69-9.7,16.42-16.34,9.58,10.79c2.32-1.07,3.83-2.15,5.48-2.44,5.06-.88,6.07-4,5.48-8.5a67.53,67.53,0,0,1-.07-8.45h22.46c0,4.11.07,8.19,0,12.27-.05,1.84.33,2.91,2.37,3.49,2.62.73,5.07,2,8.48,3.47L-221,356l16.15,16.15-10.33,9.53c1.59,4,2.63,7,4,9.9a3.79,3.79,0,0,0,2.86,1.52c4.08.17,8.18.07,12.53.07v22.45c-4.21,0-8.29.09-12.37,0-1.91-.06-2.87.48-3.45,2.45a80.62,80.62,0,0,1-3.34,8.1l10.55,9.41c-5.8,5.75-11,10.87-16.33,16.16ZM-252.44,425a20.86,20.86,0,0,0,20.73-20.73,21,21,0,0,0-20.77-20.72,21,21,0,0,0-20.73,20.77A20.86,20.86,0,0,0-252.44,425Z"
                      transform="translate(444.08 -245.9)"
                    />
                    <path
                      d="M-220.82,531c0,5.34.61,10.78-.12,16-2.33,16.75-16.24,27.92-33.32,27.32a31.64,31.64,0,0,1-30.09-29.76,171.94,171.94,0,0,1,.44-29.85c2-17.09,16.51-28.19,34-27.27,15.78.82,28.77,14.33,29.49,30.81.19,4.23,0,8.48,0,12.72Z"
                      transform="translate(444.08 -245.9)"
                    />
                    <path
                      d="M-60.94,467.31h-97.3l48.64-96Z"
                      transform="translate(444.08 -245.9)"
                    />
                    <path
                      d="M-286,313.07V245.9h67.15v67.17Z"
                      transform="translate(444.08 -245.9)"
                    />
                    <path
                      d="M-399.16,490.62l22.47-4.34c14.48,31.59,38,55.12,70.08,69.62l-14.82,17.86C-357.5,555.66-383.52,527.94-399.16,490.62Z"
                      transform="translate(444.08 -245.9)"
                    />
                    <path
                      d="M-198.67,556a139.26,139.26,0,0,0,34.84-22,133.28,133.28,0,0,0,32.07-40.89c1.23-2.44,2.63-3.42,5.42-3.33,6.71.22,13.42.07,20.86.07-15.88,37.95-41.86,65.62-78.3,84-2.37-3.22-4.5-6.4-6.93-9.32S-195.75,559.16-198.67,556Z"
                      transform="translate(444.08 -245.9)"
                    />
                    <path
                      d="M-308.7,283.5c0,8,.07,15.2-.08,22.42a3.79,3.79,0,0,1-1.77,2.76,137.65,137.65,0,0,0-60,55.82l-21.89-6.65C-373.82,323.25-346.09,298.61-308.7,283.5Z"
                      transform="translate(444.08 -245.9)"
                    />
                    <path
                      d="M-132.83,367.29c-9.14-16.5-20.25-30.55-34.74-41.49-8.2-6.19-17.27-11.23-26.08-16.58-1.84-1.11-2.67-2.1-2.63-4.28.14-6.93.06-13.85.06-21.86,7.6,3.75,14.62,6.85,21.29,10.58a158,158,0,0,1,52,46.92c1.55,2.17,1.56,3.76.37,6C-126,353.21-129.21,359.94-132.83,367.29Z"
                      transform="translate(444.08 -245.9)"
                    />
                  </svg>
                </span>
              </div>
              <div className="structDataWrap">
                <h5>Flexibility</h5>
                <p>
                  Our team quickly responds to new requirements and changes
                  thanks to the elaborate development process and no
                  bureaucracy.
                </p>
              </div>
            </div>
            <div className="structBox">
              <div className="iconBox">
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="37"
                    height="44"
                    viewBox="0 0 37 44"
                    fill="none"
                  >
                    <path
                      d="M33.1964 4.82717L18.5 0L3.8036 4.82717C2.69633 5.19093 1.73286 5.89119 1.05006 6.8285C0.367264 7.7658 -0.000152876 8.89248 4.7717e-08 10.0485V21.9835C4.7717e-08 35.7775 17.02 43.395 17.7489 43.7122L18.4038 44L19.0846 43.7763C19.8172 43.5343 37 37.708 37 21.9835V10.0485C37.0002 8.89248 36.6327 7.7658 35.9499 6.8285C35.2671 5.89119 34.3037 5.19093 33.1964 4.82717ZM19.3214 25.63C18.998 25.9526 18.6131 26.2084 18.1892 26.3824C17.7652 26.5565 17.3106 26.6453 16.8517 26.6438H16.7906C16.3223 26.6367 15.8604 26.5356 15.4327 26.3465C15.005 26.1574 14.6205 25.8844 14.3024 25.5438L10.0363 21.1438L12.7058 18.6102L16.8572 22.9002L26.442 13.4017L29.058 15.994L19.3214 25.63Z"
                      fill="#180D5B"
                    />
                  </svg>
                </span>
              </div>
              <div className="structDataWrap">
                <h5>Quality</h5>
                <p>
                  We control product quality at all levels due to collecting
                  input requirements and defining a realistic scope of work in
                  the early stage.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 col-12 mobNone">
          <div className="structureRightBox">
            <div className="floatImg floatImg1">
              <img src={imgFloat1} alt="" />
            </div>
            <div className="dottedSvg"></div>
            <div className="fillCirle"></div>
            <div className="floatImg floatImg2">
              <img src={imgFloatw2} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurStructure;
