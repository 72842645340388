import React from "react";
import { modelClock, needCardImg1, needCardImg2, needCardImg3, needCardImg4, needCardImg5 } from "..";

function WebDevServicesBlocks() {
  return (
    <div className="container">
      <div className="sectionTitle">
        <h2>Node.js Web & Mobile App Development Solutions</h2>
      </div>
      <div className="row needDevRow">
        <div className="col-lg-4 col-md-6 col-12 needDevCardWidth">
          <div className="needDevCard">
            <div className="needImgeOuter">
              <div className="cardImgWrap">
                <img src={needCardImg1} alt="Full-Stack Web Development" />
              </div>
            </div>
            <div className="needDevContent">
              <h3>Full-Stack Web Development</h3>
              <p>
                Our team of Node.js developers possesses proficiency in Node.js,
                TypeScript, React, Angular, Express, and various frameworks,
                catering to both back-end and front-end web development
                requirements.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 needDevCardWidth">
          <div className="needDevCard">
            <div className="needImgeOuter">
              <div className="cardImgWrap">
                <img src={needCardImg5} alt="E-Commerce Application Development" />
              </div>
            </div>
            <div className="needDevContent">
              <h3>E-Commerce Application Development</h3>
              <p>
                With extensive experience in crafting e-commerce websites/apps,
                our dedicated development teams possess the expertise to design
                exceptionally secure, reliable, and swift Node.js applications.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 needDevCardWidth">
          <div className="needDevCard">
            <div className="needImgeOuter">
              <div className="cardImgWrap">
                <img src={needCardImg3} alt="App Development" />
              </div>
            </div>
            <div className="needDevContent">
              <h3> App Development</h3>
              <p>
                In addition to our Node.js web development offerings, we create
                enterprise mobile applications that are scalable, tailored, and
                resilient, ensuring smooth compatibility with both internal and
                external devices within the business ecosystem.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 needDevCardWidth">
          <div className="needDevCard">
            <div className="needImgeOuter">
              <div className="cardImgWrap">
                <img src={needCardImg4} alt="CMS Development" />
              </div>
            </div>
            <div className="needDevContent">
              <h3>CMS Development</h3>
              <p>
                Leveraging Node.js admin panel extensibility and the expertise
                of our CMS developers, we provide clients with the capability to
                efficiently manage and enhance their web content.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 needDevCardWidth">
          <div className="needDevCard">
            <div className="needImgeOuter">
              <div className="cardImgWrap">
                <img src={modelClock} alt="QA & Testing Services" />
              </div>
            </div>
            <div className="needDevContent">
              <h3>QA & Testing Services</h3>
              <p>
                Across our Node.js development services, we assign certified and
                experienced quality analysts to your project. They work
                on-demand to ensure seamless app performance across all browsers
                and screens.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-12 needDevCardWidth">
          <div className="needDevCard">
            <div className="needImgeOuter">
              <div className="cardImgWrap">
                <img src={needCardImg2} alt="Maintenance & Support" />
              </div>
            </div>
            <div className="needDevContent">
              <h3>Maintenance & Support</h3>
              <p>
                With our on-demand technical support team, you can remain
                confident about addressing any bugs or glitches in your
                application. As a premier Node.js development company, we are
                always prepared to assist you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WebDevServicesBlocks;
