import { portfolio16, portfolio17, portfolio18, portfolio19 } from "..";

const catelougedata = [
    {
        id: 0,
        logoname: 'version next portfolio',
        logosrc: portfolio16
    },
    {
        id: 1,
        logoname: 'version next portfolio',
        logosrc: portfolio17
    },
    {
        id: 2,
        logoname: 'version next portfolio',
        logosrc: portfolio18
    },
    {
        id: 3,
        logoname: 'version next portfolio',
        logosrc: portfolio19
    },
    
]

export default catelougedata;