import {
  logo16,
  logo17,
  logo18,
  logo19,
  logo20,
  logo21,
  logo22,
  logo23,
  portfolio1,
  portfolio10,
  portfolio11,
  portfolio12,
  portfolio13,
  portfolio14,
  portfolio15,
  portfolio2,
  portfolio3,
  portfolio4,
  portfolio5,
  portfolio6,
  portfolio7,
  portfolio8,
  portfolio9,
} from "..";

const logosdata = [
  {
    id: 0,
    logoname: "version next portfolio",
    logosrc: portfolio1,
  },
  {
    id: 1,
    logoname: "version next portfolio",
    logosrc: portfolio2,
  },
  {
    id: 2,
    logoname: "version next portfolio",
    logosrc: portfolio3,
  },
  {
    id: 3,
    logoname: "version next portfolio",
    logosrc: portfolio4,
  },
  {
    id: 4,
    logoname: "version next portfolio",
    logosrc: portfolio5,
  },
  {
    id: 5,
    logoname: "version next portfolio",
    logosrc: portfolio6,
  },
  {
    id: 6,
    logoname: "version next portfolio",
    logosrc: portfolio7,
  },
  {
    id: 7,
    logoname: "version next portfolio",
    logosrc: portfolio8,
  },
  {
    id: 8,
    logoname: "version next portfolio",
    logosrc: portfolio9,
  },
  {
    id: 9,
    logoname: "version next portfolio",
    logosrc: portfolio10,
  },
  {
    id: 10,
    logoname: "version next portfolio",
    logosrc: portfolio11,
  },
  {
    id: 11,
    logoname: "version next portfolio",
    logosrc: portfolio12,
  },
  {
    id: 12,
    logoname: "version next portfolio",
    logosrc: portfolio13,
  },
  {
    id: 13,
    logoname: "version next portfolio",
    logosrc: portfolio14,
  },
  {
    id: 14,
    logoname: "version next portfolio",
    logosrc: portfolio15,
  },
  {
    id: 15,
    logoname: "version next portfolio",
    logosrc: logo16,
  },
  {
    id: 16,
    logoname: "version next portfolio",
    logosrc: logo17,
  },
  {
    id: 17,
    logoname: "version next portfolio",
    logosrc: logo18,
  },
  {
    id: 18,
    logoname: "version next portfolio",
    logosrc: logo19,
  },
  {
    id: 19,
    logoname: "version next portfolio",
    logosrc: logo20,
  },
  {
    id: 20,
    logoname: "version next portfolio",
    logosrc: logo21,
  },
  {
    id: 21,
    logoname: "version next portfolio",
    logosrc: logo22,
  },
  {
    id: 22,
    logoname: "version next portfolio",
    logosrc: logo23,
  },
];

export default logosdata;
