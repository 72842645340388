import React from "react";
import { Link } from "react-router-dom";
import { blackarrow, callImg, whitearrow } from "..";

const RoundedCommonBtn = (props) => {
  return (
    <div className="roundedBtnWrap">
      <Link type="button" className={props.rName} to={props.rLink}>
        <span className="fa callIcon">
          <img src={callImg} alt="" />
          <div className="wifi-symbol">
            <div className="wifi-circle second"></div>
            <div className="wifi-circle third"></div>{" "}
          </div>
        </span>
        <span>{props.rText}</span>
        <div className="card-arrow arrow-icon-wrapper">
          <span className="arrow-icon">
            <img loading="lazy" src={whitearrow} alt="" />
            <img loading="lazy" src={blackarrow} alt="" />
          </span>
          <span className="arrow-icon arrow-icon-hover">
            <img loading="lazy" src={whitearrow} alt="" />
            <img loading="lazy" src={blackarrow} alt="" />
          </span>
        </div>
      </Link>
    </div>
  );
};

export default RoundedCommonBtn;
