import React from "react";
import PageBanner from "../components/TermsPageComponents/PageBanner";

function PrivacyPolicy() {
  return (
    <section id="termsPage" className="termsPage">
      <div className="pagesBanner">
        <PageBanner PageTitle="Privacy Policy" />
        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </div>
      <div className=" termsTopSection">
        <div className="container">
          <div className="termsWrapper">
            <div className="col-md-12">
              <h6>
                <b>Effective Date:</b> 01/07/2023
              </h6>
              <p className="termsMainPara">
                This Privacy Policy ("Policy") describes how Version Next
                Technologies Private Limited ("Version Next Technologies," "we,"
                "us," or "our") collects, uses, shares, and protects personal
                information in connection with our website and services. Please
                read this Policy carefully to understand our practices regarding
                your personal information and how we will treat it.
              </p>
              <div className="termsSection">
                <h5>1. Information We Collect</h5>
                <ul>
                  <li>
                    <strong>Personal Information:</strong> We may collect
                    personal information, including but not limited to your
                    name, email address, contact number, and other information
                    you voluntarily provide to us when you use our website,
                    interact with us through email or other communication
                    channels, or when you engage with our services.
                  </li>
                  <li>
                    <strong>Log Data:</strong> When you access our website, we
                    may automatically collect certain information, such as your
                    IP address, browser type, operating system, referring URLs,
                    pages visited, and the dates/times of access. We use this
                    information to analyze trends, administer our website, and
                    gather demographic information about our user base as a
                    whole.
                  </li>
                </ul>
              </div>
              <div className="termsSection">
                <h5>2. Use of Information</h5>
                <ul>
                  <li>
                    <strong>Provision of Services: </strong> We may use the
                    personal information you provide to deliver the services you
                    request, respond to your inquiries, and communicate with you
                    regarding our services, updates, and notifications.
                  </li>
                  <li>
                    <strong>Analytics and Improvements: </strong> We may use the
                    information collected, including log data, to analyze and
                    improve our website and services, understand user
                    preferences, troubleshoot technical issues, and enhance the
                    overall user experience.
                  </li>
                  <li>
                    <strong>Marketing and Communications: </strong> With your
                    consent, we may use your personal information to send you
                    promotional materials, newsletters, and other marketing
                    communications. You may opt out of receiving such
                    communications at any time by following the instructions
                    provided in the communication.
                  </li>
                  <li>
                    <strong>Legal Obligations: </strong> We may use and disclose
                    your personal information as required by applicable laws,
                    regulations, or legal processes, or to protect and defend
                    our rights, interests, or property.
                  </li>
                </ul>
              </div>
              <div className="termsSection">
                <h5>3. Sharing of Information</h5>
                <ul>
                  <li>
                    <strong>Service Providers: </strong> We may engage trusted
                    third-party service providers to perform certain services on
                    our behalf, such as website hosting, data analysis, customer
                    support, and email delivery. These service providers will
                    have access to your personal information only as necessary
                    to perform their functions and are obligated to maintain the
                    confidentiality and security of your information.
                  </li>
                  <li>
                    <strong>Business Transfers: </strong> In the event of a
                    merger, acquisition, or sale of all or a portion of our
                    assets, your personal information may be transferred as part
                    of the transaction. We will notify you via email or
                    prominent notice on our website of any such change in
                    ownership or control of your personal information.
                  </li>
                  <li>
                    <strong>Legal Compliance: </strong> We may disclose your
                    personal information if required to do so by law or in
                    response to valid requests by public authorities (e.g.,
                    government agencies, law enforcement) or to establish,
                    exercise, or defend legal claims.
                  </li>
                </ul>
              </div>
              <div className="termsSection">
                <h5>4. Data Security</h5>
                <p>
                  We take reasonable measures to protect the personal
                  information we collect against unauthorized access,
                  disclosure, alteration, and destruction. However, no data
                  transmission or storage system can be guaranteed to be 100%
                  secure. You are responsible for maintaining the
                  confidentiality of any account information and for taking
                  reasonable precautions to protect your account credentials.
                </p>
              </div>
              <div className="termsSection">
                <h5>5. Your Rights</h5>
                <p>
                  Subject to applicable law, you have certain rights regarding
                  your personal information, including the right to access,
                  update, correct, or delete the information we hold about you.
                  If you wish to exercise any of these rights, please contact us
                  using the contact details provided below.
                </p>
              </div>
              <div className="termsSection">
                <h5>6. Children's Privacy</h5>
                <p>
                  Our website and services are not directed towards individuals
                  under the age of 16. We do not knowingly collect personal
                  information from children. If you believe we have
                  inadvertently collected personal information from a child,
                  please contact us, and we will take reasonable measures to
                  remove that information from our systems.
                </p>
              </div>
              <div className="termsSection">
                <h5>7. Changes to this Policy</h5>
                <p>
                  We reserve the right to update or modify this Policy at any
                  time. Any changes will be effective immediately upon posting
                  the revised Policy on our website. Your continued use of our
                  website and services after the posting of any changes
                  indicates your acceptance of such changes.
                </p>
              </div>
              <div className="termsSection">
                <h5>8. Contact Us</h5>
                <p>
                  If you have any questions or concerns regarding this Privacy
                  Policy or our privacy practices, please contact us at:
                </p>
                <address>
                  <strong>Version Next Technologies Private Limited</strong>{" "}
                  <br />
                  Suite No 607-608, A Wing Pranik Chambers, <br />
                  SakiVihar Road, Sakinaka Metro, <br />
                  Andheri East, Mumbai - 400072
                  <br />
                  Maharashtra, India.
                  <br />
                  <strong>Phone:</strong> +91-7039003001
                  <br />
                  <strong>Email:</strong>{" "}
                  <a href="mailto:sales@version-next.com.">
                    sales@version-next.com.
                  </a>
                </address>
                <p>
                  Please note that this Privacy Policy applies only to Version
                  Next Technologies' website and services and not to any
                  third-party websites or services linked to or referenced on
                  our website.
                </p>
                <p className="mb-0">
                  By using our website and services, you signify your consent to
                  the collection, use, and disclosure of your personal
                  information in accordance with this Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
