import React from "react";
import { modelClock } from "../../../components";
import ExpertButton from "../../../components/CTA/ExpertButton";

function HireModel(props) {
  return (
    <div className="container">
      <div className="row rowSpace">
        <div className="col-lg-5">
          <div className="sectionTitle">
            <h2>VNT Hire Models For Your Business</h2>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="sectionParas">
            <p>
            Employ a team of dedicated <span className="highlightText">{props.techName}</span>{" "}
            developers according to your convenience.
            </p>
          </div>
        </div>
      </div>
      
      <div className="row modelRow">
        <div className="col-lg-4 modelBoxWraps">
          <div className="modelBox">
            <h4>
              <img src={modelClock} alt="model clock" /> Full Time Monthly Hire
            </h4>
            <div className="modelDurationBox">
              <p>
                <span className="boldText">9</span> Hours Per Day
              </p>
              <p>
                <span className="boldText">180</span> Hours
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 modelBoxWraps">
          <div className="modelBox">
            <h4>
              <img src={modelClock} alt="model clock" /> Part Time Monthly Hire
            </h4>
            <div className="modelDurationBox">
              <p>
                <span className="boldText">4</span> Hours Per Day
              </p>
              <p>
                <span className="boldText">80</span> Hours
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 modelBoxWraps">
          <div className="modelBox">
            <h4>
              <img src={modelClock} alt="model clock" /> Hourly Hire
            </h4>
            <div className="modelDurationBox">
              <p>
                <span className="boldText">400/200 </span> Flexible Hours
              </p>
              <p></p>
            </div>
          </div>
        </div>
      </div>

      <div className="benifitsSection">
        <h3 className="sectionTitle">Benefits</h3>
        <ul>
          <li>Project Manager Services at No Cost</li>
          <li>Daily Reporting and Code Deployment Activities</li>
          <li>Integration of Version Control System</li>
          <li>Open Lines of Direct Communication</li>
          <li>Utilization of Tools for Project Management</li>
          <li>Execution Following SCRUM Principles</li>
        </ul>
        <div className="hireDevFormBtnWrap">
          <ExpertButton
            rName="rAnimeBtn expertBtn"
            rText="Schedule a Developer Interview"
          />
        </div>
      </div>
    </div>
  );
}

export default HireModel;
