import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";

function CardBlog() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(
          "https://blog.version-next.com/wp-json/wp/v2/posts?_embed&per_page=6"
        );
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  const staticImages = [
    "https://version-next.com/static/media/meetimg2.b2615b0b.jpg",
    "https://version-next.com/static/media/meetimg1.6c3c4022.jpg",
    "https://version-next.com/static/media/meetimg2.b2615b0b.jpg",
    "https://version-next.com/static/media/about-expert.7a10909f.png",
    "https://version-next.com/static/media/meetimg1.6c3c4022.jpg",
    "https://version-next.com/static/media/meetimg2.b2615b0b.jpg",
  ];

  const staticColors = [
    "#859500",
    "#e7b800",
    "#00a0b7",
    "#ca1d00",
    "#0064b9",
    "#202020",
  ];

  const getFeaturedMedia = (post) => {
    if (
      post._embedded &&
      post._embedded["wp:featuredmedia"] &&
      post._embedded["wp:featuredmedia"][0].source_url
    ) {
      return post._embedded["wp:featuredmedia"][0].source_url;
    } else {
      return staticImages[Math.floor(Math.random() * staticImages.length)];
    }
  };

  const getCategoryName = (post) => {
    if (
      post._embedded &&
      post._embedded["wp:term"] &&
      post._embedded["wp:term"][0] &&
      post._embedded["wp:term"][0][0] &&
      post._embedded["wp:term"][0][0].name
    ) {
      return post._embedded["wp:term"][0][0].name;
    } else {
      return "";
    }
  };

  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    autoplay: false,
    speed: 800,
    adaptiveHeight: false,
    infinite: true,
    useTransform: true,
    cssEase: "cubic-bezier(0.77, 0, 0.18, 1)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings} className="slider-with-gap">
      {posts.map((post, index) => {
        const postDate = new Date(post.date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
        const featuredMedia = getFeaturedMedia(post);
        const categoryName = getCategoryName(post);
        const backgroundColor =
          staticColors[index % staticColors.length];

        return (
          <div className="slide-item" key={index}>
            <div className="blogBoxWrap">
              <div
                className="blog_badge"
                style={{ backgroundColor }}
              >
                <span>{categoryName}</span>
              </div>
              <Link to={post.link} target="_blank">
                <div className="blogImgWrap">
                  <img src={featuredMedia} alt="Blog Post" />
                </div>
                <div className="blogTitle">
                  <h6>{post.title.rendered}</h6>
                  <div className="blogSummery">
                    <p>{post.yoast_head_json.author}</p>
                    <p>
                      <span className="material-symbols-outlined">
                        calendar_month
                      </span>{" "}
                      {postDate}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        );
      })}
    </Slider>
  );
}

export default CardBlog;
