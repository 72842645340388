import React, { useEffect } from "react";
import "swiper/swiper-bundle.css";
import { circularText } from "../..";
import { useState } from "react";

function WhyChooseUsNew() {
  const [Show, setShow] = useState(1);
  useEffect(() => {
    let currentNum = 1;

    const interval = setInterval(() => {
      const nextNum = (currentNum % 3) + 1;
      setShow(nextNum);
      currentNum = nextNum;
    }, 5000);

    return () => clearInterval(interval);
  }, [Show]);

  return (
    <div className="container">
      <div className="coreServRow">
        <div className="col-md-12 col-lg-5">
          <div className="coreLeftPart">
            <div className="coreLeftText">
              <h6 className="coreLeftTitle">Our core services</h6>
              <h2 className="coreTitle">
                We always remain central to your business
              </h2>
              <p className="coreDesc">
                We truly believe that when you choose to work with us, we become
                an extension of your team. It is a long established fact that a
                reader will be distracted by the readable content of a page when
                looking at its layout.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-12 col-lg-6">
          <div className="coreRightPart">
            <div className="coreRightText box1">
              <p className="coreCount">6620+</p>

              <p className="corePara">Projects Completed Successfully</p>
            </div>
            <div className="coreRightText box2">
              <p className="coreCount">15+</p>

              <p className="corePara">Years of Industry Experience</p>
            </div>
            <div className="ct-icon box5">
             <img src={circularText} alt="VNT" width={600} height={400}/>
            </div>
            <div className="coreRightText box3">
              <p className="coreCount">20K+</p>

              <p className="corePara">Happy Customers Worldwide</p>
            </div>
            <div className="coreRightText box4">
              <p className="coreCount">100%</p>

              <p className="corePara">Custom Solutions, Agile Adaptability</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyChooseUsNew;
