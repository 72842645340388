import React from "react";
import { Link } from "react-router-dom";
import {  blackarrow, gradearrow, whitearrow} from "..";

const TransparentRoundedBtn = (props) => {
  return (
    <div className="roundedBtnWrap">
      <Link type="button" className={props.rName} to={props.rLink}>
        <span>{props.rText}</span>
        <div className="card-arrow arrow-icon-wrapper">
          <span className="arrow-icon">
            <img className="blackImg" loading="lazy" src={blackarrow} alt="" />
            <img className="whiteImg" loading="lazy" src={whitearrow} alt="" />
            <img className="gradeImg" loading="lazy" src={gradearrow} alt="" />
          </span>
          <span className="arrow-icon arrow-icon-hover">
            <img className="blackImg" loading="lazy" src={blackarrow} alt="" />
            <img className="whiteImg" loading="lazy" src={whitearrow} alt="" />
            <img className="gradeImg" loading="lazy" src={gradearrow} alt="" />
          </span>
        </div>
      </Link>
    </div>
  );
};

export default TransparentRoundedBtn;
