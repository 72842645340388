import { portfolio29, portfolio30, portfolio31, portfolio32, portfolio33, portfolio34, portfolio35, portfolio36, portfolio37, portfolio38, portfolio39 } from "..";

const uidata = [
    {
        id: 0,
        logoname: 'version next portfolio',
        logosrc: portfolio29
    },
    {
        id: 1,
        logoname: 'version next portfolio',
        logosrc: portfolio30
    },
    {
        id: 2,
        logoname: 'version next portfolio',
        logosrc: portfolio31
    },
    {
        id: 3,
        logoname: 'version next portfolio',
        logosrc: portfolio32
    },
    {
        id: 4,
        logoname: 'version next portfolio',
        logosrc: portfolio33
    },
    {
        id: 5,
        logoname: 'version next portfolio',
        logosrc: portfolio34
    },
    {
        id: 6,
        logoname: 'version next portfolio',
        logosrc: portfolio35
    },
    {
        id: 7,
        logoname: 'version next portfolio',
        logosrc: portfolio36
    },
    {
        id: 8,
        logoname: 'version next portfolio',
        logosrc: portfolio37
    },
    {
        id: 9,
        logoname: 'version next portfolio',
        logosrc: portfolio38
    },
    {
        id: 10,
        logoname: 'version next portfolio',
        logosrc: portfolio39
    },
]

export default uidata;