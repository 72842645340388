import React from "react";
// import Marquee from "react-fast-marquee";

function Clients() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 p-0">
          {
            // <Marquee
            //   gradient={false}
            //   speed={80}
            //   duration={3000}
            //   delay={0}
            //   direction="left"
            // >
            //   <div className="clientLogo">
            //     <img src={client11} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client12} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client7} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client8} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client9} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client10} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client11} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client12} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client13} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client14} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client15} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client16} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client17} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client18} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client19} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client20} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client21} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client22} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client23} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client24} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client25} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client26} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client27} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client28} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client29} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client30} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client31} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client32} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client33} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client23} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client34} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client35} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client36} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client37} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client38} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client39} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client40} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client41} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client42} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client43} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client44} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client45} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client46} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client47} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client48} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client49} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client50} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client51} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client52} alt="" />
            //   </div>
            //   &nbsp;
            //   <div className="clientLogo">
            //     <img src={client1} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client2} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client3} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client4} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client5} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client6} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client7} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client8} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client9} alt="" />
            //   </div>
            //   <div className="clientLogo">
            //     <img src={client10} alt="" />
            //   </div>
            // </Marquee>
          }

          <div className="client-logo-listing">
            <div className="brands-marquee">
              <div className="js-marquee-wrapper">
                <div
                  className="js-marquee"
                  style={{ marginRight: "0", float: "left" }}
                >
                  <div
                    className="brands-marquee-inner"
                    style={{ marginRight: "0", float: "left" }}
                  >
                    <ul className="m-0">
                      <li>
                        <div className="  logo-brand-black-oracle svg_sprite_bg svg-sprite secondStripe"></div>
                      </li>
                      <li>
                        <div className="  logo-cambridge-v3 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  abdul-latif-jameel-logo svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  abdul-latif-jameel-logo-001 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-mtn svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-sanchobbdo svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-gulfstream svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-daman svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-astrazeneca svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-vfsglobal svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-haas svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-tata svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-yahoo svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-smithfield svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="logo-brand-black-best-buy svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-essilor svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-cosmopolitan svg_sprite_bg svg-sprite"></div>
                      </li>

                      <li>
                        <div className="  logo-brand-black-abbott svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-unsw svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-lifetechnologies svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-jacksoncoker svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-mcdonalds svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-vodafone svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-adidas svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-ndtv svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-kotak svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-macys svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-cartier svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo1 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo2 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo3 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo4 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo5 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo6 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo7 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo8 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo9 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo10 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo11 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo12 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo13 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo14 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo15 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo16 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo17 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo18 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo19 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo20 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo21 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo22 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo23 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo24 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo25 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo26 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo27 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo28 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo29 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo30 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo31 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo32 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo33 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo34 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo35 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo36 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo37 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo38 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo39 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo40 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo41 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo42 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo43 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo44 svg_sprite_bg svg-sprite"></div>
                      </li>
                      <li>
                        <div className="  logo-brand-black-Logo45 svg_sprite_bg svg-sprite"></div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clients;
