import React from "react";
import { chartDevImg, circleSvg, devFloatImg1 } from "../..";
import ExpertButton from "../../CTA/ExpertButton";

function DevServicesSection(props) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-lg-5 col-12">
          <div className="coreLeftBox">
            <h6 className="indusCoreLeftTitle">{props.secTitle}</h6>
            <h2 className="indusCoreTitle">{props.secMainTitle}</h2>
            <p className="indusCoreDesc">
              {props.secDesc}
            </p>
            <ExpertButton rText="Talk To Experts" rName="rAnimeBtn expertBtn"/>
          </div>
        </div>
        <div className="col-md-12 col-lg-7 col-12">
          <div className="coreRightBox">
            <div className="indusFloat1">
              <img src={devFloatImg1} alt="" />
            </div>
            <span className="indusXImg">
              <img src={circleSvg} alt="SVG Circle" />
            </span>
            <div className="indusFloat2">
              <div className="chartWrap">
                <h6>15 Years</h6>
                <p>Experience</p>
                <div className="chartWrapBox">
                  <img src={chartDevImg} alt="Chart" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DevServicesSection;
