import React from "react";

import {
  teamProfileImg1,
  teamProfileImg14,
  teamProfileImg15,
  teamProfileImg16,
  teamProfileImg18,
  teamProfileImg2,
  teamProfileImg20,
  teamProfileImg201,
  teamProfileImg202,
  teamProfileImg21,
  teamProfileImg22,
  teamProfileImg23,
  teamProfileImg25,
  teamProfileImg27,
  teamProfileImg28,
  teamProfileImg29,
  teamProfileImg3,
  teamProfileImg30,
  teamProfileImg31,
  teamProfileImg33,
  teamProfileImg36,
  teamProfileImg37,
  teamProfileImg4,
  teamProfileImg40,
  teamProfileImg6,
  teamProfileImg7,
  teamProfileImg9,
} from "..";
import { Link } from "react-router-dom";

function OurTeam() {
  const teamMembers = [
    {
      name: " Hartali Patil",
      position: "Human Resource",
      img: teamProfileImg9,
    },
    { name: " Neha Jain", position: "Accountant", img: teamProfileImg20 },
    {
      name: " Nitisha Bhatnagar",
      position: "Business Development",
      img: teamProfileImg21,
    },
    {
      name: " Sarvesh Kumar",
      position: "Sr. PHP Developer",
      img: teamProfileImg22,
    },
    {
      name: " Prajakta Rajeshirke",
      position: "Sr. Software Tester",
      img: teamProfileImg14,
    },
    {
      name: " Pranav Zende",
      position: "Sr. Frontend Developer",
      img: teamProfileImg15,
    },
    {
      name: " Suresh A.",
      position: "Sr. UI/UX Designer",
      img: teamProfileImg6,
    },
    {
      name: " Akash Kumar Singh",
      position: "Sr.React.js Developer",
      img: teamProfileImg7,
    },
    {
      name: " Vishal Rana",
      position: "Sr. PHP Developer",
      img: teamProfileImg37,
    },
    {
      name: " Ketan Vyas",
      position: "Sr. PHP Developer",
      img: teamProfileImg18,
    },
    // {
    //   name: " Amit Prajapati",
    //   position: "Jr. PHP Developer",
    //   img: teamProfileImg12,
    // },
    {
      name: " Sarthak Raut",
      position: "Sr. Flutter Developer",
      img: teamProfileImg23,
    },
    {
      name: " Umesh Mishra",
      position: "Sr. UI/UX Designer",
      img: teamProfileImg25,
    },
    {
      name: " Priyanka Malokar",
      position: "Sr. Frontend Developer",
      img: teamProfileImg27,
    },
    {
      name: " Dinesh Kumar",
      position: "Sr. Flutter Developer",
      img: teamProfileImg201,
    },
    {
      name: " Rujuta Kumbhare",
      position: "Sr.React.js Developer",
      img: teamProfileImg202,
    },
    {
      name: " Kisan Gupta",
      position: "Sr. .Net Developer",
      img: teamProfileImg33,
    },
    {
      name: " Sunil Dhakatode",
      position: "Sr. Wordpress Developer",
      img: teamProfileImg28,
    },
    {
      name: " Jagruti Dhobale",
      position: "Jr. Wordpress Developer",
      img: teamProfileImg29,
    },
    {
      name: " Rashmit Yadav",
      position: "Server Support Engineer",
      img: teamProfileImg30,
    },
    {
      name: " Sahil Jadhav",
      position: "Jr. Python Developer",
      img: teamProfileImg31,
    },
    {
      name: " Asutosh Singh",
      position: "Jr. PHP Developer",
      img: teamProfileImg36,
    },
    { name: " Mangesh Shelar", position: "Admin", img: teamProfileImg16 },
    {
      name: " Shiv Sawant",
      position: "Sr. Frontend Developer",
      img: teamProfileImg40,
    },
  ];
  teamMembers.sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
  return (
    <div className="container">
      <div className="sectionTitle">
        <h2>
          VNT Core <span className="textGrade titleArrow">Management</span>
        </h2>
        <p>
          Version Next consists of team that loves accepting challenges and
          contributes to give a positive result towards every project
          undertaken. Our workaholic and experienced team work hard to create a
          different product that takes your product to a new level.
        </p>
      </div>
      <div className="teamContainer">
        <div className="row ">
          <div className="col-md-6 col-lg-4 col-12 col-xl-3 xtraBtm">
            <div className="teamCardWrap">
              <div className="teamProfileImg bgYellowBlob">
                <img
                  src={teamProfileImg2}
                  alt="Profile"
                  className="proTeamImg"
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgYellow">
                  <p>Mr. Sanyogg Shelar</p>
                </div>
                <span>CEO</span>
              </div>
            </div>
            <div className="temaSpeechWrap">
              <p>
                “Catalyst of progress, gateway to innovation, and the bridge
                between dreams and reality.”
              </p>
              <div className="profileSocialLinks">
                <ul>
                  <Link
                    to="https://www.linkedin.com/in/sanyogs"
                    target="_blank"
                  >
                    <li>
                      <i className="fab fa-linkedin"></i>
                    </li>
                  </Link>
                  <Link to="https://sanyog.in" target="_blank">
                    <li>
                      <i className="fas fa-globe-asia"></i>
                    </li>
                  </Link>
                  <Link
                    to="https://profiles.wordpress.org/codexdemon/"
                    target="_blank"
                  >
                    <li>
                      <i className="fab fa-wordpress"></i>
                    </li>
                  </Link>

                  <Link to="https://twitter.com/sanyog" target="_blank">
                    <li>
                      {/* <i className="fab fa-twitter-square"></i> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        width="22px"
                      >
                        <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z" />
                      </svg>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3">
            <div className="teamCardWrap">
              <div className="teamProfileImg bgRedBlob">
                <img
                  src={teamProfileImg3}
                  alt="Profile"
                  className="proTeamImg"
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgRed">
                  <p>Mr. Sumeet Rathod</p>
                </div>
                <span>CTO</span>
              </div>
            </div>
            <div className="temaSpeechWrap">
              <p>
                "We are what we repeatedly do; excellence, then, is not an act
                but a habit."
              </p>
              <div className="profileSocialLinks">
                <ul>
                  <Link to="https://www.linkedin.com/in/sumeetrathod">
                    <li>
                      <i className="fab fa-linkedin"></i>
                    </li>
                  </Link>
                  <Link to="https://twitter.com/srtrend">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        width="22px"
                      >
                        <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z" />
                      </svg>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3">
            <div className="teamCardWrap">
              <div className="teamProfileImg bgBlueBlob">
                <img
                  src={teamProfileImg1}
                  alt="Profile"
                  className="proTeamImg"
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgGreen ">
                  <p>Mr. Arvind Baraskar</p>
                </div>
                <span>CMO</span>
              </div>
            </div>
            <div className="temaSpeechWrap">
              <p>
                "It is our choices, that show what we truly are, far more than
                our abilities and creativity."
              </p>

              <div className="profileSocialLinks">
                <ul>
                  <Link to="https://www.linkedin.com/in/arvindbaraskar/">
                    <li>
                      <i className="fab fa-linkedin"></i>
                    </li>
                  </Link>
                  <Link
                    to="https://profiles.wordpress.org/arvindbaraskar/"
                    target="_blank"
                  >
                    <li>
                      <i className="fab fa-wordpress"></i>
                    </li>
                  </Link>

                  <Link to="https://twitter.com/ArvindBaraskar">
                    <li>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        width="22px"
                      >
                        <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z" />
                      </svg>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4 col-12 col-xl-3">
            <div className="teamCardWrap">
              <div className="teamProfileImg bgGreenBlob">
                <img
                  src={teamProfileImg4}
                  alt="Profile"
                  className="proTeamImg "
                />
              </div>
              <div className="postionWrap">
                <div className="teamMemberName bgBlue">
                  <p>Mr. Vinayak Parab</p>
                </div>
                <span>CFO</span>
              </div>
            </div>
            <div className="temaSpeechWrap">
              <p>
                "We are what we repeatedly do; excellence, then, is not an act
                but a habit."
              </p>
              <div className="profileSocialLinks">
                <ul>
                  <Link to="http://linkedin.com/in/vinayak-parab-2a7a8615">
                    <li>
                      <i className="fab fa-linkedin"></i>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>

          {teamMembers.map((member, index) => (
            <div
              className="col-md-6 col-lg-4 col-12 col-xl-3 xtraBtm"
              key={index}
            >
              <div className="teamCardWrap">
                <div className="teamProfileImg bgYellowBlob">
                  <img src={member.img} alt="Profile" className="proTeamImg" />
                </div>
                <div className="postionWrap">
                  <div className="teamMemberName bgYellow">
                    <p>{member.name}</p>
                  </div>
                  <span>{member.position}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurTeam;
