import React from "react";

function YourNeedSection({techName, yourNeedData}) {
  return (
    <div className="container">
      <div className="sectionTitle">
        <h2>Hire {techName} Developers As Per Your Needs</h2>
      </div>
      <div className="row needDevRow">
        {yourNeedData.map((data, index) => {
          return (
            <div className="col-lg-4 col-md-6 col-12 needDevCardWidth" key={index}>
              <div className="needDevCard">
                <div className="needImgeOuter">
                  <div className="cardImgWrap">
                    <img src={data.img} alt={data.title} />
                  </div>
                </div>
                <div className="needDevContent">
                  <h3>{data.title}</h3>
                  <p>
                    {data.description}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default YourNeedSection;
