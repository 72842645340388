import React, { useEffect, useState } from "react";
import PageBanner from "../components/TermsPageComponents/PageBanner";
import RoundedCommonBtn from "../components/CTA/RoundedCommonBtn";
import logosdata from "../components/PortfolioPageComponents/logosdata";
import catelougedata from "../components/PortfolioPageComponents/catelougedata";
import packagingdata from "../components/PortfolioPageComponents/packagingdata";
import uidata from "../components/PortfolioPageComponents/designs";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Lottie from "react-lottie";
import LoadingSpinner from "../assets/loading-spinner.json";

function Portfolio() {
  const [isSticky, setSticky] = useState(false);
  const [isActive, setIsActive] = useState(1);
  const handleActive = (btn) => setIsActive(btn);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentImageIndex1, setCurrentImageIndex1] = useState(0);
  const [currentImageIndex2, setCurrentImageIndex2] = useState(0);
  const [currentImageIndex3, setCurrentImageIndex3] = useState(0);
  const [loading1, setLoading1] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);
  const [loading4, setLoading4] = useState(true);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingSpinner,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setCurrentImageIndex1(index);
    setCurrentImageIndex2(index);
    setCurrentImageIndex3(index);
    setLightboxIsOpen(true);
  };

  const closeLightbox = () => {
    setLightboxIsOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((currentImageIndex + 1) % logosdata.length);
    setCurrentImageIndex1((currentImageIndex1 + 1) % catelougedata.length);
    setCurrentImageIndex2((currentImageIndex2 + 1) % packagingdata.length);
    setCurrentImageIndex3((currentImageIndex3 + 1) % uidata.length);
  };

  const prevImage = () => {
    setCurrentImageIndex(
      (currentImageIndex - 1 + logosdata.length) % logosdata.length
    );
    setCurrentImageIndex1(
      (currentImageIndex1 - 1 + catelougedata.length) % catelougedata.length
    );
    setCurrentImageIndex2(
      (currentImageIndex2 - 1 + packagingdata.length) % packagingdata.length
    );
    setCurrentImageIndex3(
      (currentImageIndex3 - 1 + uidata.length) % uidata.length
    );
  };
  // useEffect(() => {
  //   const handleScroll = () => {
  //     setSticky(window.scrollY > 400);
  //   };

  //   // Add scroll event listener
  //   window.addEventListener("scroll", handleScroll);

  //   // Remove the event listener when component is unmounted
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const handleImageLoad1 = () => {
    setLoading1(false);
  };

  const handleImageLoad2 = () => {
    setLoading2(false);
  };

  const handleImageLoad3 = () => {
    setLoading3(false);
  };

  const handleImageLoad4 = () => {
    setLoading4(false);
  };
  return (
    <section id="termsPage" className="termsPage">
      <div className="pagesBanner">
        <PageBanner PageTitle="Portfolio" />

        <div className="shapeSvg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15002 2408">
            <g id="Layer_x0020_1">
              <metadata id="CorelCorpID_0Corel-Layer" />
              <path
                className="fil0"
                d="M14999.66 160.41l0 2250.5 -15002 0 0 -2408.48c4.48,15.46 34.05,38.31 47.09,51.36 18.3,18.3 33.31,33.31 51.61,51.61l377.15 322.94c339.34,254.69 723.38,451.6 1129.12,576.25 740.24,227.38 1518.28,209.44 2250.74,-34.78 297.55,-99.2 684.02,-289.57 933.07,-476.11 87.62,-65.63 227.27,-165.69 307.34,-240.17 31.42,-29.23 114.35,-99.39 131.22,-124.58 20,4.66 99.59,47.55 124.17,59.83 42.41,21.2 81.55,40.72 122.67,61.33l125.61 58.39c133.82,59.52 249.76,112.63 391.76,169.22 238.34,95 587.91,216.82 832.54,284.94 200.63,55.86 382.28,106.97 590.69,154.3 1557.59,353.7 3775.68,425.94 5382.01,17.95 25.54,-6.48 49.29,-10.39 73.59,-16.17l373.43 -93.31c198.88,-54.19 343.18,-97.87 536.61,-159.02l312.66 -109.2c47.88,-17.88 90.5,-33.57 136.53,-51.96 82.88,-33.12 316.41,-125.5 392.57,-163.92l128.78 -59.7c23.6,-10.77 225.99,-115.13 251.04,-121.22z"
              />
            </g>
          </svg>
        </div>
      </div>
      <section id="profolioSection">
        <div className="container">
          <div className="portFolioRow">
            <div className={`sideBarSection ${isSticky ? "sticky" : ""}`}>
              <div className="sideTabsWrap">
                <ul className="roundedBtnWrapNew">
                  <li
                    className={`tabs tabs1 ${
                      isActive === 1 ? "activeTab" : "" 
                    }`}
                    onClick={() => handleActive(1)}
                  >
                    <RoundedCommonBtn
                      rText="Logo"
                      rName="rAnimeBtn expertBtn"
                    />
                  </li>
                  <li
                    className={`tabs tabs2 ${
                      isActive === 2 ? "activeTab" : ""
                    }`}
                    onClick={() => handleActive(2)}
                  >
                    <RoundedCommonBtn
                      rText="Catalogue"
                      rName="rAnimeBtn expertBtn"
                    />
                  </li>
                  <li
                    className={`tabs tabs3 ${
                      isActive === 3 ? "activeTab" : ""
                    }`}
                    onClick={() => handleActive(3)}
                  >
                    <RoundedCommonBtn
                      rText="Packaging"
                      rName="rAnimeBtn expertBtn"
                    />
                  </li>
                  <li
                    className={`tabs tabs4 ${
                      isActive === 4 ? "activeTab" : ""
                    }`}
                    onClick={() => handleActive(4)}
                  >
                    <RoundedCommonBtn
                      rText=" UI/UX"
                      rName="rAnimeBtn expertBtn"
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="mainLeftBody">
              <div className="tabContentWrap">
                {isActive === 1 && (
                  <div className="tabBoxSize">
                    {/* <h4>Logo</h4> */}
                    <div className="workWrap">
                      <ul>
                        {logosdata.map((logoImg, index) => (
                          <li onClick={() => openLightbox(index)} key={index}>
                            {loading1 && (
                              <div className="">
                                <Lottie
                                  options={defaultOptions}
                                  className="loadingSpinner"
                                />
                              </div>
                            )}
                            <img
                              src={logoImg.logosrc}
                              alt={logoImg.logoname}
                              onLoad={handleImageLoad1}
                            />
                          </li>
                        ))}
                      </ul>
                      {lightboxIsOpen && (
                        <Lightbox
                          mainSrc={logosdata[currentImageIndex].logosrc}
                          nextSrc={
                            logosdata[
                              (currentImageIndex + 1) % logosdata.length
                            ].logosrc
                          }
                          prevSrc={
                            logosdata[
                              (currentImageIndex - 1 + logosdata.length) %
                                logosdata.length
                            ].logosrc
                          }
                          onCloseRequest={closeLightbox}
                          onMovePrevRequest={prevImage}
                          onMoveNextRequest={nextImage}
                        />
                      )}
                    </div>
                  </div>
                )}
                {isActive === 2 && (
                  <div className="tabBoxSize">
                    {/* <h4>Catalogue</h4> */}
                    <div className="workWrap">
                      <ul>
                        {catelougedata.map((logoImg1, index) => (
                          <li onClick={() => openLightbox(index)} key={index}>
                            {loading2 && (
                              <div className="">
                                <Lottie
                                  options={defaultOptions}
                                  className="loadingSpinner"
                                />
                              </div>
                            )}
                            <img
                              src={logoImg1.logosrc}
                              alt={logoImg1.logoname}
                              onLoad={handleImageLoad2}
                            />
                          </li>
                        ))}
                      </ul>
                      {lightboxIsOpen && (
                        <Lightbox
                          mainSrc={catelougedata[currentImageIndex1].logosrc}
                          nextSrc={
                            catelougedata[
                              (currentImageIndex1 + 1) % catelougedata.length
                            ].logosrc
                          }
                          prevSrc={
                            catelougedata[
                              (currentImageIndex1 - 1 + catelougedata.length) %
                                catelougedata.length
                            ].logosrc
                          }
                          onCloseRequest={closeLightbox}
                          onMovePrevRequest={prevImage}
                          onMoveNextRequest={nextImage}
                        />
                      )}
                    </div>
                  </div>
                )}
                {isActive === 3 && (
                  <div className="tabBoxSize">
                    {/* <h4>Packaging</h4> */}
                    <div className="workWrap">
                      <ul>
                        {packagingdata.map((logoImg2, index) => (
                          <li onClick={() => openLightbox(index)} key={index}>
                            {loading3 && (
                              <div className="">
                                <Lottie
                                  options={defaultOptions}
                                  className="loadingSpinner"
                                />
                              </div>
                            )}
                            <img
                              src={logoImg2.logosrc}
                              alt={logoImg2.logoname}
                              onLoad={handleImageLoad3}
                            />
                          </li>
                        ))}
                      </ul>
                      {lightboxIsOpen && (
                        <Lightbox
                          mainSrc={packagingdata[currentImageIndex2].logosrc}
                          nextSrc={
                            packagingdata[
                              (currentImageIndex2 + 1) % packagingdata.length
                            ].logosrc
                          }
                          prevSrc={
                            packagingdata[
                              (currentImageIndex2 - 1 + packagingdata.length) %
                                packagingdata.length
                            ].logosrc
                          }
                          onCloseRequest={closeLightbox}
                          onMovePrevRequest={prevImage}
                          onMoveNextRequest={nextImage}
                        />
                      )}
                    </div>
                  </div>
                )}
                {isActive === 4 && (
                  <div className="tabBoxSize">
                    {/* <h4>UI/UX</h4> */}
                    <div className="workWrap">
                      <ul>
                        {uidata.map((logoImg2, index) => (
                          <li onClick={() => openLightbox(index)} key={index}>
                            {loading4 && (
                              <div className="">
                                <Lottie
                                  options={defaultOptions}
                                  className="loadingSpinner"
                                />
                              </div>
                            )}
                            <img
                              src={logoImg2.logosrc}
                              alt={logoImg2.logoname}
                              onLoad={handleImageLoad4}
                            />
                          </li>
                        ))}
                      </ul>
                      {lightboxIsOpen && (
                        <Lightbox
                          mainSrc={uidata[currentImageIndex3].logosrc}
                          nextSrc={
                            uidata[(currentImageIndex3 + 1) % uidata.length]
                              .logosrc
                          }
                          prevSrc={
                            uidata[
                              (currentImageIndex3 - 1 + uidata.length) %
                                uidata.length
                            ].logosrc
                          }
                          onCloseRequest={closeLightbox}
                          onMovePrevRequest={prevImage}
                          onMoveNextRequest={nextImage}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default Portfolio;
